import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import MedicalAssistantAppointmentsDashboard from './ma-appointments/ma-appointments-dashboard';
import MedicalAssistantPatientsPage from './ma-patients/ma-patients-page';
import MedicalAssistantSelfRegistrationPage from './ma-selfRegistration/ma-selfRegistration-page'
import MedicalAssistantClinicDoctors from './ma-doctors/ma-doctors';
import ContactUsPage from './shard/contact-us/contact-us-page';
import MaDashCreatePatient from './ma-patients/ma-create-patient';
import MaDashAppointmentUpdate from './ma-appointments/ma-appointment-update';
import MaDashUpdatePatient from './ma-patients/ma-update-patient';
import MaDashSelfRegistrationCreatePatient from './ma-selfRegistration/ma-selfRegistration-create-patient'

const Routes = ({ match }) => (
    <>
      <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}`} component={MedicalAssistantAppointmentsDashboard} />
        <ErrorBoundaryRoute path={`${match.url}/Home`} component={MedicalAssistantAppointmentsDashboard} />
        <ErrorBoundaryRoute path={`${match.url}/Patients`} component={MedicalAssistantPatientsPage} />
        <ErrorBoundaryRoute path={`${match.url}/Self-Registration`} component={MedicalAssistantSelfRegistrationPage} />
        <ErrorBoundaryRoute path={`${match.url}/Doctors`} component={MedicalAssistantClinicDoctors} />
        <ErrorBoundaryRoute path={`${match.url}/Support`} component={ContactUsPage} />
        <ErrorBoundaryRoute path={`${match.url}/Add-New-Patient`} component={MaDashCreatePatient} />
        <ErrorBoundaryRoute path={`${match.url}/Change-Appointment`} component={MaDashAppointmentUpdate} />
        <ErrorBoundaryRoute path={`${match.url}/Change-Patient`} component={MaDashUpdatePatient} />
        <ErrorBoundaryRoute path={`${match.url}/Add-New-Patient-sr`} component={MaDashSelfRegistrationCreatePatient} />     
      </Switch>
    </>
  );
  
  export default Routes;