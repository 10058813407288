import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import InsuranceCode from './insurance-code';
import InsuranceCodeDetail from './insurance-code-detail';
import InsuranceCodeUpdate from './insurance-code-update';
import InsuranceCodeDeleteDialog from './insurance-code-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={InsuranceCodeUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={InsuranceCodeUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={InsuranceCodeDetail} />
      <ErrorBoundaryRoute path={match.url} component={InsuranceCode} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={InsuranceCodeDeleteDialog} />
  </>
);

export default Routes;
