import { Moment } from 'moment';

export interface IInsuranceCode {
  id?: number;
  entityId?: number;
  code?: string;
  startDate?: string;
  expirationDate?: string;
  status?: string;
}

export const defaultValue: Readonly<IInsuranceCode> = {};
