import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { Translate, translate, ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import { getEntities as getMedicalAssistants } from 'app/entities/medical-assistant/medical-assistant.reducer';
import { getEntities as getHealthcareProfessionals } from 'app/entities/healthcare-professional/healthcare-professional.reducer';
import { getEntities as getPatients } from 'app/entities/patient/patient.reducer';
import { getEntities as getSelfRegistrations } from 'app/entities/self-registration/self-registration.reducer';
import { getEntity, updateEntity, createEntity, reset } from './clinic.reducer';
import { ClinicAuthorizations } from 'app/shared/valid-values/clinic-authorizations';

export interface IClinicUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> { }

export const ClinicUpdate = (props: IClinicUpdateProps) => {
  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);

  const { clinicEntity, medicalAssistants, healthcareProfessionals, patients, selfRegistrations, loading, updating } = props;

  const handleClose = () => {
    props.history.push('/clinic' + props.location.search);
  };

  useEffect(() => {
    if (isNew) {
      props.reset();
    } else {
      props.getEntity(props.match.params.id);
    }

    props.getMedicalAssistants();
    props.getHealthcareProfessionals();
    props.getPatients();
    props.getSelfRegistrations();
  }, []);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const entity = {
        ...clinicEntity,
        ...values,
      };

      if (isNew) {
        props.createEntity(entity);
      } else {
        props.updateEntity(entity);
      }
    }
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md={4} sm={8}>
          <h2 id="velariaApp.clinic.home.createOrEditLabel">
            <Translate contentKey={isNew
              ? "velariaApp.clinic.home.createLabel"
              : "velariaApp.clinic.home.editLabel"}
            >
              Create or edit a Clinic</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
      <Col md={4} sm={8} className="info-box">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <AvForm model={isNew ? {} : clinicEntity} onSubmit={saveEntity}>
              <AvGroup>
                <Label id="nameLabel" for="clinic-name">
                  <Translate contentKey="velariaApp.clinic.name">Name</Translate>
                </Label>
                <AvField
                  id="clinic-name"
                  type="text"
                  name="name"
                  validate={{
                    required: { value: true, errorMessage: translate('entity.validation.required') },
                    minLength: { value: 3, errorMessage: translate('entity.validation.minlength', { min: 3 }) },
                    maxLength: { value: 255, errorMessage: translate('entity.validation.maxlength', { max: 255 }) }
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="streetLabel" for="clinic-street">
                  <Translate contentKey="velariaApp.clinic.street">Street</Translate>
                </Label>
                <AvField
                  id="clinic-street"
                  type="text"
                  name="street"
                  validate={{
                    required: { value: true, errorMessage: translate('entity.validation.required') },
                    maxLength: { value: 255, errorMessage: translate('entity.validation.maxlength', { max: 255 }) }
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="houseNumberLabel" for="clinic-houseNumber">
                  <Translate contentKey="velariaApp.clinic.houseNumber">House Number</Translate>
                </Label>
                <AvField
                  id="clinic-houseNumber"
                  type="string"
                  className="form-control"
                  name="houseNumber"
                  validate={{
                    number: { value: true, errorMessage: translate('entity.validation.number') },
                    required: { value: true, errorMessage: translate('entity.validation.required') }
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="cityLabel" for="clinic-city">
                  <Translate contentKey="velariaApp.clinic.city">City</Translate>
                </Label>
                <AvField
                  id="clinic-city"
                  type="text"
                  name="city"
                  validate={{
                    required: { value: true, errorMessage: translate('entity.validation.required') },
                    maxLength: { value: 255, errorMessage: translate('entity.validation.maxlength', { max: 255 }) }
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="zipCodeLabel" for="clinic-zipCode">
                  <Translate contentKey="velariaApp.clinic.zipCode">Zip Code</Translate>
                </Label>
                <AvField
                  id="clinic-zipCode"
                  type="text"
                  name="zipCode"
                  validate={{
                    required: { value: true, errorMessage: translate('entity.validation.required') },
                    maxLength: { value: 10, errorMessage: translate('entity.validation.maxlength', { max: 10 }) }
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="registrationNumberLabel" for="clinic-registrationNumber">
                  <Translate contentKey="velariaApp.clinic.registrationNumber">Registration Number</Translate>
                </Label>
                <AvField
                  id="clinic-registrationNumber"
                  type="text"
                  name="registrationNumber"
                  validate={{
                    required: { value: true, errorMessage: translate('global.messages.validate.registrationNumber.required') },
                    pattern: {
                      value: '^([0-9]{1,9})$',
                      errorMessage: translate('global.messages.validate.registrationNumber.invalid')
                    },
                    minlength: { value: 9, errorMessage: translate('global.messages.validate.registrationNumber.minlength') },
                    maxLength: { value: 9, errorMessage: translate('global.messages.validate.registrationNumber.maxlength') }
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="emailLabel" for="clinic-email">
                  <Translate contentKey="velariaApp.clinic.email">Email</Translate>
                </Label>
                <AvField
                  id="clinic-email"
                  type="email"
                  name="email"
                  validate={{
                    required: { value: true, errorMessage: translate('entity.validation.required') }
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="phoneNumberLabel" for="clinic-phoneNumber">
                  <Translate contentKey="velariaApp.clinic.phoneNumber">Phone Number</Translate>
                </Label>
                <AvField
                  id="clinic-phoneNumber"
                  type="text"
                  name="phoneNumber"
                  validate={{
                    required: { value: true, errorMessage: translate('global.messages.validate.phoneNumber.required') },
                    pattern: {
                      value: '^(?:([+][0-9]{1,2})+[ .-]*)?([(]{1}[0-9]{1,6}[)])?([0-9 .-/]{3,20})((x|ext|extension)[ ]?[0-9]{1,4})?$',
                      errorMessage: translate('global.messages.validate.phoneNumber.invalidPattern')
                    }
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="preferredCommunicationChannelLabel" for="clinic-preferredCommunicationChannel">
                  <Translate contentKey="velariaApp.clinic.preferredCommunicationChannel">Preferred Communication Channel</Translate>
                </Label>
                <AvInput
                  id="clinic-preferredCommunicationChannel"
                  type="select"
                  className="form-control"
                  name="preferredCommunicationChannel"
                  value={(!isNew && clinicEntity.preferredCommunicationChannel) || translate('velariaApp.preferredChannel.email')}
                >
                  <option value={translate('velariaApp.preferredChannel.email')}>{translate('velariaApp.preferredChannel.email')}</option>
                  <option value={translate('velariaApp.preferredChannel.web')}>{translate('velariaApp.preferredChannel.web')}</option>
                </AvInput>
              </AvGroup>
              <AvGroup>
                <Label id="mainContactEmailLabel" for="clinic-mainContactEmail">
                  <Translate contentKey="velariaApp.clinic.mainContactEmail">Main Contact Email</Translate>
                </Label>
                <AvField
                  id="clinic-mainContactEmail"
                  type="email"
                  name="mainContactEmail"
                  validate={{
                    required: { value: true, errorMessage: translate('entity.validation.required') }
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="verificationStatusLabel" for="clinic-verificationStatus">
                  <Translate contentKey="velariaApp.clinic.verificationStatus">Verification Status</Translate>
                </Label>
                <AvField
                  id="clinic-verificationStatus"
                  type="select"
                  name="verificationStatus"
                  value={(!isNew && clinicEntity.verificationStatus) || translate('velariaApp.verification.notVerified')}
                >
                  <option value={translate('velariaApp.verification.verified')}>{translate('velariaApp.verification.verified')}</option>
                  <option value={translate('velariaApp.verification.notVerified')}>{translate('velariaApp.verification.notVerified')}</option>
                </AvField>
              </AvGroup>
              <AvGroup>
                <Label id="authorizationLabel" for="clinic-authorization">
                  <Translate contentKey="velariaApp.clinic.authorization">Authorization</Translate>
                </Label>
                <AvField
                  id="clinic-authorization"
                  type="select"
                  name="authorization"
                  value={(!isNew && clinicEntity.authorization) || ClinicAuthorizations.DEFAULT}
                >
                  <option value={ClinicAuthorizations.DEFAULT}>{translate('velariaApp.clinicAuthorization.default')}</option>
                  <option value={ClinicAuthorizations.SELF_REGISTRATION}>{translate('velariaApp.clinicAuthorization.self-registration')}</option>
                </AvField>
              </AvGroup>
              <Button tag={Link} id="cancel-save" to="/clinic" replace className="rounded" color="success" >
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button className="rounded" color="danger" id="save-entity" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </AvForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  medicalAssistants: storeState.medicalAssistant.entities,
  healthcareProfessionals: storeState.healthcareProfessional.entities,
  patients: storeState.patient.entities,
  selfRegistrations: storeState.selfRegistration.entities,
  clinicEntity: storeState.clinic.entity,
  loading: storeState.clinic.loading,
  updating: storeState.clinic.updating,
  updateSuccess: storeState.clinic.updateSuccess
});

const mapDispatchToProps = {
  getMedicalAssistants,
  getHealthcareProfessionals,
  getPatients,
  getSelfRegistrations,
  getEntity,
  updateEntity,
  createEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ClinicUpdate);

