import React from 'react';
import { Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';


interface SmsVerificationFailedMessageProps {
    smsVerificationFailed: boolean;
    mobileNumber: string;
}

const SmsVerificationFailedMessage = ({ smsVerificationFailed, mobileNumber }: SmsVerificationFailedMessageProps) => {
    return (
        <Row>
            <Col xs={12}>
                <div style={{ height: "10rem" }}>
                    {smsVerificationFailed
                        ?
                        <div>
                            {mobileNumber && mobileNumber.length > 0
                                ?
                                (<div>
                                    <p style={{ color: "Red" }}>
                                        <Translate contentKey="smsCodeVerificationForm.messages.smsSentTo" />
                                    </p>
                                    <input type="Text" readOnly value={mobileNumber} style={{ backgroundColor: "gray" }} />
                                </div>)
                                :
                                <div></div>}
                            <p style={{ color: "Red" }}>
                                <Translate contentKey="smsCodeVerificationForm.messages.smsCannotBeVerified" />
                            </p>
                        </div>
                        :
                        <div></div>}

                </div>
            </Col>
        </Row>
    );
};

export default SmsVerificationFailedMessage;