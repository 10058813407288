import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';
import Axios from 'axios';

export const ACTION_TYPES = {
  SUBMIT_CONTACT_US_FORM: 'contactUs/SUBMIT_CONTACT_US_FORM'
};

const initialState = {
  loading: false,
  errorMessage: null,
  activitySuccess: false
};

export type ContactUsState = Readonly<typeof initialState>;

export default (state: ContactUsState = initialState, action): ContactUsState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.SUBMIT_CONTACT_US_FORM):
      return {
        ...state,
        errorMessage: null,
        activitySuccess: false,
        loading: true
      };
    case FAILURE(ACTION_TYPES.SUBMIT_CONTACT_US_FORM):
      return {
        ...state,
        loading: false,
        activitySuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.SUBMIT_CONTACT_US_FORM):
      return {
        ...state,
        loading: false,
        activitySuccess: true
      };
    default:
      return state;
  }
};

export const submitContactUs = contactUsFormDetails => ({
  type: ACTION_TYPES.SUBMIT_CONTACT_US_FORM,
  payload: Axios.post('api/contact-us', contactUsFormDetails)
});
