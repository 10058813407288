import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Container } from 'reactstrap';
import { IRootState } from 'app/shared/reducers';
import MedicalAssistantDashboardTopArea from './ma-dashboard-top-area';
import SectionsDivider from '../sections-divider/sections-divider';

import MedicalAssitantRouter from '../index';
import { Translate } from 'react-jhipster';

const MedicalAssistantHome = () => {
    const medicalDashboardURL = { url: '/ma-dashboard' };

    return (
        <Container>
            <MedicalAssistantDashboardTopArea />
            <SectionsDivider />
            <br />
            <Row style={{ marginTop: '1rem' }}>
                <MedicalAssitantRouter match={medicalDashboardURL}></MedicalAssitantRouter>
            </Row>
            <Row style={{ marginTop: '2rem' }}>
            </Row>
        </Container >
    );
};

export default MedicalAssistantHome;
