import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label } from 'reactstrap';
import { AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { Translate, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { getEntity, updateEntity, createEntity, reset } from './icd-code.reducer';

export interface IIcdCodeUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string; }> { }

export const IcdCodeUpdate = (props: IIcdCodeUpdateProps) => {
  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);

  const { icdCodeEntity, loading, updating } = props;

  const handleClose = () => {
    props.history.push('/icd-code');
  };

  useEffect(() => {
    if (isNew) {
      props.reset();
    } else {
      props.getEntity(props.match.params.id);
    }
  }, []);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const entity = {
        ...icdCodeEntity,
        ...values
      };

      if (isNew) {
        props.createEntity(entity);
      } else {
        props.updateEntity(entity);
      }
    }
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md={4} sm={8}>
          <h2 id="velariaApp.icdCode.home.createOrEditLabel">
            <Translate contentKey={isNew
              ? "velariaApp.icdCode.home.createLabel"
              : "velariaApp.icdCode.home.editLabel"}
            >
              Create or edit a ICD Code
              </Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md={4} sm={8} className="info-box">
          {loading ? (
            <p>Loading...</p>
          ) : (
              <AvForm model={isNew ? {} : icdCodeEntity} onSubmit={saveEntity}>
                <AvGroup>
                  <Label id="codeLabel" for="icd-code-code">
                    <Translate contentKey="velariaApp.icdCode.code">Code</Translate>
                  </Label>
                  <AvField
                    id="icd-code-code"
                    type="text"
                    name="code"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') },
                      maxLength: { value: 50, errorMessage: translate('entity.validation.maxlength', { max: 50 }) }
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="descriptionLabel" for="icd-code-description">
                    <Translate contentKey="velariaApp.icdCode.description">Description</Translate>
                  </Label>
                  <AvField
                    id="icd-code-description"
                    type="text"
                    name="description"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') },
                      maxLength: { value: 100, errorMessage: translate('entity.validation.maxlength', { max: 100 }) }
                    }}
                  />
                </AvGroup>
                <Button tag={Link} id="cancel-save" to="/icd-code" replace className="rounded" color="success" >
                  <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
              &nbsp;
                <Button id="save-entity" type="submit" disabled={updating} replace className="rounded" color="danger">
                  <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </AvForm>
            )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  icdCodeEntity: storeState.icdCode.entity,
  loading: storeState.icdCode.loading,
  updating: storeState.icdCode.updating,
  updateSuccess: storeState.icdCode.updateSuccess
});

const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(IcdCodeUpdate);
