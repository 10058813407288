import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Row } from 'reactstrap';
import { IRootState } from 'app/shared/reducers';
import { retrieveTermsAndConditionsData } from '../terms-and-conditions.reducer';
import { RouteComponentProps } from 'react-router-dom';
import { TermsAndConditionsContentType } from '../../models';
import parse from 'html-react-parser';
import { Translate, getUrlParameter } from 'react-jhipster';
import { setLocale } from 'app/shared/reducers/locale';

export interface IPrivacyPolicyDataPage extends StateProps, DispatchProps, RouteComponentProps<{ key: any; }> { }

export const PrivacyPolicyDataPage = (props: IPrivacyPolicyDataPage) => {

    useEffect(() => {
       props.retrieveTermsAndConditionsData('de', TermsAndConditionsContentType.Study);
    }, [props.currentLanguage]);

    return (
        <Row style={{ margin: "2rem" }}>
            {props.content ? parse(props.content) : ''}
            {props.callStatus.loading ? 'Loading...' : ''}
            <Row style={{ margin: "2rem", marginBottom: "100%" }}>
              <p style={{ color: "red" }}>{props.callStatus.updateFailure ? 
              <Translate contentKey="activationTermsConditions.callStatusFailed"></Translate> : ''}</p>
            </Row>
        </Row>
    );

};

const mapStateToProps = ({ termsAndConditions, locale }: IRootState) => ({
    content: termsAndConditions.termsAndConditionsStudyContentDataAPI.response,
    currentLanguage: locale.currentLocale,
    callStatus: termsAndConditions.termsAndConditionsStudyContentDataAPI
});

const mapDispatchToProps = { retrieveTermsAndConditionsData, setLocale };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicyDataPage);
