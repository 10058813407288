import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Row, Col, Label } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { Translate, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { getEntity, updateEntity, createEntity, reset } from 'app/entities/appointment/appointment.reducer';
import { updateSelectedClinic } from 'app/pages/medical-assistant-dashboard/ma-dashboard.reducer';
import { IAppointment } from 'app/shared/model/appointment.model';
import '../ma-pages.scss';

interface IMaDashAppointmentUpdate extends StateProps, DispatchProps {
}


const NOW = new Date().toJSON().split('T')[0];
const MAX_TIME_SINCE_TODAY = 10;

const MaDashAppointmentUpdate = (props: IMaDashAppointmentUpdate) => {

    const handleClose = () => {
        props.reset();
    };

    useEffect(() => {
        props.updateSelectedClinic(props.maSelectedClinic);
    }, []);


    useEffect(() => {
        if (props.updateSuccess) {
            handleClose();
        }
    }, [props.updateSuccess]);

    const saveEntity = (event, errors, values) => {

        if (errors.length === 0) {
            const entity: IAppointment = {
                ...props.maSelecetedAppointmentToEdit,
                ...values
            };

            entity.clinic = props.maSelectedClinic;
            props.updateEntity(entity);
        }
    };
    const getMaxDateForAppointment = () => {

        const fiveYearsAgoFromNow = new Date();
        fiveYearsAgoFromNow.setFullYear(fiveYearsAgoFromNow.getFullYear() + MAX_TIME_SINCE_TODAY);

        return fiveYearsAgoFromNow.toJSON().split('T')[0];

    };

    return (
        <Col className='page-container'>
            <Row className="justify-content-center">
                <Col xs={2} />
                <Col>
                    <h2 id="velariaApp.appointment.home.editLabel">
                        <Translate contentKey="velariaApp.appointment.home.editLabel">Create an Appointment</Translate>
                    </h2>
                </Col>
                <Col xs={2} />
            </Row>
            <Row className="justify-content-center">
                <Col xs={2} />
                <Col>
                    {props.loading ? (
                        <p>Loading...</p>
                    ) : (

                            <AvForm model={props.maSelecetedAppointmentToEdit} onSubmit={saveEntity}>
                                <AvGroup>
                                    <Label id="appointmentPatientName" for="appointment-patient-name">
                                        <Translate contentKey="velariaApp.appointment.patient">Patient Name</Translate>
                                    </Label>
                                    <AvField
                                        id="appointment-patient-name"
                                        className="form-control"
                                        name="fullName"
                                        type="input"
                                        value={`${props.maSelecetedAppointmentToEdit.patient.salutation} ${props.maSelecetedAppointmentToEdit.patient.firstName} ${props.maSelecetedAppointmentToEdit.patient.lastName}`}
                                        disabled
                                    />
                                </AvGroup>
                                <AvGroup>
                                    <Label id="dateLabel" for="appointment-date">
                                        <Translate contentKey="velariaApp.appointment.date">Date</Translate>
                                    </Label>
                                    <AvField
                                        id="appointment-date"
                                        type="date"
                                        className="form-control"
                                        name="date"
                                        min={NOW}
                                        max={getMaxDateForAppointment()}
                                        validate={{
                                            required: { value: true, errorMessage: translate('entity.validation.required') },
                                            dateRange: { start: { value: -1, units: 'days' }, end: { value: 10, units: 'years' } }
                                        }}
                                    />
                                </AvGroup>
                                <AvGroup>
                                    <Label id="timeLabel" for="appointment-time">
                                        <Translate contentKey="velariaApp.appointment.time">Time</Translate>
                                    </Label>
                                    <AvField
                                        id="appointment-time"
                                        type="time"
                                        name="time"
                                        placeholder="HH:MM"
                                        pattern="[0-9]{2}:[0-9]{2}"
                                        validate={{
                                            required: { value: true, errorMessage: translate('entity.validation.required') },
                                            maxLength: { value: 5 },
                                            pattern: {
                                                value: '^(20|21|22|23|[01]\\d|\\d)((:[0-5]\\d){1})$',
                                                errorMessage: translate('global.messages.validate.appointment.invalidTime'),
                                            }
                                        }}
                                    />
                                </AvGroup>

                                <AvGroup>
                                    <Label id="confirmedLabel" for="ma-appointment-confirmed">
                                        <Translate contentKey="velariaApp.appointment.confirmed">Confirmed</Translate>
                                    </Label>
                                    <AvInput
                                        id="ma-appointment-confirmed"
                                        type="select"
                                        className="form-control"
                                        name="confirmed"
                                        value={translate('velariaApp.confirmation.notConfirmed')}
                                    >
                                        <option value={translate('velariaApp.confirmation.confirmed')}>{translate('velariaApp.confirmation.confirmed')}</option>
                                        <option value={translate('velariaApp.confirmation.notConfirmed')}>{translate('velariaApp.confirmation.notConfirmed')}</option>
                                    </AvInput>
                                </AvGroup>
                                <AvGroup>
                                    <Label for="ma-appointment-healthcareProfessional">
                                        <Translate contentKey="velariaApp.appointment.healthcareProfessional">Healthcare Professional</Translate>
                                    </Label>
                                    <AvInput
                                        id="ma-appointment-healthcareProfessional"
                                        type="select"
                                        className="form-control"
                                        name="healthcareProfessional.id"
                                        value={props.maSelecetedAppointmentToEdit.healthcareProfessional.id}
                                        required
                                    >
                                        {props.maSelectedClinicDoctors
                                            ? props.maSelectedClinicDoctors.map(otherEntity => (
                                                <option value={otherEntity.id} key={otherEntity.id}>
                                                    {`${otherEntity.salutation} ${otherEntity.title} ${otherEntity.firstName} ${otherEntity.lastName}`}
                                                </option>
                                            ))
                                            : null}
                                    </AvInput>
                                    <AvFeedback>
                                        <Translate contentKey="entity.validation.required">This field is required.</Translate>
                                    </AvFeedback>
                                </AvGroup>
                                <Row>
                                    <Col xs={3} />
                                    <Col xs={5}>
                                        <div className="wizard-form-in-button">
                                            <Button color="primary" id="save-entity" type="submit" disabled={props.updating}>
                                                <FontAwesomeIcon icon="save" />
                                      &nbsp;
                                      <Translate contentKey="entity.action.save">Save</Translate>
                                            </Button>
                                        </div>
                                    </Col>
                                    <Col />
                                </Row>
                            </AvForm>
                        )}
                </Col>
                <Col xs={4} />
            </Row>
        </Col>
    );
};

const mapStateToProps = (storeState: IRootState) => ({
    loading: storeState.appointment.loading,
    updating: storeState.appointment.updating,
    updateSuccess: storeState.appointment.updateSuccess,
    maSelectedClinicDoctors: storeState.maDashboardReducer.maSelectedClinicDoctors,
    maselectedClinicPatients: storeState.maDashboardReducer.maSelectedClinicPatients,
    maSelectedClinic: storeState.maDashboardReducer.selectedClinic,
    maSelecetedAppointmentToEdit: storeState.maDashboardReducer.selectedAppointment,
    currentLocale: storeState.locale.currentLocale
});

const mapDispatchToProps = {
    getEntity,
    updateEntity,
    createEntity,
    reset,
    updateSelectedClinic
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(MaDashAppointmentUpdate);
