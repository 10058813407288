import { IClinic } from 'app/shared/model/clinic.model';
import { IHealthcareProfessional } from 'app/shared/model/healthcare-professional.model';
import { IPatient } from 'app/shared/model/patient.model';

export interface IAppointment {
  id?: number;
  title?: string;
  date?: Date;
  time?: string;
  confirmed?: string;
  clinic?: IClinic;
  healthcareProfessional?: IHealthcareProfessional;
  patient?: IPatient;
}

export const defaultValue: Readonly<IAppointment> = {};
