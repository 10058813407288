import React from 'react';
import { DropdownItem } from 'reactstrap';
import { NavDropdown } from './menu-components';
import { locales, languages } from 'app/config/translation';

export const LocaleMenu = ({ currentLocale, onClick }) =>
  Object.keys(languages).length > 1 && (
    <NavDropdown id="language-menu" name={currentLocale ? languages[currentLocale].name : undefined}>
      {/* {locales.map(locale => (
        <DropdownItem key={locale} value={locale} onClick={onClick}>
          {languages[locale].name}
        </DropdownItem>
      ))} */}

      <DropdownItem key='en' value='en' onClick={onClick}>
        {languages['en'].name}
      </DropdownItem>
      <DropdownItem key='de' value='de' onClick={onClick}>
        {languages['de'].name}
      </DropdownItem>
    </NavDropdown>
  );
