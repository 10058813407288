import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { Row, Col } from 'reactstrap';

import { reset } from './chatbot.reducer';
import BotsLst from './bots-list';
import { IRootState } from 'app/shared/reducers';
import ChatbotTitle from './chatbot-title';

interface IChatbotProps extends StateProps, DispatchProps { }



const ChatbotPage = (props: IChatbotProps) => {

    useEffect(() => {
        props.reset();

    }, []);

    return (
        <Row>
            <Col>
                <Row>
                    <Col>
                        <BotsLst />
                    </Col>
                    <Col>

                        <ChatbotTitle />
                    </Col>
                </Row>

            </Col>
        </Row>

    );
};



const mapStateToProps = ({ chatbotReducer }: IRootState) => ({
    botsList: chatbotReducer.bots

});

const mapDispatchToProps = {
    reset

};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ChatbotPage);
