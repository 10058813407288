import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import ScheduledTaskExecution from './scheduled-task-execution';
import ScheduledTaskExecutionDetail from './scheduled-task-execution-detail';
import ScheduledTaskExecutionUpdate from './scheduled-task-execution-update';
import ScheduledTaskExecutionDeleteDialog from './scheduled-task-execution-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={ScheduledTaskExecutionUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={ScheduledTaskExecutionUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={ScheduledTaskExecutionDetail} />
      <ErrorBoundaryRoute path={match.url} component={ScheduledTaskExecution} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={ScheduledTaskExecutionDeleteDialog} />
  </>
);

export default Routes;
