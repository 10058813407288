import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import Clinic from './clinic';
import ClinicDetail from './clinic-detail';
import ClinicUpdate from './clinic-update';
import ClinicDeleteDialog from './clinic-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={ClinicUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={ClinicUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={ClinicDetail} />
      <ErrorBoundaryRoute path={match.url} component={Clinic} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={ClinicDeleteDialog} />
  </>
);

export default Routes;
