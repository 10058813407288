import './header.scss';

import React, { useState } from 'react';
import { Storage } from 'react-jhipster';
import { Navbar, Nav, NavbarToggler, Collapse, Row, Col, Container } from 'reactstrap';

import { Brand, ChatbotMenuItem } from './header-components';
import { AdminMenu, EntitiesMenu, AccountMenu, LocaleMenu } from '../menus';

export interface IHeaderProps {
  isAuthenticated: boolean;
  isAdmin: boolean;
  isPatient: boolean;
  isMFA: boolean;
  ribbonEnv: string;
  isInProduction: boolean;
  isSwaggerEnabled: boolean;
  currentLocale: string;
  onLocaleChange: Function;
}

const Header = (props: IHeaderProps) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleLocaleChange = event => {
    const langKey = event.target.value;
    Storage.session.set('locale', langKey);
    props.onLocaleChange(langKey);
  };

  const isAdmin = () => props.isAuthenticated && props.isAdmin;
  const isChatbotMenuVisible = () => !props.isInProduction && props.isAuthenticated && props.isPatient;

  const toggleMenu = () => setMenuOpen(!menuOpen);

  /* jhipster-needle-add-element-to-menu - JHipster will add new menu items here */

  return (
    <div id="app-header">
      <Container fluid className="app-header__container">
        <Row >
          <Col xs="12">
            {/* <LoadingBar className="loading-bar" /> */}
            <Navbar light expand="sm" fixed="top" className="color-nav">

              <Brand isMFA={props.isMFA} isAuthenticated={props.isAuthenticated} />
              <NavbarToggler aria-label="Menu" onClick={toggleMenu} />
              <Collapse isOpen={menuOpen} navbar>
                <Nav id="header-tabs" className="ml-auto " navbar>
                  {/* {props.isAuthenticated && !props.isMFA && !props.isPatient && <Home />} */}
                  { isAdmin() && <EntitiesMenu />}
                  { isChatbotMenuVisible() && <ChatbotMenuItem />}
                  { isAdmin() && <AdminMenu showSwagger={props.isSwaggerEnabled} />}
                  {!props.isMFA && !props.isPatient && <LocaleMenu currentLocale={props.currentLocale} onClick={handleLocaleChange} />}
                  <AccountMenu isMFA={props.isMFA} isAuthenticated={props.isAuthenticated} />
                </Nav>
              </Collapse>
            </Navbar>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Header;
