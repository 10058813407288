import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { Translate, translate, ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { getEntity, updateEntity, createEntity, reset } from './scheduled-task.reducer';
import { IScheduledTask } from 'app/shared/model/scheduled-task.model';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';


export enum ScheduledTaskType {
  SEND_REPORT_TO_CLINIC = 1,
  CHATBOT_EMAIL_REMINDER_REQUESTED_BY_PATIENT = 2
}


export enum ScheduledTaskState {
  SCHEDULE = 1,
  DONE = 2,
  FAILED = 3
}



export interface IScheduledTaskUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string; }> { }

export const ScheduledTaskUpdate = (props: IScheduledTaskUpdateProps) => {
  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);

  const { scheduledTaskEntity, loading, updating } = props;

  const handleClose = () => {
    props.history.push('/scheduled-task' + props.location.search);
  };

  useEffect(() => {
    if (isNew) {
      props.reset();
    } else {
      props.getEntity(props.match.params.id);
    }
  }, []);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const saveEntity = (event, errors, values) => {
    values.runTime = convertDateTimeToServer(values.runTime);

    if (errors.length === 0) {
      const entity = {
        ...scheduledTaskEntity,
        ...values,
      };

      if (isNew) {
        props.createEntity(entity);
      } else {
        props.updateEntity(entity);
      }
    }
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="velariaApp.scheduledTask.home.createOrEditLabel">
            <Translate contentKey="velariaApp.scheduledTask.home.createOrEditLabel">Create or edit a ScheduledTask</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
              <AvForm model={isNew ? {} : scheduledTaskEntity} onSubmit={saveEntity}>
                {!isNew ? (
                  <AvGroup>
                    <Label for="scheduled-task-id">
                      <Translate contentKey="global.field.id">ID</Translate>
                    </Label>
                    <AvInput id="scheduled-task-id" type="text" className="form-control" name="id" required readOnly />
                  </AvGroup>
                ) : null}
                <AvGroup>
                  <Label id="typeLabel" for="scheduled-task-type">
                    <Translate contentKey="velariaApp.scheduledTask.type">Type</Translate>
                  </Label>
                  <AvField
                    id="scheduled-task-type"
                    type="select"
                    className="form-control"
                    name="type"
                    value={(!isNew && scheduledTaskEntity.type) || 1}
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') },
                      number: { value: true, errorMessage: translate('entity.validation.number') },
                    }}
                  >
                    <option value={ScheduledTaskType.SEND_REPORT_TO_CLINIC}>{translate('velariaApp.scheduledTask.typeList.sendReportToClinic')}</option>
                    <option value={ScheduledTaskType.CHATBOT_EMAIL_REMINDER_REQUESTED_BY_PATIENT}>{translate('velariaApp.scheduledTask.typeList.chatbotRequestedByPatientEmailReminder')}</option>
                  </AvField>
                </AvGroup>
                <AvGroup>
                  <Label id="runTimeLabel" for="scheduled-task-runTime">
                    <Translate contentKey="velariaApp.scheduledTask.runTime">Run Time</Translate>
                  </Label>
                  <AvInput
                    id="scheduled-task-runTime"
                    type="datetime-local"
                    className="form-control"
                    name="runTime"
                    placeholder={'YYYY-MM-DD HH:mm'}
                    value={isNew ? displayDefaultDateTime() : convertDateTimeFromServer(props.scheduledTaskEntity.runTime)}
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') },
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="requestLabel" for="scheduled-task-request">
                    <Translate contentKey="velariaApp.scheduledTask.request">Request</Translate>
                  </Label>
                  <AvField
                    id="scheduled-task-request"
                    type="text"
                    name="request"
                    validate={{
                      maxLength: { value: 2000, errorMessage: translate('entity.validation.maxlength', { max: 2000 }) },
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="stateLabel" for="scheduled-task-state">
                    <Translate contentKey="velariaApp.scheduledTask.state">State</Translate>
                  </Label>
                  <AvField
                    id="scheduled-task-state"
                    type="select"
                    className="form-control"
                    value={(!isNew && scheduledTaskEntity.state) || 1}
                    name="state"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') },
                      number: { value: true, errorMessage: translate('entity.validation.number') },
                    }}
                  >
                    <option value={ScheduledTaskState.SCHEDULE}>{translate('velariaApp.scheduledTask.stateList.schedule')}</option>
                    <option value={ScheduledTaskState.DONE}>{translate('velariaApp.scheduledTask.stateList.done')}</option>
                    <option value={ScheduledTaskState.FAILED}>{translate('velariaApp.scheduledTask.stateList.failed')}</option>
                  </AvField>
                </AvGroup>
                <Button tag={Link} id="cancel-save" to="/scheduled-task" replace color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
              &nbsp;
                <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </AvForm>
            )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  scheduledTaskEntity: storeState.scheduledTask.entity,
  loading: storeState.scheduledTask.loading,
  updating: storeState.scheduledTask.updating,
  updateSuccess: storeState.scheduledTask.updateSuccess,
});

const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ScheduledTaskUpdate);
