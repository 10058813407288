import React from 'react';
import MenuItem from 'app/shared/layout/menus/menu-item';
import { Translate, translate } from 'react-jhipster';
import { NavDropdown } from './menu-components';
import { DropDownToggle } from 'reactstrap';

export const EntitiesMenu = props => (
  <NavDropdown
    icon="th-list"
    name={translate('global.menu.entities.main')}
    id="entity-menu"
    style={{ maxHeight: '80vh', overflow: 'auto' }}
  >
    <MenuItem icon="asterisk" to="/clinic" id="clinic">
      <Translate contentKey="global.menu.entities.clinic" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/medical-assistant" id="medical-assistant">
      <Translate contentKey="global.menu.entities.medicalAssistant" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/patient" id="patient">
      <Translate contentKey="global.menu.entities.patient" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/healthcare-professional" id="healthcare-professional">
      <Translate contentKey="global.menu.entities.healthcareProfessional" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/appointment" id="appointment">
      <Translate contentKey="global.menu.entities.appointment" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/icd-code" id="icd-code">
      <Translate contentKey="global.menu.entities.icdCode" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/terms-and-conditions-content" id="terms-and-conditions-content">
      <Translate contentKey="global.menu.entities.termsAndConditionsContent" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/conversation-catalog-de" id="conversation-catalog-de">
      <Translate contentKey="global.menu.entities.conversationCatalogDe" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/conversation-catalog-tr" id="conversation-catalog-tr">
      <Translate contentKey="global.menu.entities.conversationCatalogTr" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/conversation-catalog-en" id="conversation-catalog-en">
      <Translate contentKey="global.menu.entities.conversationCatalogEn" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/conversation-event" id="conversation-event">
      <Translate contentKey="global.menu.entities.conversationEvent" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/conversation-summary" id="conversation-summary">
      <Translate contentKey="global.menu.entities.conversationSummary" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/insurance-code" id="insurance-code">
      <Translate contentKey="global.menu.entities.insuranceCode" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/scheduled-task" id="scheduled-task">
      <Translate contentKey="global.menu.entities.scheduledTask" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/scheduled-task-execution" id="scheduled-task-execution">
      <Translate contentKey="global.menu.entities.scheduledTaskExecution" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/vaccination" id="vaccination">
      <Translate contentKey="global.menu.entities.vaccination" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/self-registration" id="self-registration">
      <Translate contentKey="global.menu.entities.selfRegistration" />
    </MenuItem>
    {/* jhipster-needle-add-entity-to-menu - JHipster will add entities to the menu here */}
  </NavDropdown>
);
