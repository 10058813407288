import { Moment } from 'moment';

export interface IConversationEvent {
  id?: number;
  conversationId?: number;
  messageId?: number;
  messageText?: string;
  messageType?: string;
  replyId?: number;
  replyText?: string;
  messageTimestamp?: string;
  replyTimestamp?: string;
  conversationCategory?: string;
  quickReplies?: QuickReply[];
}

interface QuickReply {
  replyId: number;
  replyType: string;
  quickReply: string;
}

export const defaultValue: Readonly<IConversationEvent> = {};
