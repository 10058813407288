import React from 'react';
import './SideBarLinkBox.css';
import { Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { URL_SUFFIX, URL_PREFIX } from '../pages-list';

interface SideBarLinkBoxProps {
  svg: any;
  text: string;
  urlSuffix: URL_SUFFIX;
}

const SideBarLinkBox = (props: SideBarLinkBoxProps) => {
  return (
    <Col xs={6} sm={10} lg={{ span: 5, offset: 2 }} >
      <Link to={URL_PREFIX.MA_DASHBOARD + props.urlSuffix} style={{ textDecoration: 'none' }}>
        <div id='side-bar-link' className="side-bar-link-box side-bar-link-box-hover center">
          <img src={props.svg} alt='icon' className="center"></img>
          <p>{props.text}</p>
        </div>
      </Link>
    </Col>
  );
};

export default SideBarLinkBox;
