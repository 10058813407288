import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Row } from 'reactstrap';
import { IRootState } from 'app/shared/reducers';
import { retrieveDocumentData } from './generate-document-page-reducer';
import { RouteComponentProps } from 'react-router-dom';
import parse from 'html-react-parser';
import { Translate, getUrlParameter } from 'react-jhipster';
import { setLocale } from 'app/shared/reducers/locale';
import { generatePDFv2, PDFFILES } from 'app/pages/medical-assistant-dashboard/shard/pdfUtils';

export interface IGenerateDocumentPage extends StateProps, DispatchProps, RouteComponentProps<{ filename: any; }> { }

export const GenerateDocumentPage = (props: IGenerateDocumentPage) => {

    const downloadPDF = (filename) => {
        const language:string = getUrlParameter('language', props.location.search) || 'en';
        const files = {
            en: {
                dataProcessing: PDFFILES.DATA_PROCESSING_EN,
                terms: PDFFILES.TNC_EN,
                policy: PDFFILES.PRIVACY_POLICY_EN,
            },
            de: {
                dataProcessing: PDFFILES.DATA_PROCESSING_DE,
                terms: PDFFILES.TNC_DE,
                policy: PDFFILES.PRIVACY_POLICY_DE,
            },
            tr: {
                dataProcessing: PDFFILES.DATA_PROCESSING_TR,
                terms: PDFFILES.TNC_TR,
                policy: PDFFILES.PRIVACY_POLICY_TR,
            }
        }

        generatePDFv2(files?.[language]?.[filename])
    }
    useEffect(() => {
        const filename:any = getUrlParameter('filename', props.location.search);
        if ([
            'dataProcessing',
            'terms',
            'policy'
        ].includes(filename)) {
            downloadPDF(filename)
        } else {
            filename && props.retrieveDocumentData(filename, props.locale);
        }
    }, []);

    return (
        <Row style={{ margin: "2rem", display: "flow-root" }}>
            {props.content ? parse(props.content) : ''}
            {props.callStatus.loading ? 'Loading...' : ''}
            <Row style={{ margin: "2rem", marginBottom: "100%" }}>
                <p style={{ color: "red" }}>{props.callStatus.failure ?
                    <Translate contentKey="activationTermsConditions.callStatusFailed"></Translate> : ''}</p>
            </Row>
        </Row>
    );

};

const mapStateToProps = ({ generateDocumentReducer, locale }: IRootState) => ({
    content: generateDocumentReducer.response,
    locale: locale.currentLocale,
    callStatus: generateDocumentReducer
});

const mapDispatchToProps = { retrieveDocumentData, setLocale };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(GenerateDocumentPage);
