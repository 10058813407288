import axios from 'axios';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

export const ACTION_TYPES = {
  VERIFY_SMS: 'smsCodeVerification/VERIFY_SMS',
  RESET: 'smsCodeVerification/RESET'
};

const initialState = {
  verificationSuccess: false,
  verificationFailure: false
};

export type SmsVerificationState = Readonly<typeof initialState>;

// Reducer
export default (state: SmsVerificationState = initialState, action): SmsVerificationState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.VERIFY_SMS):
      return {
        ...state
      };
    case FAILURE(ACTION_TYPES.VERIFY_SMS):
      return {
        ...state,
        verificationSuccess: false,
        verificationFailure: true
      };
    case SUCCESS(ACTION_TYPES.VERIFY_SMS):
      return {
        ...state,
        verificationSuccess: true,
        verificationFailure: false
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

// Actions

export const sendSms = (key: string) => ({
  type: ACTION_TYPES.VERIFY_SMS,
  payload: axios.post('api/send-sms', { key })
});

export const verifySms = (key: string, smsCode: string) => ({
  type: ACTION_TYPES.VERIFY_SMS,
  payload: axios.post('api/verify-sms', { key, smsCode })
});

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
