import { IClinic } from 'app/shared/model/clinic.model';

export interface IMedicalAssistant {
  id?: number;
  userId?: number;
  salutation?: string;
  title?: string;
  firstName?: string;
  lastName?: string;
  gender?: string;
  email?: string;
  mobileNumber?: string;
  langKey?: string;
  verificationStatus?: string;
  clinics?: IClinic[];
}

export const defaultValue: Readonly<IMedicalAssistant> = {};
