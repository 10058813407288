import axios from 'axios';
import { translate } from 'react-jhipster';

import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';
import { TermsAndConditionsContentType } from '../models';

export const ACTION_TYPES = {
  AGREE_TERMS_CONDITIONS: 'terms-conditions/AGREE_TERMS_CONDITIONS',
  VERFIY_TERMS_CONDITIONS: 'terms-conditions/VERFIY_TERMS_CONDITIONS',
  GET_USER_STATUS: 'terms-conditions/GET_USER_STATUS',
  RESET: 'terms-conditions/RESET',
  RETRIEVE_CONSENT: 'terms-conditions/RETRIEVE_CONSENT',
  RETRIEVE_CONSENT_RESPONSE: 'terms-conditions/RETRIEVE_CONSENT_RESPONSE',
  RETRIEVE_PRIVACY_DATA: 'terms-conditions/RETRIEVE_PRIVACY_DATA',
  RETRIEVE_PRIVACY_DATA_RESPONSE: 'terms-conditions/RETRIEVE_PRIVACY_DATA_RESPONSE',
  RETRIEVE_TERMS_AND_CONDITIONS_DATA: 'terms-conditions/RETRIEVE_TERMS_AND_CONDITIONS_DATA',
  RETRIEVE_TERMS_AND_CONDITIONS_DATA_RESPONSE: 'terms-conditions/RETRIEVE_TERMS_AND_CONDITIONS_DATA_RESPONSE',
  RETRIEVE_STUDY_CONSENT_DATA: 'terms-conditions/RETRIEVE_STUDY_CONSENT_DATA',
  RETRIEVE_STUDY_CONSENT_DATA_RESPONSE: 'terms-conditions/RETRIEVE_STUDY_CONSENT_DATA_RESPONSE'
};

const initialState = {
  verifyTermsAndConditionsAPI: {
    errorMessage: null,
    loading: false,
    updateSuccess: false,
    updateFailure: false,
    response: ''
  },
  agreeTermsAndConditionsAPI: {
    errorMessage: null,
    loading: false,
    updateSuccess: false,
    updateFailure: false
  },
  termsAndConditionsContentConsentAPI: {
    errorMessage: null,
    loading: false,
    updateSuccess: false,
    updateFailure: false,
    response: ''
  },
  termsAndConditionsContentPrivacyAPI: {
    errorMessage: null,
    loading: false,
    updateSuccess: false,
    updateFailure: false,
    response: ''
  },
  termsAndConditionsContentDataAPI: {
    errorMessage: null,
    loading: false,
    updateSuccess: false,
    updateFailure: false,
    response: ''
  },
  termsAndConditionsStudyContentDataAPI: {
    errorMessage: null,
    loading: false,
    updateSuccess: false,
    updateFailure: false,
    response: ''
  }
};

export type TermsAndConditionsState = Readonly<typeof initialState>;

// Reducer
export default (state: TermsAndConditionsState = initialState, action): TermsAndConditionsState => {
  switch (action.type) {
    case ACTION_TYPES.GET_USER_STATUS:
      return {
        ...state,
        verifyTermsAndConditionsAPI: {
          ...state.verifyTermsAndConditionsAPI,
          response: action.payload
        }
      };
    case ACTION_TYPES.RETRIEVE_CONSENT_RESPONSE:
      return {
        ...state,
        termsAndConditionsContentConsentAPI: {
          ...state.termsAndConditionsContentConsentAPI,
          response: action.payload
        }
      };
    case ACTION_TYPES.RETRIEVE_PRIVACY_DATA_RESPONSE:
      return {
        ...state,
        termsAndConditionsContentPrivacyAPI: {
          ...state.termsAndConditionsContentPrivacyAPI,
          response: action.payload
        }
      };
    case ACTION_TYPES.RETRIEVE_TERMS_AND_CONDITIONS_DATA_RESPONSE:
      return {
        ...state,
        termsAndConditionsContentDataAPI: {
          ...state.termsAndConditionsContentDataAPI,
          response: action.payload
        }
      };
    case ACTION_TYPES.RETRIEVE_STUDY_CONSENT_DATA_RESPONSE:
      return {
        ...state,
        termsAndConditionsStudyContentDataAPI: {
          ...state.termsAndConditionsStudyContentDataAPI,
          response: action.payload
        }
      };
    case REQUEST(ACTION_TYPES.RETRIEVE_TERMS_AND_CONDITIONS_DATA):
      return {
        ...state,
        termsAndConditionsContentDataAPI: {
          ...state.termsAndConditionsContentDataAPI,
          loading: true,
          updateSuccess: false,
          updateFailure: false,
          errorMessage: null
        }
      };
    case REQUEST(ACTION_TYPES.RETRIEVE_PRIVACY_DATA):
      return {
        ...state,
        termsAndConditionsContentDataAPI: {
          ...state.termsAndConditionsContentDataAPI,
          loading: true,
          updateSuccess: false,
          updateFailure: false,
          errorMessage: null
        }
      };
    case REQUEST(ACTION_TYPES.RETRIEVE_STUDY_CONSENT_DATA):
      return {
        ...state,
        termsAndConditionsStudyContentDataAPI: {
          ...state.termsAndConditionsStudyContentDataAPI,
          loading: true,
          updateSuccess: false,
          updateFailure: false,
          errorMessage: null
        }
      };
    case REQUEST(ACTION_TYPES.RETRIEVE_CONSENT):
      return {
        ...state,
        termsAndConditionsContentConsentAPI: {
          ...state.termsAndConditionsContentConsentAPI,
          loading: true,
          updateSuccess: false,
          updateFailure: false,
          errorMessage: null
        }
      };
    case REQUEST(ACTION_TYPES.AGREE_TERMS_CONDITIONS):
      return {
        ...state,
        agreeTermsAndConditionsAPI: {
          ...state.agreeTermsAndConditionsAPI,
          loading: true,
          updateSuccess: false,
          updateFailure: false,
          errorMessage: null
        }
      };
    case REQUEST(ACTION_TYPES.VERFIY_TERMS_CONDITIONS):
      return {
        ...state,
        verifyTermsAndConditionsAPI: {
          ...state.verifyTermsAndConditionsAPI,
          errorMessage: null,
          loading: true,
          updateSuccess: false,
          updateFailure: false
        }
      };
    case FAILURE(ACTION_TYPES.RETRIEVE_PRIVACY_DATA):
      return {
        ...state,
        termsAndConditionsContentPrivacyAPI: {
          ...state.termsAndConditionsContentPrivacyAPI,
          loading: false,
          updateSuccess: false,
          updateFailure: true
        }
      };
    case FAILURE(ACTION_TYPES.RETRIEVE_STUDY_CONSENT_DATA):
      return {
        ...state,
        termsAndConditionsStudyContentDataAPI: {
          ...state.termsAndConditionsStudyContentDataAPI,
          loading: false,
          updateSuccess: false,
          updateFailure: true
        }
      };
    case FAILURE(ACTION_TYPES.RETRIEVE_TERMS_AND_CONDITIONS_DATA):
      return {
        ...state,
        termsAndConditionsContentDataAPI: {
          ...state.termsAndConditionsContentDataAPI,
          loading: false,
          updateSuccess: false,
          updateFailure: true
        }
      };
    case FAILURE(ACTION_TYPES.RETRIEVE_CONSENT):
      return {
        ...state,
        termsAndConditionsContentConsentAPI: {
          ...state.termsAndConditionsContentConsentAPI,
          loading: false,
          updateSuccess: false,
          updateFailure: true
        }
      };
    case FAILURE(ACTION_TYPES.AGREE_TERMS_CONDITIONS):
      return {
        ...state,
        agreeTermsAndConditionsAPI: {
          ...state.agreeTermsAndConditionsAPI,
          loading: false,
          updateSuccess: false,
          updateFailure: true
        }
      };
    case FAILURE(ACTION_TYPES.VERFIY_TERMS_CONDITIONS):
      return {
        ...state,
        verifyTermsAndConditionsAPI: {
          ...state.verifyTermsAndConditionsAPI,
          loading: false,
          updateSuccess: false,
          updateFailure: true
        }
      };
    case SUCCESS(ACTION_TYPES.RETRIEVE_CONSENT):
      return {
        ...state,
        termsAndConditionsContentConsentAPI: {
          ...state.termsAndConditionsContentConsentAPI,
          loading: false,
          updateSuccess: true,
          updateFailure: false
        }
      };
    case SUCCESS(ACTION_TYPES.RETRIEVE_TERMS_AND_CONDITIONS_DATA):
      return {
        ...state,
        termsAndConditionsContentDataAPI: {
          ...state.termsAndConditionsContentDataAPI,
          loading: false,
          updateSuccess: true,
          updateFailure: false
        }
      };
    case SUCCESS(ACTION_TYPES.RETRIEVE_STUDY_CONSENT_DATA):
      return {
        ...state,
        termsAndConditionsStudyContentDataAPI: {
          ...state.termsAndConditionsStudyContentDataAPI,
          loading: false,
          updateSuccess: true,
          updateFailure: false
        }
      };
    case SUCCESS(ACTION_TYPES.RETRIEVE_PRIVACY_DATA):
      return {
        ...state,
        termsAndConditionsContentPrivacyAPI: {
          ...state.termsAndConditionsContentPrivacyAPI,
          loading: false,
          updateSuccess: true,
          updateFailure: false
        }
      };
    case SUCCESS(ACTION_TYPES.AGREE_TERMS_CONDITIONS):
      return {
        ...state,
        agreeTermsAndConditionsAPI: {
          ...state.agreeTermsAndConditionsAPI,
          loading: false,
          updateSuccess: true,
          updateFailure: false
        }
      };
    case SUCCESS(ACTION_TYPES.VERFIY_TERMS_CONDITIONS):
      return {
        ...state,
        verifyTermsAndConditionsAPI: {
          ...state.verifyTermsAndConditionsAPI,
          loading: false,
          updateSuccess: true,
          updateFailure: false
        }
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

// Actions

export const agreeTermsAndConditions = (acceptanceDate: string, key: string, additionalData = {}) => async dispatch => {
  await dispatch({
    type: ACTION_TYPES.AGREE_TERMS_CONDITIONS,
    payload: axios.post(`api/account/agree-terms-conditions`, { acceptanceDate, key, ...additionalData })
  });
};

export const getUserStatus = (response: string) => ({
  type: ACTION_TYPES.GET_USER_STATUS,
  payload: response
});

export const verifyTermsAndConditions = (key: string) => async dispatch => {
  await dispatch({
    type: ACTION_TYPES.VERFIY_TERMS_CONDITIONS,
    payload: axios.get(`api/account/verify-terms-conditions`, { params: { key } }).then(response => {
      dispatch(getUserStatus(response.data));
    })
  });
};

export const getActionResponseType = (type: string) => {
  if (type === TermsAndConditionsContentType.CONSENT) return ACTION_TYPES.RETRIEVE_CONSENT_RESPONSE;
  if (type === TermsAndConditionsContentType.PRIVACY) return ACTION_TYPES.RETRIEVE_PRIVACY_DATA_RESPONSE;
  if (type === TermsAndConditionsContentType.TERMS_AND_CONDITIONS) return ACTION_TYPES.RETRIEVE_TERMS_AND_CONDITIONS_DATA_RESPONSE;
  if (type === TermsAndConditionsContentType.DATA_PROCESSING_FOR_DEVELOPMENT)
    return ACTION_TYPES.RETRIEVE_TERMS_AND_CONDITIONS_DATA_RESPONSE;
  if (type === TermsAndConditionsContentType.Study) return ACTION_TYPES.RETRIEVE_STUDY_CONSENT_DATA_RESPONSE;
};

export const getTermsAndConditionsData = (response: any) => ({
  type: getActionResponseType(response.inputType),
  payload: response.content
});

export const getActionType = (type: string) => {
  if (type === TermsAndConditionsContentType.CONSENT) return ACTION_TYPES.RETRIEVE_CONSENT;
  if (type === TermsAndConditionsContentType.PRIVACY) return ACTION_TYPES.RETRIEVE_PRIVACY_DATA;
  if (type === TermsAndConditionsContentType.TERMS_AND_CONDITIONS) return ACTION_TYPES.RETRIEVE_TERMS_AND_CONDITIONS_DATA;
  if (type === TermsAndConditionsContentType.DATA_PROCESSING_FOR_DEVELOPMENT) return ACTION_TYPES.RETRIEVE_TERMS_AND_CONDITIONS_DATA;
  if (type === TermsAndConditionsContentType.Study) return ACTION_TYPES.RETRIEVE_TERMS_AND_CONDITIONS_DATA;
};

export const retrieveTermsAndConditionsData = (language: string, inputType: string) => async dispatch => {
  await dispatch({
    type: getActionType(inputType),
    payload: axios.get(`api/terms-and-conditions-content`, { params: { language, inputType } }).then(response => {
      dispatch(getTermsAndConditionsData(response.data));
    })
  });
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
