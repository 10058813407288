import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Table, Spinner, Col, Row, Button } from 'reactstrap';
import { Translate } from 'react-jhipster';

import { IRootState } from 'app/shared/reducers';
import { getEntitiesByQuery } from 'app/entities/appointment/appointment.reducer';
import { updateSelectedPatient } from '../ma-dashboard.reducer';
import { URL_PREFIX, URL_SUFFIX } from '../ma-dashboard-layout/pages-list';
import '../ma-pages.scss';
import { generateReport } from '../shard/reportUtil';
import { Link } from 'react-router-dom';
import { getSession } from 'app/shared/reducers/authentication';

export interface IMedicalAssistantPatients extends StateProps, DispatchProps { }

const MedicalAssistantPatients = (props: IMedicalAssistantPatients) => {
    useEffect(() => {
        props.selectedClinic && props.selectedClinic.id && props.getEntitiesByQuery('clinicId.equals=' + props.selectedClinic.id)
    }, [props.selectedClinic]);

    useEffect(() => { }, [props.appointmentsList])

    const printReport = (patientId) => {
        generateReport(patientId);
    };

    const navigateToEditPatient = (patientToUpdate) => {
        props.updateSelectedPatient(patientToUpdate);

    };

    return (
        <Row>
            <Col>
                <div className="table-responsive">
                    {props.appointmentsList && props.appointmentsList.length > 0 ? (
                        <Table responsive>
                            <thead>
                                <tr style={{ textAlign: "center" }}>
                                    <th>
                                        {''}
                                    </th>
                                    <th>
                                        <Translate contentKey="velariaApp.patient.lastName">Last Name</Translate>
                                    </th>
                                    <th>
                                        <Translate contentKey="velariaApp.patient.firstName">First Name</Translate>
                                    </th>
                                    <th>
                                        <Translate contentKey="velariaApp.patient.velaria">velaria</Translate>
                                    </th>
                                    <th>
                                        <Translate contentKey="velariaApp.patient.patientCard">Patient Card</Translate>
                                    </th>
                                    <th>
                                        <Translate contentKey="velariaApp.patient.appointmentDate">Appointment Date</Translate>
                                    </th>
                                    <th>
                                        <Translate contentKey="velariaApp.patient.report">Report</Translate>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {props.appointmentsList.map((appointment, i) => {
                                    return (
                                        <tr key={`entity-${i}`} style={{ textAlign: "center" }}>
                                            <td>
                                                {`${i + 1}`}
                                            </td>
                                            <td>
                                                {`${appointment.patient.lastName}`}
                                            </td>
                                            <td>
                                                {`${appointment.patient.firstName}`}
                                            </td>
                                            {appointment.patient.mobileAppInstalled === true ?
                                                <td style={{ color: "green" }}>{"Active"}</td> :
                                                <td style={{ color: "red" }}>{"Not Installed"}</td>}
                                            <td>
                                                <Link to={URL_PREFIX.MA_DASHBOARD + URL_SUFFIX.CHANGE_PATIENT}
                                                    style={{ color: "black", textDecoration: "underline" }}
                                                    onClick={() => navigateToEditPatient(appointment.patient)}>
                                                    {`Details`}
                                                </Link>
                                            </td>
                                            <td>
                                                {new Date(appointment.date).toLocaleDateString(props.currentLocale, {timeZone: 'Europe/Berlin'})}
                                            </td>
                                            <td style={{ cursor: "pointer" }}>
                                                <Button className="tableButton" onClick={() => printReport(appointment.patient.id)}><Translate contentKey="velariaApp.patient.open">Open</Translate></Button>
                                            </td>
                                        </tr>)
                                })}
                            </tbody>
                        </Table>
                    ) : (<Col><div><Translate contentKey="velariaApp.patient.home.notFound">No patients found</Translate></div></Col>)}
                </div>
            </Col >
        </Row>
    );
};

const mapStateToProps = (storeState: IRootState) => ({
    appointmentsList: storeState.appointment.entities,
    selectedClinic: storeState.maDashboardReducer.selectedClinic,
    updateSuccess: storeState.maDashboardReducer.updateSuccess,
    currentLocale: storeState.locale.currentLocale,
    account: storeState.authentication.account
});

const mapDispatchToProps = {
    getEntitiesByQuery,
    updateSelectedPatient,
    getSession
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(MedicalAssistantPatients);
