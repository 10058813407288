import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Row, Table } from 'reactstrap';
import { Translate, ICrudGetAllAction, TextFormat, getSortState, IPaginationBaseState, JhiPagination, JhiItemCount } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntities } from './conversation-summary.reducer';
import { IConversationSummary } from 'app/shared/model/conversation-summary.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';

export interface IConversationSummaryProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string; }> { }

export const ConversationSummary = (props: IConversationSummaryProps) => {
  const [paginationState, setPaginationState] = useState(getSortState(props.location, ITEMS_PER_PAGE));

  const getAllEntities = () => {
    props.getEntities(paginationState.activePage - 1, paginationState.itemsPerPage, `${paginationState.sort},${paginationState.order}`);
  };

  const sortEntities = () => {
    getAllEntities();
    props.history.push(
      `${props.location.pathname}?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`
    );
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === 'asc' ? 'desc' : 'asc',
      sort: p
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage
    });

  const { conversationSummaryList, match, loading, totalItems } = props;
  return (
    <div>
      <h2 id="conversation-summary-heading">
        <Translate contentKey="velariaApp.conversationSummary.home.title">Conversation Summaries</Translate>
        <Link to={`${match.url}/new`} className="btn btn-outline-primary float-right jh-create-entity" id="jh-create-entity">
          <FontAwesomeIcon icon="plus" />
          &nbsp;
          <Translate contentKey="velariaApp.conversationSummary.home.createLabel">Create new Conversation Summary</Translate>
        </Link>
      </h2>
      <div className="table-responsive">
        {conversationSummaryList && conversationSummaryList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th className="hand" onClick={sort('id')}>
                  <Translate contentKey="global.field.id">ID</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('entityId')}>
                  <Translate contentKey="velariaApp.conversationSummary.entityId">Entity Id</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('conversationTypeId')}>
                  <Translate contentKey="velariaApp.conversationSummary.conversationTypeId">Conversation Type Id</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('conversationType')}>
                  <Translate contentKey="velariaApp.conversationSummary.conversationType">Conversation Type</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('appointmentId')}>
                  <Translate contentKey="velariaApp.conversationSummary.appointmentId">Appointment Id</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('startTime')}>
                  <Translate contentKey="velariaApp.conversationSummary.startTime">Start Time</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('endTime')}>
                  <Translate contentKey="velariaApp.conversationSummary.endTime">End Time</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('currentMessageId')}>
                  <Translate contentKey="velariaApp.conversationSummary.currentMessageId">Current Message Id</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('requiredSince')}>
                  <Translate contentKey="velariaApp.conversationSummary.requiredSince">Required Since</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('status')}>
                  <Translate contentKey="velariaApp.conversationSummary.status">Status</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {conversationSummaryList.map((conversationSummary, i) => (
                <tr key={`entity-${i}`}>
                  <td>
                    <Button tag={Link} to={`${match.url}/${conversationSummary.id}`} color="link" size="sm">
                      {conversationSummary.id}
                    </Button>
                  </td>
                  <td>{conversationSummary.entityId}</td>
                  <td>{conversationSummary.conversationTypeId}</td>
                  <td>{conversationSummary.conversationType}</td>
                  <td>{conversationSummary.appointmentId}</td>
                  <td>
                    <TextFormat type="date" value={conversationSummary.startTime} format={APP_DATE_FORMAT} />
                  </td>
                  <td>
                    <TextFormat type="date" value={conversationSummary.endTime} format={APP_DATE_FORMAT} />
                  </td>
                  <td>{conversationSummary.currentMessageId}</td>
                  <td>
                    <TextFormat type="date" value={conversationSummary.requiredSince} format={APP_LOCAL_DATE_FORMAT} />
                  </td>
                  <td>{conversationSummary.status}</td>
                  <td className="text-right">
                    <div className="btn-group flex-btn-group-container">
                      <Button tag={Link} to={`${match.url}/${conversationSummary.id}`}
                        outline
                        className="rounded mr-1"
                        color="success"
                        size="sm">
                        <FontAwesomeIcon icon="eye" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">View</Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`${match.url}/${conversationSummary.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        outline
                        className="rounded mr-1"
                        color="primary"
                        size="sm"
                      >
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`${match.url}/${conversationSummary.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        outline
                        className="rounded mr-1"
                        color="danger"
                        size="sm"
                      >
                        <FontAwesomeIcon icon="trash" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">Delete</Translate>
                        </span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
            !loading && (
              <div className="alert alert-warning">
                <Translate contentKey="velariaApp.conversationSummary.home.notFound">No Conversation Summaries found</Translate>
              </div>
            )
          )}
      </div>
      <div className={conversationSummaryList && conversationSummaryList.length > 0 ? '' : 'd-none'}>
        <Row className="justify-content-center">
          <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
        </Row>
        <Row className="justify-content-center">
          <JhiPagination
            activePage={paginationState.activePage}
            onSelect={handlePagination}
            maxButtons={5}
            itemsPerPage={paginationState.itemsPerPage}
            totalItems={props.totalItems}
          />
        </Row>
      </div>
    </div>
  );
};

const mapStateToProps = ({ conversationSummary }: IRootState) => ({
  conversationSummaryList: conversationSummary.entities,
  loading: conversationSummary.loading,
  totalItems: conversationSummary.totalItems
});

const mapDispatchToProps = {
  getEntities
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ConversationSummary);
