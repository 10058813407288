import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';

interface InputConditionValidatorProps {
    validatorText: string;
    isValid: boolean;
}

const VALID_CLASSNAME = "valid";
const NOT_VALID_CLASSNAME = "not-valid";

const CHECKED_SVG = "content/images/circle-checked.svg";
const NOT_CHECKED_SVG = "content/images/circle-not-checked.svg";


const InputConditionValidator = (props: InputConditionValidatorProps) => {

    const [svg, setSvg] = useState(NOT_CHECKED_SVG);
    const [validationClassName, setValidationClassName] = useState(VALID_CLASSNAME);

    useEffect(() => {
        setSvg(props.isValid ? CHECKED_SVG : NOT_CHECKED_SVG);
        setValidationClassName(props.isValid ? VALID_CLASSNAME : NOT_VALID_CLASSNAME);

    }, [props.isValid]);

    return (
        <Row style={props.isValid ? { color: "green" } : { color: "red" }}>
            <Col xs={1}>
                <img src={svg} alt='icon' />
            </Col>
            <Col>
                <div>{props.validatorText}</div>
            </Col>
        </Row>
    );
};

export default InputConditionValidator;
