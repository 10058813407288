import React from 'react';
import { IAppointment } from 'app/shared/model/appointment.model';
import { updateSelectedAppointment, updateSelectedPatient } from 'app/pages/medical-assistant-dashboard/ma-dashboard.reducer';
import { connect } from 'react-redux';
import { MA_DASH_PAGE, URL_PREFIX, URL_SUFFIX } from '../ma-dashboard-layout/pages-list';
import { translate, Translate, TextFormat } from 'react-jhipster';
import { IRootState } from 'app/shared/reducers';
import { generateReport } from '../shard/reportUtil';
import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import moment from 'moment';
import 'moment-timezone';

interface PatientAppointmentDetailsProps extends DispatchProps, StateProps {
    patientAppointmentDetails: IAppointment;
    isShowDate: boolean;
}

const MedicalAssistantAppointmentRow = (props: PatientAppointmentDetailsProps): any => {

    const appointment = props.patientAppointmentDetails;

    const navigateToAppointment = (appointmentToUpdate) => {
        props.updateSelectedAppointment(appointmentToUpdate);
    };

    const printReport = (patientId) => {
        generateReport(patientId);
    };

    const navigateToEditPatient = (patientToUpdate) => {
        props.updateSelectedPatient(patientToUpdate);
    };


    return (
        <tr style={{ textAlign: "center" }}>
            <td>
                    {`${appointment.patient.firstName}`}
            </td>
            <td>
                    {`${appointment.patient.lastName}`}
            </td>
            <td>
                {`${moment.tz(moment(appointment.date),'Europe/Berlin').format('HH:mm')}`}
            </td>
            <td
                style={
                    appointment.patient.mobileAppInstalled
                        ? { color: "green" }
                        : { color: "red" }
                }>
                {appointment.patient.mobileAppInstalled ? translate("maDashboard.general.active") : translate("maDashboard.general.notInstalled")}
            </td>
            <td style={{ cursor: "pointer" }}>
                <Button className="tableButton" onClick={() => printReport(appointment.patient.id)}>
                    <Translate contentKey="velariaApp.patient.open">Open</Translate>
                </Button>
            </td>
        </tr>
    );
};

const mapStateToProps = (storeState: IRootState) => ({
    currentLocale: storeState.locale.currentLocale
});

const mapDispatchToProps = {
    updateSelectedAppointment,
    updateSelectedPatient
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(MedicalAssistantAppointmentRow);

