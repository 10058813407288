import React from 'react';
import { Switch } from 'react-router-dom';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import Clinic from './clinic';
import MedicalAssistant from './medical-assistant';
import Patient from './patient';
import HealthcareProfessional from './healthcare-professional';
import Appointment from './appointment';
import PrivateRoute from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';
import ConversationEvent from './conversation-event';
import ConversationSummary from './conversation-summary';
import IcdCode from './icd-code';
import TermsAndConditionsContent from './terms-and-conditions-content';
import ConversationCatalogDe from './conversation-catalog-de';
import ConversationCatalogTr from './conversation-catalog-tr';
import ConversationCatalogEn from './conversation-catalog-en';
import InsuranceCode from './insurance-code';
import ScheduledTask from './scheduled-task';
import ScheduledTaskExecution from './scheduled-task-execution';
import SelfRegistration from './self-registration';
import Vaccination from './vaccination';
/* jhipster-needle-add-route-import - JHipster will add routes here */

const Routes = ({ match }) => (
  <div>
    <Switch>
      {/* prettier-ignore */}
      <PrivateRoute path={`${match.url}clinic`} component={Clinic} hasAnyAuthorities={[AUTHORITIES.ADMIN]} />
      <PrivateRoute
        path={`${match.url}medical-assistant`}
        component={MedicalAssistant}
        hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.MEDICAL_ASSISTANT, AUTHORITIES.USER]}
      />
      <PrivateRoute path={`${match.url}patient`} component={Patient} hasAnyAuthorities={[AUTHORITIES.ADMIN]} />
      <PrivateRoute
        path={`${match.url}healthcare-professional`}
        component={HealthcareProfessional}
        hasAnyAuthorities={[AUTHORITIES.ADMIN]}
      />
      <PrivateRoute path={`${match.url}appointment`} component={Appointment} hasAnyAuthorities={[AUTHORITIES.ADMIN]} />
      <ErrorBoundaryRoute path={`${match.url}conversation-event`} component={ConversationEvent} />
      <ErrorBoundaryRoute path={`${match.url}conversation-summary`} component={ConversationSummary} />
      <ErrorBoundaryRoute path={`${match.url}clinic`} component={Clinic} />
      <ErrorBoundaryRoute path={`${match.url}healthcare-professional`} component={HealthcareProfessional} />
      <ErrorBoundaryRoute path={`${match.url}medical-assistant`} component={MedicalAssistant} />
      <ErrorBoundaryRoute path={`${match.url}patient`} component={Patient} />
      <ErrorBoundaryRoute path={`${match.url}appointment`} component={Appointment} />
      <ErrorBoundaryRoute path={`${match.url}icd-code`} component={IcdCode} />
      <ErrorBoundaryRoute path={`${match.url}terms-and-conditions-content`} component={TermsAndConditionsContent} />
      <ErrorBoundaryRoute path={`${match.url}conversation-catalog-de`} component={ConversationCatalogDe} />
      <ErrorBoundaryRoute path={`${match.url}conversation-catalog-tr`} component={ConversationCatalogTr} />
      <ErrorBoundaryRoute path={`${match.url}conversation-catalog-en`} component={ConversationCatalogEn} />
      <ErrorBoundaryRoute path={`${match.url}insurance-code`} component={InsuranceCode} />
      <ErrorBoundaryRoute path={`${match.url}scheduled-task`} component={ScheduledTask} />
      <ErrorBoundaryRoute path={`${match.url}scheduled-task-execution`} component={ScheduledTaskExecution} />
      <ErrorBoundaryRoute path={`${match.url}vaccination`} component={Vaccination} />
      <ErrorBoundaryRoute path={`${match.url}self-registration`} component={SelfRegistration} />
      {/* jhipster-needle-add-route-path - JHipster will add routes here */}
    </Switch>
  </div>
);

export default Routes;
