import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import InputConditionValidator from './input-condition-validator';
import { translate } from 'react-jhipster';

interface IPasswordIndicatorBoxProps {
    passwordToTest: string;
    isValidationPassed: any;
}

const PasswordIndicatorBox = (props: IPasswordIndicatorBoxProps) => {

    const [isCapitalLetter, setIsCapitalLetter] = useState(false);
    const [isLowerLetter, setIsLowerLetter] = useState(false);
    const [isNumberDigit, setIsNumberDigit] = useState(false);
    const [isAtLeastSix, setIsAtLeastSix] = useState(false);

    const capitalCaseRegex = RegExp("[A-Z]+");
    const lowerCaseRegex = RegExp("[a-z]+");
    const numberRegex = RegExp("[0-9]+");

    useEffect(() => {
        setIsCapitalLetter(capitalCaseRegex.test(props.passwordToTest));
        setIsLowerLetter(lowerCaseRegex.test(props.passwordToTest));
        setIsNumberDigit(numberRegex.test(props.passwordToTest));
        setIsAtLeastSix(props.passwordToTest && props.passwordToTest.length >= 6);
    }, [props.passwordToTest]);

    props.isValidationPassed(isCapitalLetter && isLowerLetter && isNumberDigit && isAtLeastSix);
    return (
        <Row xs={12} md={3} className="no-gutters">
            <Col xs={6}>
                <InputConditionValidator
                    validatorText={translate('activationChoosePassword.condition.regularLetter')}
                    isValid={isLowerLetter} />
                <br />
                <InputConditionValidator
                    validatorText={translate('activationChoosePassword.condition.capitalLetter')}
                    isValid={isCapitalLetter} />
            </Col>
            <Col>
                <InputConditionValidator
                    validatorText={translate('activationChoosePassword.condition.minNumbers')}
                    isValid={isNumberDigit} />
                <br />
                <InputConditionValidator
                    validatorText={translate('activationChoosePassword.condition.minDigits')}
                    isValid={isAtLeastSix} />
            </Col>
        </Row>
    );
};

export default PasswordIndicatorBox;
