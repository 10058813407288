import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';
import { IMedicalAssistant, defaultValue } from 'app/shared/model/medical-assistant.model';
import { getEntitiesByQuery as getDoctorsByQuery } from '../healthcare-professional/healthcare-professional.reducer';
import { updateSelectedClinic } from 'app/pages/medical-assistant-dashboard/ma-dashboard.reducer';

export const ACTION_TYPES = {
  FETCH_MEDICALASSISTANT_LIST: 'medicalAssistant/FETCH_MEDICALASSISTANT_LIST',
  FETCH_MEDICALASSISTANT: 'medicalAssistant/FETCH_MEDICALASSISTANT',
  CREATE_MEDICALASSISTANT: 'medicalAssistant/CREATE_MEDICALASSISTANT',
  UPDATE_MEDICALASSISTANT: 'medicalAssistant/UPDATE_MEDICALASSISTANT',
  DELETE_MEDICALASSISTANT: 'medicalAssistant/DELETE_MEDICALASSISTANT',
  RESET: 'medicalAssistant/RESET'
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IMedicalAssistant>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false
};

export type MedicalAssistantState = Readonly<typeof initialState>;

// Reducer

export default (state: MedicalAssistantState = initialState, action): MedicalAssistantState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_MEDICALASSISTANT_LIST):
    case REQUEST(ACTION_TYPES.FETCH_MEDICALASSISTANT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_MEDICALASSISTANT):
    case REQUEST(ACTION_TYPES.UPDATE_MEDICALASSISTANT):
    case REQUEST(ACTION_TYPES.DELETE_MEDICALASSISTANT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_MEDICALASSISTANT_LIST):
    case FAILURE(ACTION_TYPES.FETCH_MEDICALASSISTANT):
    case FAILURE(ACTION_TYPES.CREATE_MEDICALASSISTANT):
    case FAILURE(ACTION_TYPES.UPDATE_MEDICALASSISTANT):
    case FAILURE(ACTION_TYPES.DELETE_MEDICALASSISTANT):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_MEDICALASSISTANT_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.FETCH_MEDICALASSISTANT):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_MEDICALASSISTANT):
    case SUCCESS(ACTION_TYPES.UPDATE_MEDICALASSISTANT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_MEDICALASSISTANT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/medical-assistants';

// Actions

export const getEntities: ICrudGetAllAction<IMedicalAssistant> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_MEDICALASSISTANT_LIST,
    payload: axios.get<IMedicalAssistant>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`)
  };
};

export const getEntity: ICrudGetAction<IMedicalAssistant> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_MEDICALASSISTANT,
    payload: axios.get<IMedicalAssistant>(requestUrl)
  };
};

export const getMedicalAssistantByAccountId: ICrudGetAction<IMedicalAssistant> = id => {
  return {
    type: ACTION_TYPES.FETCH_MEDICALASSISTANT,
    payload: axios.get<IMedicalAssistant>('api/medical-assistant/edit-personal-details', { params: { id } })
  };
};

export const getEntityByUser = () => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.FETCH_MEDICALASSISTANT,
    payload: axios.get<IMedicalAssistant>('api/medical-assistant')
  });

  const medicalAssistant: IMedicalAssistant = result.value.data;
  if (medicalAssistant && medicalAssistant.clinics && medicalAssistant.clinics.length > 0) {
    const query = `clinicId.equals=${medicalAssistant.clinics[0].id}`;
    await dispatch(getDoctorsByQuery(query));
    await dispatch(updateSelectedClinic(medicalAssistant.clinics[0]));
  }
};

export const createEntity: ICrudPutAction<IMedicalAssistant> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_MEDICALASSISTANT,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<IMedicalAssistant> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_MEDICALASSISTANT,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<IMedicalAssistant> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_MEDICALASSISTANT,
    payload: axios.delete(requestUrl)
  });
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
