import './home.scss';

import React from 'react';
import { HomeHeaderRow } from './HomeHeaderRow';

export const Home = () => {
  return <HomeHeaderRow />;
};

export default Home;
