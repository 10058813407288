import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, ICrudGetAction, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './scheduled-task-execution.reducer';
import { IScheduledTaskExecution } from 'app/shared/model/scheduled-task-execution.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IScheduledTaskExecutionDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const ScheduledTaskExecutionDetail = (props: IScheduledTaskExecutionDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const { scheduledTaskExecutionEntity } = props;
  return (
    <Row>
      <Col md="8">
        <h2>
          <Translate contentKey="velariaApp.scheduledTaskExecution.detail.title">ScheduledTaskExecution</Translate> [
          <b>{scheduledTaskExecutionEntity.id}</b>]
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="startTime">
              <Translate contentKey="velariaApp.scheduledTaskExecution.startTime">Start Time</Translate>
            </span>
          </dt>
          <dd>
            {scheduledTaskExecutionEntity.startTime ? (
              <TextFormat value={scheduledTaskExecutionEntity.startTime} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="endTime">
              <Translate contentKey="velariaApp.scheduledTaskExecution.endTime">End Time</Translate>
            </span>
          </dt>
          <dd>
            {scheduledTaskExecutionEntity.endTime ? (
              <TextFormat value={scheduledTaskExecutionEntity.endTime} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="status">
              <Translate contentKey="velariaApp.scheduledTaskExecution.status">Status</Translate>
            </span>
          </dt>
          <dd>{scheduledTaskExecutionEntity.status}</dd>
          <dt>
            <span id="messages">
              <Translate contentKey="velariaApp.scheduledTaskExecution.messages">Messages</Translate>
            </span>
          </dt>
          <dd>{scheduledTaskExecutionEntity.messages}</dd>
          <dt>
            <span id="input">
              <Translate contentKey="velariaApp.scheduledTaskExecution.input">Input</Translate>
            </span>
          </dt>
          <dd>{scheduledTaskExecutionEntity.input}</dd>
          <dt>
            <span id="output">
              <Translate contentKey="velariaApp.scheduledTaskExecution.output">Output</Translate>
            </span>
          </dt>
          <dd>{scheduledTaskExecutionEntity.output}</dd>
          <dt>
            <span id="error">
              <Translate contentKey="velariaApp.scheduledTaskExecution.error">Error</Translate>
            </span>
          </dt>
          <dd>{scheduledTaskExecutionEntity.error}</dd>
          <dt>
            <Translate contentKey="velariaApp.scheduledTaskExecution.scheduledTask">Scheduled Task</Translate>
          </dt>
          <dd>{scheduledTaskExecutionEntity.scheduledTask ? scheduledTaskExecutionEntity.scheduledTask.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/scheduled-task-execution" replace color="info">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/scheduled-task-execution/${scheduledTaskExecutionEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ scheduledTaskExecution }: IRootState) => ({
  scheduledTaskExecutionEntity: scheduledTaskExecution.entity,
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ScheduledTaskExecutionDetail);
