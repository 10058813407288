import axios from 'axios';
import { translate } from 'react-jhipster';

import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';
import { login } from '../../../shared/reducers/authentication';
import { IUser } from '../../../shared/model/user.model';
import { AUTHORITIES } from 'app/config/constants';

export const ACTION_TYPES = {
  ACTIVATION_CHOOSE_PASSWORD: 'account-choose-password/ACTIVATION_CHOOSE_PASSWORD',
  RESET_PASSWORD_FINISH: 'account-choose-password/RESET_PASSWORD_FINISH',
  VERIFY_CREATE_NEW_PASSWORD: 'account-choose-password/VERIFY_CREATE_NEW_PASSWORD',
  VERIFY_RESET_PASSWORD: 'account-choose-password/VERIFY_RESET_PASSWORD',
  GET_USER_STATUS: 'account-choose-password/GET_USER_STATUS',
  ACTIVATION_CHOOSE_PASSWORD_DONE: 'account-choose-password/ACTIVATION_CHOOSE_PASSWORD_DONE',
  RESET_PASSWORD_FINISH_DONE: 'account-choose-password/RESET_PASSWORD_FINISH_DONE',
  RESET: 'account-choose-password/RESET'
};

const initialState = {
  activateNewPasswordAPI: {
    loading: false,
    errorMessage: null,
    updateSuccess: false,
    updateFailure: false
  },
  verifyNewPasswordAPI: {
    loading: false,
    errorMessage: null,
    updateSuccess: false,
    updateFailure: false,
    payload: ''
  }
};

export type ActivationChoosePasswordState = Readonly<typeof initialState>;

// Reducer
export default (state: ActivationChoosePasswordState = initialState, action): ActivationChoosePasswordState => {
  switch (action.type) {
    case ACTION_TYPES.GET_USER_STATUS:
      return {
        ...state,
        verifyNewPasswordAPI: {
          ...state.verifyNewPasswordAPI,
          payload: action.payload
        }
      };
    case REQUEST(ACTION_TYPES.ACTIVATION_CHOOSE_PASSWORD):
    case REQUEST(ACTION_TYPES.RESET_PASSWORD_FINISH):
      return {
        ...state,
        activateNewPasswordAPI: {
          ...state.activateNewPasswordAPI,
          errorMessage: null,
          updateSuccess: false
        }
      };
    case REQUEST(ACTION_TYPES.VERIFY_CREATE_NEW_PASSWORD):
    case REQUEST(ACTION_TYPES.VERIFY_RESET_PASSWORD):
      return {
        ...state,
        verifyNewPasswordAPI: {
          ...state.verifyNewPasswordAPI,
          errorMessage: null,
          updateSuccess: false,
          loading: true
        }
      };
    case FAILURE(ACTION_TYPES.ACTIVATION_CHOOSE_PASSWORD):
    case FAILURE(ACTION_TYPES.RESET_PASSWORD_FINISH):
      return {
        ...state,
        activateNewPasswordAPI: {
          ...state.activateNewPasswordAPI,
          loading: false,
          updateSuccess: false,
          updateFailure: true
        }
      };
    case FAILURE(ACTION_TYPES.VERIFY_CREATE_NEW_PASSWORD):
    case FAILURE(ACTION_TYPES.VERIFY_RESET_PASSWORD):
      return {
        ...state,
        verifyNewPasswordAPI: {
          ...state.verifyNewPasswordAPI,
          loading: false,
          updateSuccess: false,
          updateFailure: true
        }
      };
    case ACTION_TYPES.ACTIVATION_CHOOSE_PASSWORD_DONE:
    case ACTION_TYPES.RESET_PASSWORD_FINISH_DONE:
    case SUCCESS(ACTION_TYPES.ACTIVATION_CHOOSE_PASSWORD):
    case SUCCESS(ACTION_TYPES.RESET_PASSWORD_FINISH):
      return {
        ...state,
        activateNewPasswordAPI: {
          ...state.activateNewPasswordAPI,
          loading: false,
          updateSuccess: true,
          updateFailure: false
        }
      };
    case SUCCESS(ACTION_TYPES.VERIFY_CREATE_NEW_PASSWORD):
    case SUCCESS(ACTION_TYPES.VERIFY_RESET_PASSWORD):
      return {
        ...state,
        verifyNewPasswordAPI: {
          ...state.verifyNewPasswordAPI,
          loading: false,
          updateSuccess: true,
          updateFailure: false
        }
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/account';
// Actions

export const activateNewPassword = (newPassword: string, key: string) => async dispatch => {
  const response = await axios.post(`${apiUrl}/activation-choose-password`, { newPassword, key });
  const user: IUser = response.data;

  if (!user.authorities.some(authority => authority['name'] === AUTHORITIES.PATIENT)) {
    await dispatch(login(user.login, newPassword, false, 'VELARIA'));
  }

  dispatch({
    type: ACTION_TYPES.ACTIVATION_CHOOSE_PASSWORD_DONE,
    payload: response,
    meta: {
      successMessage: translate('activationChoosePassword.messages.success')
    }
  });
};

export const getUserStatus = (response: string) => ({
  type: ACTION_TYPES.GET_USER_STATUS,
  payload: response
});

export const verifyCreateNewPassword = (key: string) => async dispatch => {
  await dispatch({
    type: ACTION_TYPES.VERIFY_CREATE_NEW_PASSWORD,
    payload: axios.get(`${apiUrl}/verify-create-new-password`, { params: { key } }).then(response => dispatch(getUserStatus(response.data)))
  });
};

export const verifyResetPassword = (key: string) => async dispatch => {
  await dispatch({
    type: ACTION_TYPES.VERIFY_RESET_PASSWORD,
    payload: axios.get(`${apiUrl}/verify-reset-password`, { params: { key } }).then(response => dispatch(getUserStatus(response.data)))
  });
};

export const handlePasswordResetFinish = (newPassword, key) => async dispatch => {
  const response = await axios.post(`${apiUrl}/reset-password/finish`, { newPassword, key });
  const user: IUser = response.data;

  if (!user.authorities.some(authority => authority['name'] === AUTHORITIES.PATIENT)) {
    await dispatch(login(user.login, newPassword, false,'VELARIA'));
  }

  dispatch({
    type: ACTION_TYPES.RESET_PASSWORD_FINISH_DONE,
    payload: response,
    meta: {
      successMessage: translate('reset.finish.messages.success')
    }
  });
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
