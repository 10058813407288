import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './patient.reducer';
import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IPatientDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string; }> { }

export const PatientDetail = (props: IPatientDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const { patientEntity } = props;
  return (
    <Row>
      <Col>
        <Row className="justify-content-center">
          <Col md={4} sm={8}>
            <h2>
              <Translate contentKey="velariaApp.patient.detail.title">Patient</Translate> [<b>{patientEntity.id}</b>]
        </h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md={4} sm={8} className="info-box">
            <dl className="jh-entity-details">
              <dt>
                <span id="userId">
                  <Translate contentKey="velariaApp.patient.userId">User Id</Translate>
                </span>
              </dt>
              <dd>{patientEntity.userId}</dd>
              <dt>
                <span id="salutation">
                  <Translate contentKey="velariaApp.patient.salutation">Salutation</Translate>
                </span>
              </dt>
              <dd>{patientEntity.salutation}</dd>
              <dt>
                <span id="title">
                  <Translate contentKey="velariaApp.patient.title">Title</Translate>
                </span>
              </dt>
              <dd>{patientEntity.title}</dd>
              <dt>
                <span id="firstName">
                  <Translate contentKey="velariaApp.patient.firstName">First Name</Translate>
                </span>
              </dt>
              <dd>{patientEntity.firstName}</dd>
              <dt>
                <span id="lastName">
                  <Translate contentKey="velariaApp.patient.lastName">Last Name</Translate>
                </span>
              </dt>
              <dd>{patientEntity.lastName}</dd>
              <dt>
                <span id="gender">
                  <Translate contentKey="velariaApp.patient.gender">Gender</Translate>
                </span>
              </dt>
              <dd>{patientEntity.gender}</dd>
              <dt>
                <span id="langKey">
                  <Translate contentKey="velariaApp.patient.langKey">Lang Key</Translate>
                </span>
              </dt>
              <dd>{patientEntity.langKey}</dd>
              <dt>
                <span id="email">
                  <Translate contentKey="velariaApp.patient.email">Email</Translate>
                </span>
              </dt>
              <dd>{patientEntity.email}</dd>
              <dt>
                <span id="mobileNumber">
                  <Translate contentKey="velariaApp.patient.mobileNumber">Mobile Number</Translate>
                </span>
              </dt>
              <dd>{patientEntity.mobileNumber}</dd>
              <dt>
                <span id="birthdate">
                  <Translate contentKey="velariaApp.patient.birthdate">Birthdate</Translate>
                </span>
              </dt>
              <dd>
                <TextFormat value={patientEntity.birthdate} type="date" format={APP_LOCAL_DATE_FORMAT} />
              </dd>
              <dt>
                <span id="invitationFrom">
                  <Translate contentKey="velariaApp.patient.invitationFrom">Invitation From</Translate>
                </span>
              </dt>
              <dd>{patientEntity.invitationFrom}</dd>
              <dt>
                <span id="mobileAppInstalled">
                  <Translate contentKey="velariaApp.patient.mobileAppInstalled">Mobile App Installed</Translate>
                </span>
              </dt>
              <dd>{patientEntity.mobileAppInstalled ? 'Active' : 'Not Installed'}</dd>
              <dt>
                <Translate contentKey="velariaApp.patient.icdCode">Icd Code</Translate>
              </dt>
              <dd>{patientEntity.icdCode ? patientEntity.icdCode.code : ''}</dd>
              <dt>
                <Translate contentKey="velariaApp.patient.clinic">Clinic</Translate>
              </dt>
              <dd>
                {patientEntity.clinics
                  ? patientEntity.clinics.map((val, i) => (
                    <span key={val.id}>
                      <a>{val.name}</a>
                      {i === patientEntity.clinics.length - 1 ? '' : ', '}
                    </span>
                  ))
                  : null}
              </dd>
            </dl>
            <Button tag={Link} to="/patient" replace className="rounded" color="success" >
              <FontAwesomeIcon icon="arrow-left" />{' '}
              <span className="d-none d-md-inline">
                <Translate contentKey="entity.action.back">Back</Translate>
              </span>
            </Button>
        &nbsp;
        <Button tag={Link} to={`/patient/${patientEntity.id}/edit`} replace className="rounded" color="danger">
              <FontAwesomeIcon icon="pencil-alt" />{' '}
              <span className="d-none d-md-inline">
                <Translate contentKey="entity.action.edit">Edit</Translate>
              </span>
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ patient }: IRootState) => ({
  patientEntity: patient.entity
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PatientDetail);
