import { Moment } from 'moment';

export interface IConversationSummary {
  id?: number;
  entityId?: number;
  conversationTypeId?: number;
  conversationType?: string;
  appointmentId?: number;
  startTime?: Moment;
  endTime?: Moment;
  currentMessageId?: number;
  requiredSince?: Moment;
  status?: string;
  svgIcon?: string;
}

export const defaultValue: Readonly<IConversationSummary> = {};
