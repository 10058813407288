import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Table, Spinner, Col, Row, Button } from 'reactstrap';
import { Translate } from 'react-jhipster';

import { IRootState } from 'app/shared/reducers';
import { getEntitiesByQuery } from 'app/entities/self-registration/self-registration.reducer';
import { updateSelectedPatient } from '../ma-dashboard.reducer';
import { URL_PREFIX, URL_SUFFIX } from '../ma-dashboard-layout/pages-list';
import '../ma-pages.scss';
import { Link } from 'react-router-dom';

export interface IMedicalAssistantSelfRegistrations extends DispatchProps, StateProps { }

const MedicalAssistantSelfRegistrations = (props: IMedicalAssistantSelfRegistrations) => {

    const navigateToCreatePatient = (patientToCreate) => {
        props.updateSelectedPatient(patientToCreate);
    };

    useEffect(() => {
        if (props.selectedClinic) {
            props.getEntitiesByQuery('clinicId.equals=' + props.selectedClinic.id);
        }
    }, [props.selectedClinic]);

    return (
        <Row>
            <Col>
                <div className="table-responsive">
                    {props.selfRegistrationList && props.selfRegistrationList.length > 0 ? (
                        <Table responsive>
                            <thead>
                                <tr style={{ textAlign: "center" }}>
                                    <th>
                                        {''}
                                    </th>
                                    <th>
                                        <Translate contentKey="velariaApp.selfRegistration.create">Create Patient</Translate>
                                    </th>
                                    <th>
                                        <Translate contentKey="velariaApp.selfRegistration.firstName">First Name</Translate>
                                    </th>
                                    <th>
                                        <Translate contentKey="velariaApp.selfRegistration.lastName">Last Name</Translate>
                                    </th>
                                    <th>
                                        <Translate contentKey="velariaApp.selfRegistration.langKey">Language</Translate>
                                    </th>
                                    <th>
                                        <Translate contentKey="velariaApp.selfRegistration.gender">Gender</Translate>
                                    </th>
                                    <th>
                                        <Translate contentKey="velariaApp.selfRegistration.birthdate">Birthday</Translate>
                                    </th>
                                    <th>
                                        <Translate contentKey="velariaApp.selfRegistration.email">Email</Translate>
                                    </th>
                                    <th>
                                        <Translate contentKey="velariaApp.selfRegistration.mobileNumber">Mobile Number</Translate>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {props.selfRegistrationList.map((selfRegistration, i) => {
                                    return (
                                        <tr key={`entity-${i}`} style={{ textAlign: "center" }}>
                                            <td>
                                                {`${i + 1}`}
                                            </td>
                                            <td style={{ cursor: "pointer" }}>
                                                <Link to={URL_PREFIX.MA_DASHBOARD + URL_SUFFIX.ADD_NEW_PATIENT_SR}>
                                                    <Button className="tableButton-2" onClick={() => navigateToCreatePatient(selfRegistration)}>
                                                        <Translate contentKey="velariaApp.selfRegistration.create">Create patient</Translate>
                                                    </Button>
                                                </Link>
                                            </td>
                                            <td>
                                                {`${selfRegistration.firstName}`}

                                            </td>
                                            <td>
                                                {`${selfRegistration.lastName}`}
                                            </td>
                                            <td>
                                                {`${selfRegistration.langKey}`}
                                            </td>
                                            <td>
                                                {`${selfRegistration.gender}`}
                                            </td>
                                            <td>
                                                {new Date(selfRegistration.birthdate).toLocaleDateString(props.currentLocale, { timeZone: 'Europe/Berlin' })}
                                            </td>
                                            <td>
                                                {`${selfRegistration.email}`}
                                            </td>
                                            <td>
                                                {`${selfRegistration.mobileNumber}`}
                                            </td>
                                        </tr>)
                                })}
                            </tbody>
                        </Table>
                    ) : (<Col><div><Translate contentKey="velariaApp.selfRegistration.home.notFound">No Self Registrations found</Translate></div></Col>)}
                </div>
            </Col >
        </Row>
    );
};

const mapStateToProps = (storeState: IRootState) => ({
    selfRegistrationList: storeState.selfRegistration.entities,
    selectedClinic: storeState.maDashboardReducer.selectedClinic,
    currentLocale: storeState.locale.currentLocale,
});

const mapDispatchToProps = {
    getEntitiesByQuery,
    updateSelectedPatient
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(MedicalAssistantSelfRegistrations);
