import { getSelfServiceClinics } from 'app/entities/clinic/clinic.reducer';
import { LanguageCode } from 'app/shared/languages/language-options';
import { IRootState } from 'app/shared/reducers';
import { mapIdList } from 'app/shared/util/entity-utils';
import { AvField, AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import React, { useEffect, useState } from 'react';
import { getUrlParameter, Translate, translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import Select from 'react-select';
import { Button, Col, Label, Row, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { createSelfRegistratinoWithoutGet, reset } from '../../entities/self-registration/self-registration.reducer';
import {
  isSafari
} from "react-device-detect";


export interface IAccountSelfRegistrationUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string; }> { }

export const accountSelfRegistrationUpdate = (props: IAccountSelfRegistrationUpdateProps) => {

  const { accountselfRegistrationEntity, clinics, loading, updating } = props;
  const [clinic, setClinic] = useState(undefined);
  const [key, setKey] = useState('');
  const [modal, setModal] = useState(false);
  const [disableClinics, setDisableClinics] = useState(false);

  const toggle = () => setModal(!modal);

  const onChange = (event) => {
    setClinic(event.value);
    setKey(event.value.urlUuid);
  };

  const assignClinicToItsField = (uuid) => {
    const selectedClinic = clinics.find(entity => entity.urlUuid === `${uuid}`);
    selectedClinic !== undefined ? setClinic(selectedClinic) : clinics && clinics.length > 0 ? setClinic(clinics[0]) : null;
  };

  const activateUUIDToForm = () => {
    const keyFromUrl = getUrlParameter('key', props.location.search);
    setKey(keyFromUrl);
    keyFromUrl !== '' ? (assignClinicToItsField(keyFromUrl), setDisableClinics(true)) : setDisableClinics(false);
  };

  useEffect(() => {
    activateUUIDToForm();

  }, [clinics]);

  useEffect(() => {
    props.reset();
    props.getSelfServiceClinics();
  }, []);

  useEffect(() => {
    if (props.updateSuccess) {
      props.reset();
    }
  }, [props.updateSuccess]);

  const saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const entity = {
        ...accountselfRegistrationEntity,
        ...values,
        clinics: mapIdList([`${clinic.id}`]),
      };
      props.createSelfRegistratinoWithoutGet(entity);
    }
  };

  
  return (
    <div>
      <Row className="justify-content-center">
        <Col md="6" sm={8} className="info-box-selfRegistration">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <AvForm model={{}} onSubmit={saveEntity}>
              <Label className="info-box-selfRegistration-description">
                <Translate contentKey={"velariaApp.selfRegistration.description"}>
                </Translate>
              </Label>
              <div>
                <Col md={8}>
                  <div style={{ margin: "1rem 0rem 1rem 1rem" }}>
                    <Label for="self-registration-clinic">
                      <Translate contentKey="velariaApp.selfRegistration.Empfänger">Clinic</Translate>
                    </Label>
                    <Select
                      options={clinics.map(entity => ({
                        label: entity.name,
                        value: entity
                      })
                      )}
                      onChange={(event) => onChange(event)}
                      value={key !== '' && clinic !== undefined ? ({ label: clinic.name, value: clinic }) : clinics && clinics.length > 0 ? ({ label: clinics[0].name, value: clinics[0] }) : null}
                      placeholder="Please choose your clinic..."
                      isDisabled={disableClinics}
                    />
                    <div style={{ margin: "2%" }}>
                      {clinic !== undefined ?
                        (clinic.street !== undefined ? clinic.street : '') + ' ' +
                        (clinic.houseNumber !== undefined ? clinic.houseNumber : '') + ', ' +
                        (clinic.zipCode !== undefined ? clinic.zipCode : '') + ' ' +
                        (clinic.city !== undefined ? clinic.city : '') : ''}</div>

                  </div>
                </Col>
              </div>

              <Row md={8} style={{ margin: "1rem" }}>
                <Col>
                  <AvGroup>
                    <Label id="firstNameLabel" for="self-registration-firstName">
                      <Translate contentKey="velariaApp.selfRegistration.firstName">First Name</Translate>
                    </Label>
                    <AvField
                      id="self-registration-firstName"
                      type="text"
                      name="firstName"
                      validate={{
                        required: { value: true, errorMessage: translate('entity.validation.required') },
                        maxLength: { value: 50, errorMessage: translate('entity.validation.maxlength', { max: 50 }) },
                      }}
                    />
                  </AvGroup>
                </Col>
                <Col>
                  <AvGroup >
                    <Label id="lastNameLabel" for="self-registration-lastName">
                      <Translate contentKey="velariaApp.selfRegistration.lastName">Last Name</Translate>
                    </Label>
                    <AvField
                      id="self-registration-lastName"
                      type="text"
                      name="lastName"
                      validate={{
                        required: { value: true, errorMessage: translate('entity.validation.required') },
                        maxLength: { value: 50, errorMessage: translate('entity.validation.maxlength', { max: 50 }) },
                      }}
                    />
                  </AvGroup>
                </Col>
                <Col>
                  <AvGroup>
                    <Label id="langKeyLabel" for="self-registration-langKey">
                      <Translate contentKey="velariaApp.selfRegistration.langKey">Lang Key</Translate>
                    </Label>
                    <AvInput
                      id="self-registration-langKey"
                      type="select"
                      name="langKey"
                      value={LanguageCode.GERMAN}
                      validate={{
                        required: { value: true, errorMessage: translate('entity.validation.required') },
                        minLength: { value: 2, errorMessage: translate('entity.validation.minlength', { min: 2 }) },
                        maxLength: { value: 10, errorMessage: translate('entity.validation.maxlength', { max: 10 }) },
                      }}
                    >
                      <option value={LanguageCode.GERMAN}>{translate('global.form.languageKey.german')}</option>
                      <option value={LanguageCode.ENGLISH}>{translate('global.form.languageKey.english')}</option>
                      <option value={LanguageCode.TURKISH}>{translate('global.form.languageKey.turkish')}</option>
                    </AvInput>
                  </AvGroup>
                </Col>
              </Row>

              <Row style={{ margin: "1rem" }}>
                <Col>
                  <AvGroup>
                    <Label id="genderLabel" for="self-registration-gender">
                      <Translate contentKey="velariaApp.selfRegistration.gender">Gender</Translate>
                    </Label>
                    <AvInput
                      id="self-registration-gender"
                      type="select"
                      className="form-control"
                      name="gender"
                      value={translate('velariaApp.gender.male')}
                    > 
                      <option value={translate('velariaApp.gender.male')}>{translate('velariaApp.gender.male')}</option>
                      <option value={translate('velariaApp.gender.female')}>{translate('velariaApp.gender.female')}</option>
                      <option value={translate('velariaApp.gender.other')}>{translate('velariaApp.gender.other')}</option>
                    </AvInput>
                  </AvGroup>
                </Col>
                <Col>
                  <AvGroup>
                    <Label id="birthdateLabel" for="self-registration-birthdate">
                      {!isSafari ?
                        <Translate contentKey="velariaApp.selfRegistration.birthdate">Birthdate</Translate> :
                        <Translate contentKey="velariaApp.selfRegistration.birthdateWithFormat">Birthdate (dd/mm/yyyy / yyyy-mm-dd)</Translate>
                      }
                    </Label>
                    <AvField
                      id="self-registration-birthdate"
                      type="date"
                      className="form-control"
                      name="birthdate"
                      validate={{
                        required: { value: true, errorMessage: translate('entity.validation.required') },
                        dateRange: {
                          start: { value: -130, units: 'years', },
                          end: { value: -18, units: 'years', },
                          errorMessage: translate('global.messages.validate.birthdate.invalidAge')
                        }
                      }}
                    />
                  </AvGroup>
                </Col>
                <Col>
                  <Row>

                  </Row>
                </Col>
              </Row>
              <Row style={{ margin: "1rem" }}>
                <Col>
                  <AvGroup>
                    <Label id="emailLabel" for="self-registration-email">
                      <Translate contentKey="velariaApp.selfRegistration.email">Email</Translate>
                    </Label>
                    <AvField
                      id="self-registration-email"
                      type="email"
                      name="email"
                      validate={{
                        required: { value: true, errorMessage: translate('entity.validation.required') },
                        pattern: {
                          value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                          errorMessage: translate('global.messages.validate.email.invalid')
                        }

                      }}
                    />
                  </AvGroup>
                </Col>
                <Col md={7}>
                  <AvGroup>
                    <Label id="mobileNumberLabel" for="self-registration-mobileNumber">
                      <Translate contentKey="velariaApp.selfRegistration.mobileNumber">Mobile Number</Translate>
                    </Label>
                    <AvField
                      id="self-registration-mobileNumber"
                      type="text"
                      name="mobileNumber"
                      placeholder={translate('global.messages.validate.mobileNumber.placeHolder')}
                      validate={{
                        required: { value: true, errorMessage: translate('entity.validation.required') },
                        pattern: {
                          value: '^([0-9]{2,})$',
                          errorMessage: translate('global.messages.validate.mobileNumber.invalidPattern')
                        },
                        maxLength: { value: 20, errorMessage: translate('global.messages.validate.mobileNumber.maxlength', { max: 20 }) }
                      }}
                    />
                  </AvGroup>
                </Col>
              </Row>


              <AvGroup check>
                <Label>
                  <Translate contentKey="velariaApp.selfRegistration.DatenschutzEinwilligung"></Translate>
                </Label>
                <AvGroup style={{ margin: "1rem 0rem 1rem 2rem" }}>
                  <Label className="form-check-label">
                    <AvInput
                      type="checkbox"
                      name="personalData"
                      required
                    />
                    <Translate contentKey="activationTermsConditions.selfRegistration.consentStart"></Translate>
                    <a onClick={toggle}>
                      <Translate contentKey="activationTermsConditions.selfRegistration.consentLink"></Translate>
                    </a>
                    <Translate contentKey="activationTermsConditions.selfRegistration.consentEnd"></Translate>
                  </Label>
                </AvGroup>
                <Modal isOpen={modal} toggle={toggle}>
                  <ModalHeader toggle={toggle}>
                    <Translate contentKey="velariaApp.selfRegistration.consent.title"></Translate>
                  </ModalHeader>
                  <ModalBody>
                    <Translate contentKey="velariaApp.selfRegistration.consent.body"></Translate>
                  </ModalBody>
                  <ModalFooter>
                    <Button color="success" onClick={toggle}>Close</Button>{' '}
                  </ModalFooter>
                </Modal>

                <AvGroup style={{ margin: "0rem 0rem 1rem 2rem" }}>
                  <Label className="form-check-label">
                    <AvInput
                      type="checkbox"
                      name="privacyPolicyData"
                      required
                    />
                    <Translate contentKey="activationTermsConditions.agreeTo"></Translate>
                    <a onClick={() => window.open("/account/privacyPolicyDataPage?language=" + props.currentLanguage)}>
                      <Translate contentKey="activationTermsConditions.privacyPolicyData"></Translate>
                    </a>
                  </Label>
                </AvGroup>

                <AvGroup style={{ margin: "0rem 0rem 1rem 2rem" }}>
                  <Label className="form-check-label">
                    <AvInput
                      type="checkbox"
                      name="agreeToTermsAndConditions"
                      required
                    />
                    <Translate contentKey="activationTermsConditions.agreeTo"></Translate>
                    <a onClick={() => window.open("/account/termsAndConditionsDataPage?language=" + props.currentLanguage)}>
                      <Translate contentKey="activationTermsConditions.agreeToTermsAndConditions"></Translate>
                    </a>
                  </Label>
                </AvGroup>
              </AvGroup>

               &nbsp;
              <Button
                style={{
                  margin: "0rem 0rem 2rem 2rem",
                  borderRadius: "20px",
                  backgroundPosition: "left-top",
                  backgroundRepeat: "repeat",
                  padding: "8px",
                  width: "20%"
                }} color="success" id="save-entity" type="submit" disabled={updating}>
                &nbsp;
                <span style={{ fontSize: "14px" }}><Translate contentKey="velariaApp.selfRegistration.send">Send</Translate></span>
              </Button>
              <Col>
                <Row>

                </Row>
                <Row>

                </Row>
              </Col>
            </AvForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  clinics: storeState.clinic.entities,
  accountselfRegistrationEntity: storeState.selfRegistration.entity,
  loading: storeState.selfRegistration.loading,
  updating: storeState.selfRegistration.updating,
  updateSuccess: storeState.selfRegistration.updateSuccess,
  currentLanguage: storeState.locale.currentLocale ? storeState.locale.currentLocale : 'de'
});

const mapDispatchToProps = {
  getSelfServiceClinics,
  createSelfRegistratinoWithoutGet,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(accountSelfRegistrationUpdate);
