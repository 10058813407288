import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, ICrudGetAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './conversation-catalog-en.reducer';
import { IConversationCatalogEn } from 'app/shared/model/conversation-catalog-en.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IConversationCatalogEnDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const ConversationCatalogEnDetail = (props: IConversationCatalogEnDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const { conversationCatalogEnEntity } = props;
  return (
    <Row>
      <Col md="8">
        <h2>
          <Translate contentKey="velariaApp.conversationCatalogEn.detail.title">ConversationCatalogEn</Translate> [
          <b>{conversationCatalogEnEntity.id}</b>]
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="conversationTypeId">
              <Translate contentKey="velariaApp.conversationCatalogEn.conversationTypeId">Conversation Type Id</Translate>
            </span>
          </dt>
          <dd>{conversationCatalogEnEntity.conversationTypeId}</dd>
          <dt>
            <span id="conversationType">
              <Translate contentKey="velariaApp.conversationCatalogEn.conversationType">Conversation Type</Translate>
            </span>
          </dt>
          <dd>{conversationCatalogEnEntity.conversationType}</dd>
          <dt>
            <span id="conversationCategory">
              <Translate contentKey="velariaApp.conversationCatalogEn.conversationCategory">Conversation Category</Translate>
            </span>
          </dt>
          <dd>{conversationCatalogEnEntity.conversationCategory}</dd>
          <dt>
            <span id="messageType">
              <Translate contentKey="velariaApp.conversationCatalogEn.messageType">Message Type</Translate>
            </span>
          </dt>
          <dd>{conversationCatalogEnEntity.messageType}</dd>
          <dt>
            <span id="messageId">
              <Translate contentKey="velariaApp.conversationCatalogEn.messageId">Message Id</Translate>
            </span>
          </dt>
          <dd>{conversationCatalogEnEntity.messageId}</dd>
          <dt>
            <span id="messageText">
              <Translate contentKey="velariaApp.conversationCatalogEn.messageText">Message Text</Translate>
            </span>
          </dt>
          <dd>{conversationCatalogEnEntity.messageText}</dd>
          <dt>
            <span id="replyType">
              <Translate contentKey="velariaApp.conversationCatalogEn.replyType">Reply Type</Translate>
            </span>
          </dt>
          <dd>{conversationCatalogEnEntity.replyType}</dd>
          <dt>
            <span id="replyId">
              <Translate contentKey="velariaApp.conversationCatalogEn.replyId">Reply Id</Translate>
            </span>
          </dt>
          <dd>{conversationCatalogEnEntity.replyId}</dd>
          <dt>
            <span id="replyText">
              <Translate contentKey="velariaApp.conversationCatalogEn.replyText">Reply Text</Translate>
            </span>
          </dt>
          <dd>{conversationCatalogEnEntity.replyText}</dd>
          <dt>
            <span id="parentMessageId">
              <Translate contentKey="velariaApp.conversationCatalogEn.parentMessageId">Parent Message Id</Translate>
            </span>
          </dt>
          <dd>{conversationCatalogEnEntity.parentMessageId}</dd>
          <dt>
            <span id="nextMessageId">
              <Translate contentKey="velariaApp.conversationCatalogEn.nextMessageId">Next Message Id</Translate>
            </span>
          </dt>
          <dd>{conversationCatalogEnEntity.nextMessageId}</dd>
        </dl>
        <Button tag={Link} to="/conversation-catalog-en" replace color="info">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/conversation-catalog-en/${conversationCatalogEnEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ conversationCatalogEn }: IRootState) => ({
  conversationCatalogEnEntity: conversationCatalogEn.entity,
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ConversationCatalogEnDetail);
