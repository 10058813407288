import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label } from 'reactstrap';
import { AvForm, AvGroup, AvInput, AvField, AvFeedback } from 'availity-reactstrap-validation';
import { Translate, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { getEntities as getIcdCodes } from 'app/entities/icd-code/icd-code.reducer';
import { getAllEntites as getClinics } from 'app/entities/clinic/clinic.reducer';
import { getEntity, updateEntity, createEntity, reset } from './patient.reducer';
import { IPatient } from 'app/shared/model/patient.model';
import { LanguageCode } from 'app/shared/languages/language-options';

export interface IPatientUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string; }> { }

export const PatientUpdate = (props: IPatientUpdateProps) => {
  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);

  const { patientEntity, icdCodes, clinics, loading, updating } = props;

  const handleClose = () => {
    props.history.push('/patient' + props.location.search);
  };

  useEffect(() => {
    if (isNew) {
      props.reset();
    } else {
      props.getEntity(props.match.params.id);
    }

    props.getIcdCodes();
    props.getClinics();
  }, []);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const saveEntity = (event, errors, values) => {

    if (errors.length === 0) {
      const entity: IPatient = {
        ...patientEntity,
        ...values

      };

      if (isNew) {
        props.createEntity(entity);
      } else {
        props.updateEntity(entity);
      }
    }
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md={4} sm={8}>
          <h2 id="velariaApp.patient.home.createOrEditLabel">
            <Translate contentKey={isNew
              ? "velariaApp.patient.home.createLabel"
              : "velariaApp.patient.home.editLabel"}
            >
              Create or edit a Patient
              </Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="4" sm={8} className="info-box">
          {loading ? (
            <p>Loading...</p>
          ) : (
              <AvForm model={isNew ? {} : patientEntity} onSubmit={saveEntity}>
                <AvGroup>
                  <Label id="salutationLabel" for="patient-salutation">
                    <Translate contentKey="velariaApp.patient.salutation">Salutation</Translate>
                  </Label>
                  <AvInput
                    id="patient-salutation"
                    type="select"
                    className="form-control"
                    name="salutation"
                    value={(!isNew && patientEntity.salutation) || translate('velariaApp.salutation.mr')}
                  >
                    <option value={translate('velariaApp.salutation.mr')}>{translate('velariaApp.salutation.mr')}</option>
                    <option value={translate('velariaApp.salutation.ms')}>{translate('velariaApp.salutation.ms')}</option>
                  </AvInput>
                </AvGroup>
                <AvGroup>
                  <Label id="titleLabel" for="patient-title">
                    <Translate contentKey="velariaApp.patient.title">Title</Translate>
                  </Label>
                  <AvField
                    id="patient-title"
                    type="text"
                    name="title"
                    validate={{
                      maxLength: { value: 50, errorMessage: translate('entity.validation.maxlength', { max: 50 }) }
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="firstNameLabel" for="patient-firstName">
                    <Translate contentKey="velariaApp.patient.firstName">First Name</Translate>
                  </Label>
                  <AvField
                    id="patient-firstName"
                    type="text"
                    name="firstName"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') },
                      maxLength: { value: 50, errorMessage: translate('entity.validation.maxlength', { max: 50 }) }
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="lastNameLabel" for="patient-lastName">
                    <Translate contentKey="velariaApp.patient.lastName">Last Name</Translate>
                  </Label>
                  <AvField
                    id="patient-lastName"
                    type="text"
                    name="lastName"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') },
                      maxLength: { value: 50, errorMessage: translate('entity.validation.maxlength', { max: 50 }) }
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="genderLabel" for="patient-gender">
                    <Translate contentKey="velariaApp.patient.gender">Gender</Translate>
                  </Label>
                  <AvInput
                    id="patient-gender"
                    type="select"
                    className="form-control"
                    name="gender"
                    value={(!isNew && patientEntity.gender) || translate('velariaApp.gender.male')}
                  >
                    <option value={translate('velariaApp.gender.male')}>{translate('velariaApp.gender.male')}</option>
                    <option value={translate('velariaApp.gender.female')}>{translate('velariaApp.gender.female')}</option>
                    <option value={translate('velariaApp.gender.other')}>{translate('velariaApp.gender.other')}</option>
                  </AvInput>
                </AvGroup>
                <AvGroup>
                  <Label id="langKeyLabel" for="patient-langKey">
                    <Translate contentKey="velariaApp.patient.langKey">Lang Key</Translate>
                  </Label>
                  <AvInput
                    id="patient-langKey"
                    type="select"
                    name="langKey"
                    value={(!isNew && patientEntity.langKey) || LanguageCode.GERMAN}
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') },
                      minLength: { value: 2, errorMessage: translate('entity.validation.minlength', { min: 2 }) },
                      maxLength: { value: 10, errorMessage: translate('entity.validation.maxlength', { max: 10 }) },
                    }}
                  >
                    <option value={LanguageCode.GERMAN}>{translate('global.form.languageKey.german')}</option>
                    <option value={LanguageCode.ENGLISH}>{translate('global.form.languageKey.english')}</option>
                    <option value={LanguageCode.TURKISH}>{translate('global.form.languageKey.turkish')}</option>
                  </AvInput>
                </AvGroup>
                <AvGroup>
                  <Label id="emailLabel" for="patient-email">
                    <Translate contentKey="velariaApp.patient.email">Email</Translate>
                  </Label>
                  <AvField
                    id="patient-email"
                    type="email"
                    name="email"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') }
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="mobileNumberLabel" for="patient-mobileNumber">
                    <Translate contentKey="velariaApp.patient.mobileNumber">Mobile Number</Translate>
                  </Label>

                  <AvField
                    id="patient-mobileNumber"
                    type="text"
                    placeholder="Contry Code and mobile number"
                    name="mobileNumber"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') },
                      pattern: {
                        value: '^([0-9]{2,})$',
                        errorMessage: translate('global.messages.validate.mobileNumber.invalidPattern')
                      },
                      maxLength: { value: 20, errorMessage: translate('global.messages.validate.mobileNumber.maxlength', { max: 20 }) }
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="birthdateLabel" for="patient-birthdate">
                    <Translate contentKey="velariaApp.patient.birthdate">Birthdate</Translate>
                  </Label>
                  <AvField
                    id="patient-birthdate"
                    type="date"
                    className="form-control"
                    name="birthdate"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') },
                      dateRange: {
                        start: { value: -130, units: 'years', },
                        end: { value: -18, units: 'years', },
                        errorMessage: translate('global.messages.validate.birthdate.invalidAge')
                      }
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="invitationFromLabel" for="patient-invitationFrom">
                    <Translate contentKey="velariaApp.patient.invitationFrom">Invitation From</Translate>
                  </Label>
                  <AvField
                    id="patient-invitationFrom"
                    type="text"
                    name="invitationFrom"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') },
                      maxLength: { value: 100, errorMessage: translate('entity.validation.maxlength', { max: 100 }) }
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="mobileAppInstalledLabel" for="patient-mobileAppInstalled">
                    <Translate contentKey="velariaApp.patient.mobileAppInstalled">Mobile App Installed</Translate>
                  </Label>
                  <AvInput
                    id="patient-mobileAppInstalled"
                    type="select"
                    className="form-control"
                    name="mobileAppInstalled"
                    value={!isNew && patientEntity.mobileAppInstalled}
                  >
                    <option value="true">{translate("maDashboard.general.active")}</option>
                    <option value="false">{translate("maDashboard.general.notInstalled")}</option>
                  </AvInput>
                </AvGroup>
                <AvGroup>
                  <Label for="patient-icdCode">
                    <Translate contentKey="velariaApp.patient.icdCode">Icd Code</Translate>
                  </Label>
                  <AvInput
                    id="patient-icdCode"
                    type="select"
                    className="form-control"
                    name="icdCode.id"
                    value={
                      !isNew &&
                      patientEntity &&
                      patientEntity.icdCode &&
                      patientEntity.icdCode.id
                    }>
                    {icdCodes
                      ? icdCodes.map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {`${otherEntity.code}  -   ${otherEntity.description}`}
                        </option>
                      ))
                      : null}
                  </AvInput>
                </AvGroup>
                <AvGroup>
                  <Label for="patient-clinic">
                    <Translate contentKey="velariaApp.patient.clinic">Clinic</Translate>
                  </Label>
                  <AvInput
                    id="patient-clinic"
                    type="select"
                    className="form-control"
                    name="clinics[0].id"
                    value={
                      !isNew && patientEntity.clinics && patientEntity.clinics.length > 0 && patientEntity.clinics[0]
                        ? patientEntity.clinics[0].id
                        : clinics && clinics[0]
                          ? clinics[0].id
                          : 0
                    }
                    required
                  >
                    {clinics
                      ? clinics.map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.name}
                        </option>
                      ))
                      : null}
                  </AvInput>
                </AvGroup>
                <Button tag={Link} id="cancel-save" to="/patient" replace className="rounded" color="success" >
                  <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
              &nbsp;
                <Button className="rounded" color="danger" id="save-entity" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </AvForm>
            )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  icdCodes: storeState.icdCode.entities,
  clinics: storeState.clinic.entities,
  patientEntity: storeState.patient.entity,
  loading: storeState.patient.loading,
  updating: storeState.patient.updating,
  updateSuccess: storeState.patient.updateSuccess
});

const mapDispatchToProps = {
  getIcdCodes,
  getClinics,
  getEntity,
  updateEntity,
  createEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PatientUpdate);
