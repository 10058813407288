import Axios from 'axios';
import { translate } from 'react-jhipster';

export const generatePDF = fileName => {
  const requestUrl = `/api/general/get-pdf/${fileName}`;
  Axios.get(`${requestUrl}`, {
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/pdf'
    }
  })
    .then(response => {
      if (response.data) {
        const file = new Blob([response.data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      } else {
        alert(translate('error.file.could.not.extract'));
      }
    })
    .catch(err => {
      alert(translate('error.file.could.not.extract'));
    });
};

export const generatePDFv2 = fileName => {
  const requestUrl = `/api/general/retrieveDocument/${fileName}`;
  Axios.get(`${requestUrl}`, {
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/pdf'
    }
  })
    .then(response => {
      if (response.data) {
        const file = new Blob([response.data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      } else {
        alert(translate('error.file.could.not.extract'));
      }
    })
    .catch(err => {
      alert(translate('error.file.could.not.extract'));
    });
};

export enum PDFFILES {
  HEALTH_LITERACY = 'health_literacy',
  STUDY_RESULTS = 'Study_Results',
  INSTALLATION_GUIDE_EN = 'installation_guide_en_velaria',
  INSTALLATION_GUIDE_DE = 'installation_guide_de_velaria',
  INSTALLATION_GUIDE_TR = 'installation_guide_tr_velaria',
  DATA_PROCESSING_EN = 'Data processing for technical developments - ENGLISH',
  DATA_PROCESSING_DE = 'Data processing for technical developments - GERMAN',
  DATA_PROCESSING_TR = 'Data processing for technical developments - TURKISH',
  PRIVACY_POLICY_EN = 'Privacy Policy - ENGLISH',
  PRIVACY_POLICY_DE = 'Privacy policy - GERMAN',
  PRIVACY_POLICY_TR = 'Privacy Policy - TURKISH',
  TNC_EN = 'T&C ENGLISH',
  TNC_DE = 'T&C GERMAN',
  TNC_TR = 'T&C TURKISH',
  USER_GUIDE_SELF_REG_EN = 'Velaria installation Guide_English Self-Registration Ver(1.0)',
  USER_GUIDE_SELF_REG_DE = 'Installation Guide Velaria Self-Registration Ver(1.0)'
}
