import { Moment } from 'moment';
import { IIcdCode } from 'app/shared/model/icd-code.model';
import { IClinic } from 'app/shared/model/clinic.model';

export interface IPatient {
  id?: number;
  userId?: number;
  salutation?: string;
  title?: string;
  firstName?: string;
  lastName?: string;
  gender?: string;
  email?: string;
  mobileNumber?: string;
  langKey?: string;
  birthdate?: string;
  invitationFrom?: string;
  mobileAppInstalled?: boolean;
  icdCode?: IIcdCode;
  clinics?: IClinic[];
}

export const defaultValue: Readonly<IPatient> = {
  mobileAppInstalled: false
};
