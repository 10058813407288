import { Moment } from 'moment';
import { IScheduledTask } from 'app/shared/model/scheduled-task.model';

export interface IScheduledTaskExecution {
  id?: number;
  startTime?: string;
  endTime?: string;
  status?: string;
  messages?: string;
  input?: string;
  output?: string;
  error?: string;
  scheduledTask?: IScheduledTask;
}

export const defaultValue: Readonly<IScheduledTaskExecution> = {};
