import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import HealthcareProfessional from './healthcare-professional';
import HealthcareProfessionalDetail from './healthcare-professional-detail';
import HealthcareProfessionalUpdate from './healthcare-professional-update';
import HealthcareProfessionalDeleteDialog from './healthcare-professional-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={HealthcareProfessionalUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={HealthcareProfessionalUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={HealthcareProfessionalDetail} />
      <ErrorBoundaryRoute path={match.url} component={HealthcareProfessional} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={HealthcareProfessionalDeleteDialog} />
  </>
);

export default Routes;
