import React, { useState } from 'react';
import { connect } from 'react-redux';
import Calendar from 'react-calendar';
import { IRootState } from 'app/shared/reducers';
import { updateSelectedDate } from 'app/pages/medical-assistant-dashboard/ma-dashboard.reducer';


export interface IMedicalAssistantCalendarProps extends StateProps, DispatchProps { }

const MedicalAssistantCalendar = (props: IMedicalAssistantCalendarProps) => {

    const updateNewSelectedDate = newDate => {
        props.updateSelectedDate(newDate);
    };

    return (
        <div className="info-box">
            <Calendar onChange={updateNewSelectedDate} value={props.selectedDate} />
        </div>
    );
};

const mapStateToProps = ({ maDashboardReducer }: IRootState) => {
    return {
        selectedDate: maDashboardReducer.selectedDate
    };
};

const mapDispatchToProps = { updateSelectedDate };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;


export default connect(mapStateToProps, mapDispatchToProps)(MedicalAssistantCalendar);
