import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, ICrudGetAction, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './conversation-event.reducer';
import { IConversationEvent } from 'app/shared/model/conversation-event.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IConversationEventDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string; }> { }

export const ConversationEventDetail = (props: IConversationEventDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const { conversationEventEntity } = props;
  return (
    <Row>
      <Col>
        <Row className="justify-content-center">
          <Col md={4} sm={8}>
            <h2>
              <Translate contentKey="velariaApp.conversationEvent.detail.title">ConversationEvent</Translate> [<b>{conversationEventEntity.id}</b>
          ]
        </h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md={4} sm={8} className="info-box">
            <dl className="jh-entity-details">
              <dt>
                <span id="conversationId">
                  <Translate contentKey="velariaApp.conversationEvent.conversationId">Conversation Id</Translate>
                </span>
              </dt>
              <dd>{conversationEventEntity.conversationId}</dd>
              <dt>
                <span id="messageId">
                  <Translate contentKey="velariaApp.conversationEvent.messageId">Message Id</Translate>
                </span>
              </dt>
              <dd>{conversationEventEntity.messageId}</dd>
              <dt>
                <span id="messageText">
                  <Translate contentKey="velariaApp.conversationEvent.messageText">Message Text</Translate>
                </span>
              </dt>
              <dd>{conversationEventEntity.messageText}</dd>
              <dt>
                <span id="replyId">
                  <Translate contentKey="velariaApp.conversationEvent.replyId">Reply Id</Translate>
                </span>
              </dt>
              <dd>{conversationEventEntity.replyId}</dd>
              <dt>
                <span id="replyText">
                  <Translate contentKey="velariaApp.conversationEvent.replyText">Reply Text</Translate>
                </span>
              </dt>
              <dd>{conversationEventEntity.replyText}</dd>
              <dt>
                <span id="messageTimestamp">
                  <Translate contentKey="velariaApp.conversationEvent.messageTimestamp">Message Timestamp</Translate>
                </span>
              </dt>
              <dd>
                <TextFormat value={conversationEventEntity.messageTimestamp} type="date" format={APP_DATE_FORMAT} />
              </dd>
              <dt>
                <span id="replyTimestamp">
                  <Translate contentKey="velariaApp.conversationEvent.replyTimestamp">Reply Timestamp</Translate>
                </span>
              </dt>
              <dd>
                <TextFormat value={conversationEventEntity.replyTimestamp} type="date" format={APP_DATE_FORMAT} />
              </dd>
              <dt>
                <span id="conversationCategory">
                  <Translate contentKey="velariaApp.conversationEvent.conversationCategory">Conversation Category</Translate>
                </span>
              </dt>
              <dd>{conversationEventEntity.conversationCategory}</dd>
            </dl>
            <Button tag={Link} to="/conversation-event" replace className="rounded" color="success" >
              <FontAwesomeIcon icon="arrow-left" />{' '}
              <span className="d-none d-md-inline">
                <Translate contentKey="entity.action.back">Back</Translate>
              </span>
            </Button>
        &nbsp;
        <Button tag={Link} to={`/conversation-event/${conversationEventEntity.id}/edit`} replace className="rounded" color="danger">
              <FontAwesomeIcon icon="pencil-alt" />{' '}
              <span className="d-none d-md-inline">
                <Translate contentKey="entity.action.edit">Edit</Translate>
              </span>
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ conversationEvent }: IRootState) => ({
  conversationEventEntity: conversationEvent.entity
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ConversationEventDetail);
