import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label } from 'reactstrap';
import { AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { Translate, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { getEntities as getClinics } from 'app/entities/clinic/clinic.reducer';
import { getEntity, updateEntity, createEntity, reset } from './medical-assistant.reducer';
import { LanguageCode } from 'app/shared/languages/language-options';

export interface IMedicalAssistantUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string; }> { }

export const MedicalAssistantUpdate = (props: IMedicalAssistantUpdateProps) => {
  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);

  const { medicalAssistantEntity, clinics, loading, updating } = props;

  const handleClose = () => {
    props.history.push('/medical-assistant' + props.location.search);
  };

  useEffect(() => {
    isNew ? props.reset() : props.getEntity(props.match.params.id);
    
    props.getClinics();
    
  }, []);


  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const saveEntity = (event, errors, values) => {

    if (errors.length === 0) {
      const entity = {
        ...medicalAssistantEntity,
        ...values
      };

      if (isNew) {
        props.createEntity(entity);
      } else {
        props.updateEntity(entity);
      }
    }
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md={4} sm={8}>
          <h2 id="velariaApp.medicalAssistant.home.createOrEditLabel">
            <Translate contentKey={isNew
              ? "velariaApp.medicalAssistant.home.createLabel"
              : "velariaApp.medicalAssistant.home.editLabel"}
            >
              Create or edit a MedicalAssistant
              </Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md={4} sm={8} className="info-box">
          {loading ? (
            <p>Loading...</p>
          ) : (
              <AvForm model={isNew ? {} : medicalAssistantEntity} onSubmit={saveEntity}>
                <AvGroup>
                  <Label id="salutationLabel" for="medical-assistant-salutation">
                    <Translate contentKey="velariaApp.medicalAssistant.salutation">Salutation</Translate>
                  </Label>
                  <AvInput
                    id="medical-assistant-salutation"
                    type="select"
                    className="form-control"
                    name="salutation"
                    value={(!isNew && medicalAssistantEntity.salutation) || translate('velariaApp.salutation.mr')}
                  >
                    <option value={translate('velariaApp.salutation.mr')}>{translate('velariaApp.salutation.mr')}</option>
                    <option value={translate('velariaApp.salutation.ms')}>{translate('velariaApp.salutation.ms')}</option>
                  </AvInput>
                </AvGroup>
                <AvGroup>
                  <Label id="titleLabel" for="medical-assistant-title">
                    <Translate contentKey="velariaApp.medicalAssistant.title">Title</Translate>
                  </Label>
                  <AvField
                    id="medical-assistant-title"
                    type="text"
                    name="title"
                    validate={{
                      maxLength: { value: 50, errorMessage: translate('entity.validation.maxlength', { max: 50 }) }
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="firstNameLabel" for="medical-assistant-firstName">
                    <Translate contentKey="velariaApp.medicalAssistant.firstName">First Name</Translate>
                  </Label>
                  <AvField
                    id="medical-assistant-firstName"
                    type="text"
                    name="firstName"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') },
                      maxLength: { value: 50, errorMessage: translate('entity.validation.maxlength', { max: 50 }) }
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="lastNameLabel" for="medical-assistant-lastName">
                    <Translate contentKey="velariaApp.medicalAssistant.lastName">Last Name</Translate>
                  </Label>
                  <AvField
                    id="medical-assistant-lastName"
                    type="text"
                    name="lastName"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') },
                      maxLength: { value: 50, errorMessage: translate('entity.validation.maxlength', { max: 50 }) }
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="genderLabel" for="medical-assistant-gender">
                    <Translate contentKey="velariaApp.medicalAssistant.gender">Gender</Translate>
                  </Label>
                  <AvInput
                    id="medical-assistant-gender"
                    type="select"
                    className="form-control"
                    name="gender"
                    value={(!isNew && medicalAssistantEntity.gender) || translate('velariaApp.gender.male')}
                  >
                    <option value={translate('velariaApp.gender.male')}>{translate('velariaApp.gender.male')}</option>
                    <option value={translate('velariaApp.gender.female')}>{translate('velariaApp.gender.female')}</option>
                    <option value={translate('velariaApp.gender.other')}>{translate('velariaApp.gender.other')}</option>
                  </AvInput>
                </AvGroup>
                <AvGroup>
                  <Label id="langKeyLabel" for="medical-assistant-langKey">
                    <Translate contentKey="velariaApp.medicalAssistant.langKey">Lang Key</Translate>
                  </Label>
                  <AvInput
                    id="medical-assistant-langKey"
                    type="select"
                    name="langKey"
                    value={(!isNew && medicalAssistantEntity.langKey) || LanguageCode.GERMAN}
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') },
                      minLength: { value: 2, errorMessage: translate('entity.validation.minlength', { min: 2 }) },
                      maxLength: { value: 10, errorMessage: translate('entity.validation.maxlength', { max: 10 }) },
                    }}
                  >
                    <option value={LanguageCode.GERMAN}>{translate('global.form.languageKey.german')}</option>
                    <option value={LanguageCode.ENGLISH}>{translate('global.form.languageKey.english')}</option>
                    <option value={LanguageCode.TURKISH}>{translate('global.form.languageKey.turkish')}</option>
                  </AvInput>
                </AvGroup>
                <AvGroup>
                  <Label id="emailLabel" for="medical-assistant-email">
                    <Translate contentKey="velariaApp.medicalAssistant.email">Email</Translate>
                  </Label>
                  <AvField
                    id="medical-assistant-email"
                    type="email"
                    name="email"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') }
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="mobileNumberLabel" for="medical-assistant-mobileNumber">
                    <Translate contentKey="velariaApp.medicalAssistant.mobileNumber">Mobile Number</Translate>
                  </Label>

                  <AvField
                    id="medical-assistant-mobileNumber"
                    type="text"
                    placeholder="Contry Code and mobile number"
                    name="mobileNumber"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') },
                      pattern: {
                        value: '^([0-9]{2,})$',
                        errorMessage: translate('global.messages.validate.mobileNumber.invalidPattern')
                      },
                      maxLength: { value: 20, errorMessage: translate('global.messages.validate.mobileNumber.maxlength', { max: 20 }) }
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="verificationStatusLabel" for="medical-assistant-verificationStatus">
                    <Translate contentKey="velariaApp.medicalAssistant.verificationStatus">Verification Status</Translate>
                  </Label>
                  <AvField
                    id="medical-assistant-verificationStatus"
                    type="select"
                    name="verificationStatus"
                    value={(!isNew && medicalAssistantEntity.verificationStatus) || translate('velariaApp.verification.notVerified')}
                  >
                    <option value={translate('velariaApp.verification.verified')}>{translate('velariaApp.verification.verified')}</option>
                    <option value={translate('velariaApp.verification.notVerified')}>{translate('velariaApp.verification.notVerified')}</option>
                  </AvField>
                </AvGroup>
                <AvGroup>
                  <Label for="medical-assistant-clinic">
                    <Translate contentKey="velariaApp.medicalAssistant.clinic">Clinic</Translate>
                  </Label>
                  <AvInput
                    id="medical-assistant-clinic"
                    type="select"
                    className="form-control"
                    name="clinics[0].id"
                    required
                    value={
                      !isNew && medicalAssistantEntity.clinics && medicalAssistantEntity.clinics.length > 0 && medicalAssistantEntity.clinics[0]
                        ? medicalAssistantEntity.clinics[0].id
                        : clinics && clinics[0]
                          ? clinics[0].id
                          : 0
                    }
                  >
                    {clinics
                      ? clinics.map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id} >
                          {otherEntity.name}
                        </option>
                      ))
                      : null}
                  </AvInput>
                </AvGroup>
                <Button tag={Link} id="cancel-save" to="/medical-assistant" replace className="rounded" color="success" >
                  <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
              &nbsp;
                <Button className="rounded" color="danger" id="save-entity" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </AvForm>
            )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  clinics: storeState.clinic.entities,
  medicalAssistantEntity: storeState.medicalAssistant.entity,
  loading: storeState.medicalAssistant.loading,
  updating: storeState.medicalAssistant.updating,
  updateSuccess: storeState.medicalAssistant.updateSuccess
});

const mapDispatchToProps = {
  getClinics,
  getEntity,
  updateEntity,
  createEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(MedicalAssistantUpdate);
