import { Moment } from 'moment';
import { IUser } from 'app/shared/model/user.model';

export interface IVaccination {
  id?: number;
  date?: string;
  typeId?: number;
  location?: string;
  batchNumber?: string;
  acceptedTNC?: boolean;
  user?: IUser;
}

export const defaultValue: Readonly<IVaccination> = {
  acceptedTNC: false
};
