import { IMedicalAssistant } from 'app/shared/model/medical-assistant.model';
import { IHealthcareProfessional } from 'app/shared/model/healthcare-professional.model';
import { IPatient } from 'app/shared/model/patient.model';
import { ISelfRegistration } from 'app/shared/model/self-registration.model';

export interface IClinic {
  id?: number;
  name?: string;
  street?: string;
  houseNumber?: number;
  city?: string;
  zipCode?: string;
  registrationNumber?: string;
  email?: string;
  phoneNumber?: string;
  preferredCommunicationChannel?: string;
  mainContactEmail?: string;
  verificationStatus?: string;
  urlUuid?: string;
  authorization?: string;
  medicalAssistants?: IMedicalAssistant[];
  healthcareProfessionals?: IHealthcareProfessional[];
  patients?: IPatient[];
  selfRegistrations?: ISelfRegistration[];
}

export const defaultValue: Readonly<IClinic> = {};
