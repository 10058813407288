import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Row, Col, Label, Alert } from 'reactstrap';
import { AvForm, AvGroup, AvField } from 'availity-reactstrap-validation';
import { Translate, translate } from 'react-jhipster'; import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { updateHideCalendar } from '../../ma-dashboard.reducer';
import { submitContactUs } from './contact-us-reducer';
import { IRootState } from 'app/shared/reducers';

import '../../ma-pages.scss';

interface ContactUsPageInterface extends DispatchProps, StateProps { };

const ContactUsPage = (props: ContactUsPageInterface) => {

    useEffect(() => {
        props.updateHideCalendar(true);
    },[])

    const sumbitSupportForm = (event, errors, values) => {

        if (errors.length === 0) {
            const contactUsFormDetails = { ...values };
            props.submitContactUs(contactUsFormDetails);

        }
    };

    return (
        <Col className="page-container">
            <Row>
                <Col xs={2}></Col>
                <Col>
                    <h2 id="velariaApp.contactUs.title">
                        <Translate contentKey="velariaApp.contactUs.title">Contact us</Translate>
                    </h2>
                </Col>
            </Row>
            <Row>
                <Col xs={3}></Col>
                <Col xs={6}>
                    {props.loading
                        ?
                        (<p>Loading...</p>)
                        :
                        props.activitySuccess ?
                            (<Alert>Mail sent successfully</Alert>)
                            : (
                                <AvForm onSubmit={sumbitSupportForm}>
                                    <AvGroup>
                                        <Label id="contactUsSubject" for="contactUs-subject">
                                            <Translate contentKey="velariaApp.contactUs.subject">Subject</Translate>
                                        </Label>
                                        <AvField
                                            id="contactUs-subject"
                                            type="text"
                                            name="subject"
                                            validate={{
                                                required: { value: true, errorMessage: translate('entity.validation.required') },
                                                maxLength: { value: 250, errorMessage: translate('entity.validation.maxlength', { max: 250 }) }
                                            }}
                                        />
                                    </AvGroup>
                                    <AvGroup>
                                        <Label id="contactUsMessage" for="contactUs-message">
                                            <Translate contentKey="velariaApp.contactUs.message">Message</Translate>
                                        </Label>
                                        <AvField
                                            id="contactUs-message"
                                            type="textarea"
                                            name="message"
                                            style={{ height: "200px" }}
                                            validate={{
                                                required: { value: true, errorMessage: translate('entity.validation.required') },
                                                maxLength: { value: 1000, errorMessage: translate('entity.validation.maxlength', { max: 1000 }) }
                                            }}
                                        />
                                    </AvGroup>
                                    <Row>
                                        <Col xs={3} />
                                        <Col xs={5}>
                                            <div className="wizard-form-in-button">
                                                <Button color="primary" id="submit-support-from" type="submit">
                                                    <FontAwesomeIcon icon="sign-out-alt" />
                                      &nbsp;
                                      <Translate contentKey="entity.action.submit">Submit</Translate>
                                                </Button>
                                            </div>
                                        </Col>
                                        <Col />
                                    </Row>

                                </AvForm>
                            )}
                </Col>
                <Col></Col>
            </Row>
        </Col>
    );
};

const mapStateToProps = (storeState: IRootState) => ({
    loading: storeState.contactUsReducer.loading,
    errorMessage: storeState.contactUsReducer.errorMessage,
    activitySuccess: storeState.contactUsReducer.activitySuccess,
    currentLocale: storeState.locale.currentLocale

});

const mapDispatchToProps = { submitContactUs, updateHideCalendar };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ContactUsPage);