export interface IConversationCatalogTr {
  id?: number;
  conversationTypeId?: number;
  conversationType?: string;
  conversationCategory?: string;
  messageType?: string;
  messageId?: number;
  messageText?: string;
  replyType?: string;
  replyId?: number;
  replyText?: string;
  parentMessageId?: number;
  nextMessageId?: number;
}

export const defaultValue: Readonly<IConversationCatalogTr> = {};
