import axios from 'axios';

import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

export const ACTION_TYPES = {
  ACTIVATE_INTERIM_ACCOUNT: 'activateinterim/ACTIVATE_ACCOUNT',
  RESET: 'activateinterim/RESET'
};

const initialState = {
  activationInterimSuccess: false,
  activationInterimFailure: false
};

export type ActivateInterimState = Readonly<typeof initialState>;

// Reducer
export default (state: ActivateInterimState = initialState, action): ActivateInterimState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.ACTIVATE_INTERIM_ACCOUNT):
      return {
        ...state
      };
    case FAILURE(ACTION_TYPES.ACTIVATE_INTERIM_ACCOUNT):
      return {
        ...state,
        activationInterimFailure: false
      };
    case SUCCESS(ACTION_TYPES.ACTIVATE_INTERIM_ACCOUNT):
      return {
        ...state,
        activationInterimSuccess: true
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

// Actions
export const activateInterimAction = key => ({
  type: ACTION_TYPES.ACTIVATE_INTERIM_ACCOUNT,
  payload: axios.get('api/activateinterim?key=' + key)
});

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
