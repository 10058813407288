import React from 'react';
import { Row, Col, UncontrolledCollapse, Button } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { generatePDFv2, PDFFILES } from 'app/pages/medical-assistant-dashboard/shard/pdfUtils';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

const HomeHeaderRowView = (props) => {
  const openInstallationGuide = () => {
    const language = props.locale || 'en'
    const files = {
        en: PDFFILES.USER_GUIDE_SELF_REG_EN,
        de: PDFFILES.USER_GUIDE_SELF_REG_DE,
        tr: PDFFILES.USER_GUIDE_SELF_REG_EN
    }
    generatePDFv2(files?.[language])
};

  return (
    <Row className="home__body">
      <Row className="home__body--content">
        <Col md="12" lg="5">
          <div className="home__picture"><img src="../../../content/images/home-page-pic.jpg" /></div>
        </Col>
        <Col>
          <h1 className="home__title">
            <Translate contentKey="home.header.title">Activated</Translate>
          </h1>
          <h2 className="home__subtitle">
            <Translate contentKey="home.header.subtitle">Activated</Translate>
          </h2>
          <div className="home__description">
            <p>
              <Translate contentKey="home.header.description-first-row">Activated</Translate>
            </p>
            <p>
              <Translate contentKey="home.header.description-second-row">Activated</Translate>
            </p>
          </div>
        </Col>
      </Row>
      <Row className="home__body--content">
        <Col xs={{ size: 12, order: 1, offset: 0 }} sm={{ size: 8, order: 1, offset: 3 }}>
          <div className="home__description">
            <a href="https://play.google.com/store/apps/details?id=de.velaria" target="_blank" rel="noopener noreferrer">
              <img id="android-logo" src="../../../content/images/google-play-badge.png" />
            </a>
            <a href="https://apps.apple.com/de/app/velaria/id1568037631" target="_blank" rel="noopener noreferrer">
              <img id="apple-logo" src="../../../content/images/apple-logo.svg" />
            </a>
            &nbsp;
            &nbsp;
            <a href="javascript:void(0)" onClick={openInstallationGuide} style={{ textDecoration: 'underline', fontSize: 18, color: '#000000' }}>
              <Translate contentKey='fileNames.installationGuide' />
            </a>
            <br />
            <br />
          </div>


          <Row className="home__toggler">
            <Col>
              <Button color="#91D7F2" id="dataProtectionToggler" style={{ marginBottom: '1rem' }}>
                <Translate contentKey="home.dataProtection.title">Data Protection</Translate>
              </Button>
              <UncontrolledCollapse toggler="#dataProtectionToggler" style={{ marginBottom: '1rem' }}>
                <ul>
                  <li><Translate contentKey="home.dataProtection.text1">text1</Translate></li>
                  <li><Translate contentKey="home.dataProtection.text2">text2</Translate></li>
                  <ul>
                    <li><Translate contentKey="home.dataProtection.text3">text3</Translate></li>
                    <li><Translate contentKey="home.dataProtection.text4">text4</Translate></li>
                    <li><Translate contentKey="home.dataProtection.text5">text5</Translate></li>
                    <li><Translate contentKey="home.dataProtection.text6">text6</Translate></li>
                  </ul>
                  <li><Translate contentKey="home.dataProtection.text7">text7</Translate></li>
                  <li><Translate contentKey="home.dataProtection.text8">text8</Translate></li>
                </ul>
              </UncontrolledCollapse>
            </Col>
          </Row>

          <Row className="home__toggler">
            <Col>
              <Button color="#91D7F2" id="dataPortabilityToggler" style={{ marginBottom: '1rem' }}>
                <Translate contentKey="home.dataPortability.title">Data Portabiilty</Translate>
              </Button>
              <UncontrolledCollapse toggler="#dataPortabilityToggler" style={{ marginBottom: '1rem' }}>
                <ul>
                  <li><Translate contentKey="home.dataPortability.text1">text1</Translate></li>
                  <li><Translate contentKey="home.dataPortability.text2">text2</Translate></li>
                  <li><Translate contentKey="home.dataPortability.text3">text3</Translate></li>
                </ul>
              </UncontrolledCollapse>
            </Col>
          </Row>

          <Row className="home__toggler">
            <Col>
              <Button color="#91D7F2" id="systemRequirementsToggler" style={{ marginBottom: '1rem' }}>
                <Translate contentKey="home.systemRequirements.title">System Requirements</Translate>
              </Button>
              <UncontrolledCollapse toggler="#systemRequirementsToggler" style={{ marginBottom: '1rem' }}>
                <ul>
                  <li><Translate contentKey="home.systemRequirements.text1">text1</Translate></li>
                  <ul>
                    <li><Translate contentKey="home.systemRequirements.text2">text2</Translate></li>
                    <li><Translate contentKey="home.systemRequirements.text3">text3</Translate></li>
                    <li><Translate contentKey="home.systemRequirements.text4">text4</Translate></li>
                  </ul>
                  <li><Translate contentKey="home.systemRequirements.text5">text5</Translate></li>
                  <ul>
                    <li><Translate contentKey="home.systemRequirements.text6">text6</Translate></li>
                    <br />
                    <table>
                      <tr>
                        <th>Browser</th>
                        <th>Platform</th>
                        <th>Version</th>
                      </tr>
                      <tr>
                        <td>Chrome</td>
                        <td>Desktop/Android/iOS</td>
                        <td>80.0.3987</td>
                      </tr>
                      <tr>
                        <td>Firefox</td>
                        <td>Desktop</td>
                        <td>75.0</td>
                      </tr>
                      <tr>
                        <td>Firefox</td>
                        <td>iOS</td>
                        <td>23.0</td>
                      </tr>
                      <tr>
                        <td>Firefox</td>
                        <td>Android</td>
                        <td>68.7.0</td>
                      </tr>
                    </table>
                  </ul>
                </ul>
              </UncontrolledCollapse>
            </Col>
          </Row>

        </Col>
      </Row>
      <Row className="home__documents">
        <Col>
          <a onClick={() => window.open("/account/privacyPolicyDataPage")} style={{ textDecoration: "underline" }} target="_blank" rel="noopener noreferrer" ><Translate contentKey='home.link.dataProtection' /></a>
        </Col>
      </Row>
      <Row className="home__documents">
        <Col />
        <Col sm={{ span: 6 }}>
          <p>
            <div>docjo GmbH</div>
            <div> Lichtstrasse 35 </div>
            <div> CH-4056 Basel, Schweiz </div>
            <div> Handelsregisteramt: Kanton Basel-Stadt </div>
            <div> UID: CHE-295.966.793 </div>
            <div> Geschäftsführung: Dr. Ulrich Mühlner </div>
            <div> Kontakt: support@docjo.de </div>
          </p>
        </Col>
        <Col />
      </Row>

      <span className="home__copyright">© 2021 by docjo GmbH</span>
    </Row>
  );
};

const mapStateToProps = ({ locale }) => ({
  locale: locale.currentLocale
});

export const HomeHeaderRow = connect(mapStateToProps, {})(HomeHeaderRowView);
