import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Row, Col, Label } from 'reactstrap';
import { AvForm, AvGroup, AvInput, AvField, AvFeedback } from 'availity-reactstrap-validation';
import { Translate, translate } from 'react-jhipster'; import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { getEntities as getIcdCodes } from 'app/entities/icd-code/icd-code.reducer';
import { reset, updateEntity as updatePatient } from 'app/entities/patient/patient.reducer';
import '../ma-pages.scss';
import { LanguageCode } from 'app/shared/languages/language-options';

export interface IMaDashPatientCreateUpdateProps extends StateProps, DispatchProps { }


const MaDashUpdatePatient = (props: IMaDashPatientCreateUpdateProps) => {
    const [phone, setPhone] = useState("");

    useEffect(() => {
        props.getIcdCodes();
        setPhone(props.selectedPatientToUpdate && props.selectedPatientToUpdate.mobileNumber);
    }, []);

    const saveEntity = (event, errors, values) => {
        const patient = {
            ...props.selectedPatientToUpdate,
            ...values
        };

        if (errors.length === 0) {
            patient.clinics = [props.selectedClinic];
            props.updatePatient(patient);
        }
    };


    return (
        <Col className="page-container">
            <Row>
                <Col>
                    <h2 id="velariaApp.patient.home.createLabel">
                        <Translate contentKey="velariaApp.patient.home.changeLabel">Edit Patient</Translate>
                    </h2>
                </Col>
            </Row>
            <Row>
                <Col>
                    {props.loading ? (
                        <p>Loading...</p>
                    ) : (
                            <AvForm model={props.selectedPatientToUpdate} onSubmit={saveEntity}>
                                <Row>
                                    <Col>
                                        <AvGroup>
                                            <Label id="salutationLabel" for="patient-salutation">
                                                <Translate contentKey="velariaApp.patient.salutation">Salutation</Translate>
                                            </Label>
                                            <AvInput
                                                id="patient-salutation"
                                                type="select"
                                                className="form-control"
                                                name="salutation"
                                            >
                                                <option value={translate('velariaApp.salutation.mr')}>{translate('velariaApp.salutation.mr')}</option>
                                                <option value={translate('velariaApp.salutation.ms')}>{translate('velariaApp.salutation.ms')}</option>
                                            </AvInput>
                                        </AvGroup>
                                    </Col>
                                    <Col>
                                        <AvGroup>
                                            <Label id="titleLabel" for="patient-title">
                                                <Translate contentKey="velariaApp.patient.title">Title</Translate>
                                            </Label>
                                            <AvField
                                                id="patient-title"
                                                type="text"
                                                name="title"
                                                validate={{
                                                    maxLength: { value: 50, errorMessage: translate('entity.validation.maxlength', { max: 50 }) }
                                                }}
                                            />
                                        </AvGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <AvGroup>
                                            <Label id="firstNameLabel" for="patient-firstName">
                                                <Translate contentKey="velariaApp.patient.firstName">First Name</Translate>
                                            </Label>
                                            <AvField
                                                id="patient-firstName"
                                                type="text"
                                                name="firstName"
                                                validate={{
                                                    required: { value: true, errorMessage: translate('entity.validation.required') },
                                                    maxLength: { value: 50, errorMessage: translate('entity.validation.maxlength', { max: 50 }) }
                                                }}
                                            />
                                        </AvGroup>
                                    </Col>
                                    <Col>
                                        <AvGroup>
                                            <Label id="lastNameLabel" for="patient-lastName">
                                                <Translate contentKey="velariaApp.patient.lastName">Last Name</Translate>
                                            </Label>
                                            <AvField
                                                id="patient-lastName"
                                                type="text"
                                                name="lastName"
                                                validate={{
                                                    required: { value: true, errorMessage: translate('entity.validation.required') },
                                                    maxLength: { value: 50, errorMessage: translate('entity.validation.maxlength', { max: 50 }) }
                                                }}
                                            />
                                        </AvGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <AvGroup>
                                            <Label id="genderLabel" for="patient-gender">
                                                <Translate contentKey="velariaApp.patient.gender">Gender</Translate>
                                            </Label>
                                            <AvInput
                                                id="patient-gender"
                                                type="select"
                                                className="form-control"
                                                name="gender"
                                            >
                                                <option value={translate('velariaApp.gender.male')}>{translate('velariaApp.gender.male')}</option>
                                                <option value={translate('velariaApp.gender.female')}>{translate('velariaApp.gender.female')}</option>
                                                <option value={translate('velariaApp.gender.other')}>{translate('velariaApp.gender.other')}</option>
                                            </AvInput>
                                        </AvGroup>
                                    </Col>
                                    <Col>
                                        <AvGroup>
                                            <Label id="emailLabel" for="patient-email">
                                                <Translate contentKey="velariaApp.patient.email">Email</Translate>
                                            </Label>
                                            <AvField
                                                id="patient-email"
                                                type="email"
                                                name="email"
                                                validate={{
                                                    required: { value: true, errorMessage: translate('entity.validation.required') }
                                                }}
                                            />
                                        </AvGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Label id="mobileNumberLabel" for="patient-mobileNumber">
                                            <Translate contentKey="velariaApp.patient.mobileNumber">Mobile Number</Translate>
                                        </Label>

                                        <AvField
                                                id="patient-mobileNumber"
                                                type="text"
                                                placeholder="Contry Code and mobile number"
                                                name="mobileNumber"
                                                validate={{
                                                    required: { value: true, errorMessage: translate('entity.validation.required') },
                                                    pattern: {
                                                        value: '^([0-9]{2,})$',
                                                        errorMessage: translate('global.messages.validate.mobileNumber.invalidPattern')
                                                    },
                                                    maxLength: { value: 20, errorMessage: translate('global.messages.validate.mobileNumber.maxlength', { max: 20 }) }
                                                }}
                                            />
                                    </Col>
                                    <Col>
                                        <AvGroup>
                                            <Label id="birthdateLabel" for="patient-birthdate">
                                                <Translate contentKey="velariaApp.patient.birthdate">Birthdate</Translate>
                                            </Label>
                                            <AvField
                                                id="patient-birthdate"
                                                type="date"
                                                className="form-control"
                                                name="birthdate"
                                                validate={{
                                                    required: { value: true, errorMessage: translate('entity.validation.required') },
                                                    dateRange: {
                                                        start: { value: -130, units: 'years', }, end: { value: -18, units: 'years', },
                                                        errorMessage: translate('global.messages.validate.birthdate.invalidAge')
                                                    }
                                                }}
                                            />
                                        </AvGroup>
                                    </Col>

                                </Row>
                                <Row>
                                    <Col>
                                        <AvGroup>
                                            <Label for="patient-icdCode">
                                                <Translate contentKey="velariaApp.patient.icdCode">Icd Code</Translate>
                                            </Label>
                                            <AvInput id="patient-icdCode" type="select" className="form-control" name="icdCode.id"
                                                value={
                                                    props.selectedPatientToUpdate &&
                                                    props.selectedPatientToUpdate.icdCode &&
                                                    props.selectedPatientToUpdate.icdCode.id

                                                }>
                                                {props.icdCodes
                                                    ? props.icdCodes.map(otherEntity => (
                                                        <option value={otherEntity.id} key={otherEntity.id}>
                                                            {`${otherEntity.code}  -   ${otherEntity.description}`}
                                                        </option>
                                                    ))
                                                    : ''}
                                            </AvInput>
                                        </AvGroup>
                                    </Col>
                                    <Col>
                                        <AvGroup>
                                            <Label id="langKeyLabel" for="patient-langKey">
                                                <Translate contentKey="velariaApp.patient.langKey">Lang Key</Translate>
                                            </Label>
                                            <AvInput
                                                id="patient-langKey"
                                                type="select"
                                                name="langKey"
                                                validate={{
                                                    required: { value: true, errorMessage: translate('entity.validation.required') },
                                                    minLength: { value: 2, errorMessage: translate('entity.validation.minlength', { min: 2 }) },
                                                    maxLength: { value: 10, errorMessage: translate('entity.validation.maxlength', { max: 10 }) },
                                                }}
                                            >
                                                <option value={LanguageCode.GERMAN}>{translate('global.form.languageKey.german')}</option>
                                                <option value={LanguageCode.ENGLISH}>{translate('global.form.languageKey.english')}</option>
                                                <option value={LanguageCode.TURKISH}>{translate('global.form.languageKey.turkish')}</option>
                                            </AvInput>
                                        </AvGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={3} />
                                    <Col xs={6}>
                                        <div className="wizard-form-in-button">
                                            <Button color="primary" id="save-entity" type="submit" disabled={props.updating}>
                                                <FontAwesomeIcon icon="save" />
                                      &nbsp;
                                      <Translate contentKey="entity.action.save">Save</Translate>
                                            </Button>
                                        </div>
                                    </Col>
                                    <Col />
                                </Row>
                            </AvForm>
                        )}
                </Col>
            </Row>
        </Col>
    );
};

const mapStateToProps = (storeState: IRootState) => ({
    icdCodes: storeState.icdCode.entities,

    selectedClinic: storeState.maDashboardReducer.selectedClinic,
    selectedPatientToUpdate: storeState.maDashboardReducer.selectedPatient,

    loading: storeState.patient.loading,
    updating: storeState.patient.updating,
    updateSuccess: storeState.patient.updateSuccess,
    currentLocale: storeState.locale.currentLocale

});

const mapDispatchToProps = {
    getIcdCodes,
    updatePatient,
    reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(MaDashUpdatePatient);
