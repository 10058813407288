import React, { useEffect } from 'react';
import { Button, ListGroup, ListGroupItem, Badge, Row, Col, Alert } from 'reactstrap';
import { getBots, updateSelectedBot } from './chatbot.reducer';
import { IRootState } from 'app/shared/reducers';
import { connect } from 'react-redux';
import moment from 'moment';

interface IBotsList extends StateProps, DispatchProps { }

const BotsLst = (props: IBotsList) => {

    useEffect(() => {
        props.getBots();
    }, []);

    const handleOnBotClick = (bot) => {
        props.updateSelectedBot(bot);
    };

    return (
        <div>
            <h2>Chatbots List</h2>
            <br />
            {props.botsList && props.botsList.length > 0
                ?
                <Row>
                    <Col>
                        <Row>
                            <Col>
                                <ListGroup>
                                    {props.botsList.map((bot) =>
                                        <Row key={bot.id}>
                                            <Col xs={2} />
                                            <Col xs={8}>

                                                <ListGroupItem
                                                    key={bot.id}
                                                    className="justify-content-between">
                                                    <Row>
                                                        <Col xs={2} ><img src={bot.svgIcon} alt='icon' className="center"></img></Col>
                                                        <Col xs={3} ><h4>{bot.conversationType ? bot.conversationType : ""}</h4></Col>
                                                        <Col xs={1} >
                                                            <Badge pill color="danger">1</Badge>
                                                        </Col>
                                                        <Col xs={3} >{bot.requiredSince}</Col>
                                                        <Col>
                                                            <Button color="warning" onClick={() => handleOnBotClick(bot)} > Start</Button>
                                                        </Col>
                                                    </Row>
                                                </ListGroupItem>
                                            </Col>
                                            <Col />
                                        </Row>
                                    )}

                                </ListGroup>

                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div style={{ border: "1px" }} />
                            </Col>
                        </Row>
                    </Col>

                </Row>
                :
                <div><Alert color="danger">Bots not found</Alert></div>
            }
        </div >
    );
    ;
};

const mapStateToProps = ({ chatbotReducer }: IRootState) => ({
    botsList: chatbotReducer.bots

});

const mapDispatchToProps = {
    getBots, updateSelectedBot

};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(BotsLst);

