import axios from 'axios';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

export const ACTION_TYPES = {
  GENERATE_DOCUMENT: 'generateDocument/GENERATE_DOCUMENT',
  RESET: 'generateDocument/RESET'
};

const initialState = {
  success: false,
  failure: false,
  response: '',
  loading: false
};

export type GenerateDocumentState = Readonly<typeof initialState>;

// Reducer
export default (state: GenerateDocumentState = initialState, action): GenerateDocumentState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.GENERATE_DOCUMENT):
      return {
        ...state,
        loading: true
      };
    case FAILURE(ACTION_TYPES.GENERATE_DOCUMENT):
      return {
        ...state,
        success: false,
        failure: true,
        loading: false
      };
    case SUCCESS(ACTION_TYPES.GENERATE_DOCUMENT):
      return {
        ...state,
        success: true,
        failure: false,
        loading: false,
        response: action.payload.data.content
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

// Actions

export const retrieveDocumentData = (filename: string, lang: string) => ({
  type: ACTION_TYPES.GENERATE_DOCUMENT,
  payload: axios.get(`api/general/get-document/${filename}/${lang}`)
});

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
