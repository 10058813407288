import React from 'react';
import { Switch } from 'react-router-dom';
import Loadable from 'react-loadable';

import Login from 'app/modules/login/login';
import Register from 'app/modules/account/register/register';
import Activate from 'app/modules/account/activate/activate';
import PasswordResetInit from 'app/modules/account/password-reset/init/password-reset-init';
import PasswordResetFinish from 'app/modules/account/password-reset/finish/password-reset-finish';
import Logout from 'app/modules/login/logout';
import Home from 'app/modules/home/home';
import Entities from 'app/entities';
import PrivateRoute from 'app/shared/auth/private-route';
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';
import PageNotFound from 'app/shared/error/page-not-found';
import { AUTHORITIES } from 'app/config/constants';
import ActivateInterim from './modules/account/activate-interim/ActivateInterim';
import ActivateChoosePassword from './modules/account/activate-choose-password/activate-choose-password';
import MedicalAssistantDashboard from './pages/medical-assistant-dashboard/ma-dashboard-layout/medical-assistant-dashboard';
import VerifySms from './modules/account/sms-code-verification/verify-sms';
import patientWelcomePage from './entities/patient/patient-welcome-page';
import ChatbotPage from './pages/chatbot/chatbot-page';
import TermsAndConditions from './modules/account/terms-and-conditions/terms-and-conditions';
import PrivacyPolicyDataPage from './modules/account/terms-and-conditions/data-pages/PrivacyPolicyDataPage';
import TermsAndConditionsDataPage from './modules/account/terms-and-conditions/data-pages/TermsAndConditionsDataPage';
import MedicalAssistantEditPersonalDetails from './pages/medical-assistant-dashboard/ma-edit-personal-details/ma-edit-personal-details';
import GenerateDocumentPage from './modules/home/generate-document/generate-document-page';
import accountSelfRegistration from './pages/account-self-registration/self-registration-update';
import StudyConsentDataPage from './modules/account/terms-and-conditions/data-pages/StudyConsentDataPage';
import MaDashSelfRegistrationCreatePatient from './pages/medical-assistant-dashboard/ma-selfRegistration/ma-selfRegistration-create-patient'

const Account = Loadable({
  loader: () => import(/* webpackChunkName: "account" */ 'app/modules/account'),
  loading: () => <div>loading ...</div>
});

const Admin = Loadable({
  loader: () => import(/* webpackChunkName: "administration" */ 'app/modules/administration'),
  loading: () => <div>loading ...</div>
});

const Routes = () => (
  <div className="view-routes">
    <Switch>
      <ErrorBoundaryRoute path="/home/:fileName?" component={GenerateDocumentPage} />
      <ErrorBoundaryRoute path="/documents/:fileName?" component={GenerateDocumentPage} />
      <ErrorBoundaryRoute path="/login" component={Login} />
      <ErrorBoundaryRoute path="/logout" component={Logout} />
      <ErrorBoundaryRoute path="/account/register" component={Register} />
      <ErrorBoundaryRoute path="/account/activateInterim1/:key?" component={TermsAndConditions} />
      <ErrorBoundaryRoute path="/account/activateInterim2/:key?" component={ActivateChoosePassword} />
      <ErrorBoundaryRoute path="/medical-assitant/edit-personal-details" component={MedicalAssistantEditPersonalDetails} />
      <ErrorBoundaryRoute path="/account/activateInterim/:key?" component={ActivateInterim} />
      <ErrorBoundaryRoute path="/account/privacyPolicyDataPage" component={PrivacyPolicyDataPage} />
      <ErrorBoundaryRoute path="/account/termsAndConditionsDataPage" component={TermsAndConditionsDataPage} />
      <ErrorBoundaryRoute path="/account/studyConsent" component={StudyConsentDataPage} />
      <ErrorBoundaryRoute path="/account/verifysms/:key?" component={VerifySms} />
      <ErrorBoundaryRoute path="/account/activate/:key?" component={Activate} />
      <ErrorBoundaryRoute path="/account/resetPassword:key?" component={ActivateChoosePassword} />
      <ErrorBoundaryRoute path="/account/reset/request" component={PasswordResetInit} />
      <ErrorBoundaryRoute path="/account/self-registration" component={accountSelfRegistration} />
      <ErrorBoundaryRoute path="/account/reset/finish/:key?" component={PasswordResetFinish} />
      <ErrorBoundaryRoute path="/patient-welcome" component={patientWelcomePage} />
      <PrivateRoute path="/admin" component={Admin} hasAnyAuthorities={[AUTHORITIES.ADMIN]} />
      <PrivateRoute path="/account" component={Account} hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.USER]} />
      <PrivateRoute path="/ma-dashboard" component={MedicalAssistantDashboard} hasAnyAuthorities={[AUTHORITIES.MEDICAL_ASSISTANT]} />
      <ErrorBoundaryRoute path="/chatbot" component={ChatbotPage} />
      <ErrorBoundaryRoute path="/" exact component={Home} />
      <PrivateRoute path="/" component={Entities} hasAnyAuthorities={[AUTHORITIES.ADMIN]} />
      <ErrorBoundaryRoute component={PageNotFound} />
    </Switch>
  </div>
);

export default Routes;
