import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Table, Alert, Spinner } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { updateHideCalendar } from '../ma-dashboard.reducer';
import { IRootState } from 'app/shared/reducers';
import { getEntitiesByQuery, reset } from 'app/entities/healthcare-professional/healthcare-professional.reducer';
import '../ma-pages.scss';

interface IMedicalAssistantClinicDoctors extends StateProps, DispatchProps { }

const MedicalAssistantClinicDoctors = (props: IMedicalAssistantClinicDoctors) => {

    useEffect(() => {
        props.reset();
        if (props.selectedClinic) {
            props.getEntitiesByQuery('clinicId.equals=' + props.selectedClinic.id);
        }
    }, [props.selectedClinic]);

    useEffect(() => {
        props.updateHideCalendar(true);
    },[])

    return (
        <Col className="page-container">
            <h2 id="healthcare-professional-heading">
                <Translate contentKey="velariaApp.healthcareProfessional.home.title">Doctors</Translate>
            </h2>
            <div className="table-responsive">
                {props.selectedClinic && props.healthcareProfessionalList && props.healthcareProfessionalList.length > 0 ? (
                    <Table responsive>
                        <thead>
                            <tr>
                                <th className="hand" >
                                    <Translate contentKey="velariaApp.healthcareProfessional.fullName">Full Name</Translate>
                                </th>
                                <th className="hand" >
                                    <Translate contentKey="velariaApp.healthcareProfessional.gender">Gender</Translate>
                                </th>
                                <th className="hand" >
                                    <Translate contentKey="velariaApp.healthcareProfessional.email">Email</Translate>
                                </th>
                                <th className="hand" >
                                    <Translate contentKey="velariaApp.healthcareProfessional.mobileNumber">Mobile Number</Translate>{' '}
                                </th>
                                <th className="hand" >
                                    <Translate contentKey="velariaApp.healthcareProfessional.registrationNumber">Registration Number</Translate>{' '}
                                </th>
                                <th className="hand" >
                                    <Translate contentKey="velariaApp.healthcareProfessional.verificationStatus">Verification Status</Translate>{' '}
                                </th>
                                <th />
                            </tr>
                        </thead>
                        <tbody>
                            {props.healthcareProfessionalList.map((healthcareProfessional, i) => (
                                <tr key={`entity-${i}`}>
                                    <td>{`${healthcareProfessional.salutation} ${healthcareProfessional.title} ${healthcareProfessional.firstName} ${healthcareProfessional.lastName}`}</td>
                                    <td>{healthcareProfessional.gender}</td>
                                    <td>{healthcareProfessional.email}</td>
                                    <td>{healthcareProfessional.mobileNumber}</td>
                                    <td>{healthcareProfessional.registrationNumber}</td>
                                    <td>{healthcareProfessional.verificationStatus}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                ) : (<Col><Spinner color="warning" className="center">LOADING</Spinner></Col>)}
            </div>
        </Col>
    );
};

const mapStateToProps = (storeState: IRootState) => ({
    healthcareProfessionalList: storeState.healthcareProfessional.entities,
    selectedClinic: storeState.maDashboardReducer.selectedClinic,
    currentLocale: storeState.locale.currentLocale
});

const mapDispatchToProps = {
    getEntitiesByQuery, reset, updateHideCalendar
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(MedicalAssistantClinicDoctors);
