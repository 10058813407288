import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { Translate, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { getEntities as getClinics } from 'app/entities/clinic/clinic.reducer';
import { getEntities as getHealthcareProfessionals } from 'app/entities/healthcare-professional/healthcare-professional.reducer';
import { getEntities as getPatients } from 'app/entities/patient/patient.reducer';
import { getEntity, updateEntity, createEntity, reset } from './appointment.reducer';
import moment from 'moment';
import 'moment-timezone';

const NOW = new Date().toJSON().split('T')[0];
const MAX_TIME_SINCE_TODAY = 10;

export interface IAppointmentUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string; }> { }

export const AppointmentUpdate = (props: IAppointmentUpdateProps) => {
  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);

  const { appointmentEntity, clinics, healthcareProfessionals, patients, loading, updating } = props;

  const handleClose = () => {
    props.history.push('/appointment' + props.location.search);
  };

  useEffect(() => {
    if (isNew) {
      props.reset();
    } else {
      props.getEntity(props.match.params.id);
    }

    props.getClinics();
    props.getHealthcareProfessionals();
    props.getPatients(0,50,'asc');
  }, []);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const entity = {
        ...appointmentEntity,
        ...values
      };

      const momentObj = moment(entity.date + entity.time, 'YYYY-MM-DDLT');
      entity.date = moment.tz(momentObj.format('YYYY-MM-DDTHH:mm:ss'),'Europe/Berlin');

      if (isNew) {
        props.createEntity(entity);
      } else {
        props.updateEntity(entity);
      }
    }
  };

  const getMaxDateForAppointment = () => {

    const fiveYearsAgoFromNow = new Date();
    fiveYearsAgoFromNow.setFullYear(fiveYearsAgoFromNow.getFullYear() + MAX_TIME_SINCE_TODAY);

    return fiveYearsAgoFromNow.toJSON().split('T')[0];

  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md={4} sm={8}  >
          <h2 id="velariaApp.appointment.home.createOrEditLabel">
            <Translate contentKey={isNew
              ? "velariaApp.appointment.home.createLabel"
              : "velariaApp.appointment.home.editLabel"}
            >
              Create or edit a Appointment</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md={4} sm={8} className="info-box">
          {loading ? (
            <p>Loading...</p>
          ) : (
              <AvForm model={isNew ? {} : appointmentEntity} onSubmit={saveEntity}>
                <AvGroup>
                  <Label id="titleLabel" for="appointment-title">
                    <Translate contentKey="velariaApp.appointment.title">Title</Translate>
                  </Label>
                  <AvField id="appointment-title" type="text" name="title" />
                </AvGroup>
                <AvGroup>
                  <Label id="dateLabel" for="appointment-date">
                    <Translate contentKey="velariaApp.appointment.date">Date</Translate>
                  </Label>
                  <AvField
                    id="appointment-date"
                    type="date"
                    className="form-control"
                    name="date"
                    min={NOW}
                    max={getMaxDateForAppointment()}
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') }
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="timeLabel" for="appointment-time">
                    <Translate contentKey="velariaApp.appointment.time">Time</Translate>
                  </Label>
                  <AvField
                    id="appointment-time"
                    type="text"
                    name="time"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') },
                      maxLength: { value: 5 },
                      pattern: {
                        value: '^(20|21|22|23|[01]\\d|\\d)((:[0-5]\\d){1})$',
                        errorMessage: translate('global.messages.validate.appointment.invalidTime'),
                      }
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="confirmedLabel" for="appointment-confirmed">
                    <Translate contentKey="velariaApp.appointment.confirmed">Confirmed</Translate>
                  </Label>
                  <AvInput
                    id="appointment-confirmed"
                    type="select"
                    className="form-control"
                    name="confirmed"
                    value={(!isNew && appointmentEntity.confirmed) || translate('velariaApp.confirmation.notConfirmed')}
                  >
                    <option value={translate('velariaApp.confirmation.confirmed')}>{translate('velariaApp.confirmation.confirmed')}</option>
                    <option value={translate('velariaApp.confirmation.notConfirmed')}>{translate('velariaApp.confirmation.notConfirmed')}</option>
                  </AvInput>
                </AvGroup>
                <AvGroup>
                  <Label for="appointment-clinic">
                    <Translate contentKey="velariaApp.appointment.clinic">Clinic</Translate>
                  </Label>
                  <AvInput
                    id="appointment-clinic"
                    type="select"
                    className="form-control"
                    name="clinic.id"
                    value={clinics[0] && clinics[0].id}
                    required
                  >
                    {clinics
                      ? clinics.map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.name}
                        </option>
                      ))
                      : null}
                  </AvInput>
                  <AvFeedback>
                    <Translate contentKey="entity.validation.required">This field is required.</Translate>
                  </AvFeedback>
                </AvGroup>
                <AvGroup>
                  <Label for="appointment-healthcareProfessional">
                    <Translate contentKey="velariaApp.appointment.healthcareProfessional">Healthcare Professional</Translate>
                  </Label>
                  <AvInput
                    id="appointment-healthcareProfessional"
                    type="select"
                    className="form-control"
                    name="healthcareProfessional.id"
                    value={healthcareProfessionals[0] && healthcareProfessionals[0].id}
                    required
                  >
                    {healthcareProfessionals
                      ? healthcareProfessionals.map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.email}
                        </option>
                      ))
                      : null}
                  </AvInput>
                  <AvFeedback>
                    <Translate contentKey="entity.validation.required">This field is required.</Translate>
                  </AvFeedback>
                </AvGroup>
                <AvGroup>
                  <Label for="appointment-patient">
                    <Translate contentKey="velariaApp.appointment.patient">Patient</Translate>
                  </Label>
                  <AvInput
                    id="appointment-patient"
                    type="select"
                    className="form-control"
                    name="patient.id"
                    value={patients[0] && patients[0].id}
                    required
                  >
                    {patients
                      ? patients.map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.email}
                        </option>
                      ))
                      : null}
                  </AvInput>
                  <AvFeedback>
                    <Translate contentKey="entity.validation.required">This field is required.</Translate>
                  </AvFeedback>
                </AvGroup>
                <Button tag={Link} id="cancel-save" to="/appointment" replace className="rounded" color="success" >
                  <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
              &nbsp;
                <Button className="rounded" color="danger" id="save-entity" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </AvForm>
            )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  clinics: storeState.clinic.entities,
  healthcareProfessionals: storeState.healthcareProfessional.entities,
  patients: storeState.patient.entities,
  appointmentEntity: storeState.appointment.entity,
  loading: storeState.appointment.loading,
  updating: storeState.appointment.updating,
  updateSuccess: storeState.appointment.updateSuccess
});

const mapDispatchToProps = {
  getClinics,
  getHealthcareProfessionals,
  getPatients,
  getEntity,
  updateEntity,
  createEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentUpdate);
