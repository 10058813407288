import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IHealthcareProfessional, defaultValue } from 'app/shared/model/healthcare-professional.model';

export const ACTION_TYPES = {
  FETCH_HEALTHCAREPROFESSIONAL_LIST: 'healthcareProfessional/FETCH_HEALTHCAREPROFESSIONAL_LIST',
  FETCH_HEALTHCAREPROFESSIONAL: 'healthcareProfessional/FETCH_HEALTHCAREPROFESSIONAL',
  CREATE_HEALTHCAREPROFESSIONAL: 'healthcareProfessional/CREATE_HEALTHCAREPROFESSIONAL',
  UPDATE_HEALTHCAREPROFESSIONAL: 'healthcareProfessional/UPDATE_HEALTHCAREPROFESSIONAL',
  DELETE_HEALTHCAREPROFESSIONAL: 'healthcareProfessional/DELETE_HEALTHCAREPROFESSIONAL',
  RESET: 'healthcareProfessional/RESET'
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IHealthcareProfessional>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false
};

export type HealthcareProfessionalState = Readonly<typeof initialState>;

// Reducer

export default (state: HealthcareProfessionalState = initialState, action): HealthcareProfessionalState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_HEALTHCAREPROFESSIONAL_LIST):
    case REQUEST(ACTION_TYPES.FETCH_HEALTHCAREPROFESSIONAL):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_HEALTHCAREPROFESSIONAL):
    case REQUEST(ACTION_TYPES.UPDATE_HEALTHCAREPROFESSIONAL):
    case REQUEST(ACTION_TYPES.DELETE_HEALTHCAREPROFESSIONAL):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_HEALTHCAREPROFESSIONAL_LIST):
    case FAILURE(ACTION_TYPES.FETCH_HEALTHCAREPROFESSIONAL):
    case FAILURE(ACTION_TYPES.CREATE_HEALTHCAREPROFESSIONAL):
    case FAILURE(ACTION_TYPES.UPDATE_HEALTHCAREPROFESSIONAL):
    case FAILURE(ACTION_TYPES.DELETE_HEALTHCAREPROFESSIONAL):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_HEALTHCAREPROFESSIONAL_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.FETCH_HEALTHCAREPROFESSIONAL):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_HEALTHCAREPROFESSIONAL):
    case SUCCESS(ACTION_TYPES.UPDATE_HEALTHCAREPROFESSIONAL):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_HEALTHCAREPROFESSIONAL):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/healthcare-professionals';

// Actions

export const getEntities: ICrudGetAllAction<IHealthcareProfessional> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_HEALTHCAREPROFESSIONAL_LIST,
    payload: axios.get<IHealthcareProfessional>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`)
  };
};

export const getEntitiesByQuery = query => async dispatch => {
  const requestUrl = `/api/healthcare-professionals?sort=id,desc&${query}`;

  await dispatch({
    type: ACTION_TYPES.FETCH_HEALTHCAREPROFESSIONAL_LIST,
    payload: axios.get<IHealthcareProfessional>(`${requestUrl}`)
  });
};

export const getEntity: ICrudGetAction<IHealthcareProfessional> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_HEALTHCAREPROFESSIONAL,
    payload: axios.get<IHealthcareProfessional>(requestUrl)
  };
};

export const createEntity: ICrudPutAction<IHealthcareProfessional> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_HEALTHCAREPROFESSIONAL,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<IHealthcareProfessional> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_HEALTHCAREPROFESSIONAL,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<IHealthcareProfessional> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_HEALTHCAREPROFESSIONAL,
    payload: axios.delete(requestUrl)
  });
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
