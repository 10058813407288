import { combineReducers } from 'redux';
import { loadingBarReducer as loadingBar } from 'react-redux-loading-bar';

import locale, { LocaleState } from './locale';
import authentication, { AuthenticationState } from './authentication';
import applicationProfile, { ApplicationProfileState } from './application-profile';

import administration, { AdministrationState } from 'app/modules/administration/administration.reducer';
import userManagement, { UserManagementState } from 'app/modules/administration/user-management/user-management.reducer';
import register, { RegisterState } from 'app/modules/account/register/register.reducer';
import activate, { ActivateState } from 'app/modules/account/activate/activate.reducer';
import activationChoosePassword, {
  ActivationChoosePasswordState
} from 'app/modules/account/activate-choose-password/activate-choose-password.reducer';
import password, { PasswordState } from 'app/modules/account/password/password.reducer';
import settings, { SettingsState } from 'app/modules/account/settings/settings.reducer';
import passwordReset, { PasswordResetState } from 'app/modules/account/password-reset/password-reset.reducer';
import healthcareProfessional, { HealthcareProfessionalState } from 'app/entities/healthcare-professional/healthcare-professional.reducer';
import medicalAssistant, { MedicalAssistantState } from 'app/entities/medical-assistant/medical-assistant.reducer';
import patient, { PatientState } from 'app/entities/patient/patient.reducer';
import verifySmsReducer, { SmsVerificationState } from 'app/modules/account/sms-code-verification/sms-code-verification.reducer';
import maDashboardReducer, { MedicalAssistantDashboardState } from '../../pages/medical-assistant-dashboard/ma-dashboard.reducer';
import appointment, { AppointmentState } from 'app/entities/appointment/appointment.reducer';
import conversationEvent, { ConversationEventState } from 'app/entities/conversation-event/conversation-event.reducer';
import conversationSummary, { ConversationSummaryState } from 'app/entities/conversation-summary/conversation-summary.reducer';
import chatbotReducer, { ChatbotState } from 'app/pages/chatbot/chatbot.reducer';
import icdCode, { IcdCodeState } from 'app/entities/icd-code/icd-code.reducer';
import contactUsReducer, { ContactUsState } from 'app/pages/medical-assistant-dashboard/shard/contact-us/contact-us-reducer';
import termsAndConditions, { TermsAndConditionsState } from 'app/modules/account/terms-and-conditions/terms-and-conditions.reducer';
import termsAndConditionsContent, {
  TermsAndConditionsContentState
} from 'app/entities/terms-and-conditions-content/terms-and-conditions-content.reducer';
import conversationCatalogDe, { ConversationCatalogDeState } from 'app/entities/conversation-catalog-de/conversation-catalog-de.reducer';
import conversationCatalogEn, { ConversationCatalogEnState } from 'app/entities/conversation-catalog-en/conversation-catalog-en.reducer';
import conversationCatalogTr, { ConversationCatalogTrState } from 'app/entities/conversation-catalog-tr/conversation-catalog-tr.reducer';
// prettier-ignore
import insuranceCode, {
  InsuranceCodeState
} from 'app/entities/insurance-code/insurance-code.reducer';
// prettier-ignore
import scheduledTask, {
  ScheduledTaskState
} from 'app/entities/scheduled-task/scheduled-task.reducer';
// prettier-ignore
import scheduledTaskExecution, {
  ScheduledTaskExecutionState
} from 'app/entities/scheduled-task-execution/scheduled-task-execution.reducer';
import generateDocumentReducer, { GenerateDocumentState } from 'app/modules/home/generate-document/generate-document-page-reducer';
// prettier-ignore
import selfRegistration, {
  SelfRegistrationState
} from 'app/entities/self-registration/self-registration.reducer';
import clinic, { ClinicState } from 'app/entities/clinic/clinic.reducer';
// prettier-ignore
import vaccination, {
  VaccinationState
} from 'app/entities/vaccination/vaccination.reducer'
/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

export interface IRootState {
  readonly authentication: AuthenticationState;
  readonly locale: LocaleState;
  readonly applicationProfile: ApplicationProfileState;
  readonly administration: AdministrationState;
  readonly userManagement: UserManagementState;
  readonly register: RegisterState;
  readonly activate: ActivateState;
  readonly termsAndConditions: TermsAndConditionsState;
  readonly activationChoosePassword: ActivationChoosePasswordState;
  readonly passwordReset: PasswordResetState;
  readonly password: PasswordState;
  readonly settings: SettingsState;
  readonly clinic: ClinicState;
  readonly medicalAssistant: MedicalAssistantState;
  readonly patient: PatientState;
  readonly healthcareProfessional: HealthcareProfessionalState;
  readonly appointment: AppointmentState;
  readonly conversationEvent: ConversationEventState;
  readonly conversationSummary: ConversationSummaryState;
  readonly icdCode: IcdCodeState;
  readonly termsAndConditionsContent: TermsAndConditionsContentState;
  readonly conversationCatalogDe: ConversationCatalogDeState;
  readonly conversationCatalogTr: ConversationCatalogTrState;
  readonly conversationCatalogEn: ConversationCatalogEnState;
  readonly insuranceCode: InsuranceCodeState;
  readonly scheduledTask: ScheduledTaskState;
  readonly scheduledTaskExecution: ScheduledTaskExecutionState;
  readonly vaccination: VaccinationState;
  readonly selfRegistration: SelfRegistrationState;
  /* jhipster-needle-add-reducer-type - JHipster will add reducer type here */
  readonly loadingBar: any;
  readonly verifySmsReducer: SmsVerificationState;
  readonly maDashboardReducer: MedicalAssistantDashboardState;
  readonly chatbotReducer: ChatbotState;
  readonly contactUsReducer: ContactUsState;
  readonly generateDocumentReducer: GenerateDocumentState;
}

const rootReducer = combineReducers<IRootState>({
  authentication,
  locale,
  applicationProfile,
  administration,
  userManagement,
  register,
  activate,
  activationChoosePassword,
  termsAndConditions,
  verifySmsReducer,
  passwordReset,
  password,
  settings,
  clinic,
  medicalAssistant,
  patient,
  healthcareProfessional,
  appointment,
  conversationEvent,
  conversationSummary,
  icdCode,
  termsAndConditionsContent,
  conversationCatalogDe,
  conversationCatalogTr,
  conversationCatalogEn,
  insuranceCode,
  scheduledTask,
  scheduledTaskExecution,
  vaccination,
  selfRegistration,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
  loadingBar,
  maDashboardReducer,
  chatbotReducer,
  contactUsReducer,
  generateDocumentReducer
});

export default rootReducer;
