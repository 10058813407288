import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IScheduledTask, defaultValue } from 'app/shared/model/scheduled-task.model';

export const ACTION_TYPES = {
  FETCH_SCHEDULEDTASK_LIST: 'scheduledTask/FETCH_SCHEDULEDTASK_LIST',
  FETCH_SCHEDULEDTASK: 'scheduledTask/FETCH_SCHEDULEDTASK',
  CREATE_SCHEDULEDTASK: 'scheduledTask/CREATE_SCHEDULEDTASK',
  UPDATE_SCHEDULEDTASK: 'scheduledTask/UPDATE_SCHEDULEDTASK',
  DELETE_SCHEDULEDTASK: 'scheduledTask/DELETE_SCHEDULEDTASK',
  RESET: 'scheduledTask/RESET'
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IScheduledTask>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false
};

export type ScheduledTaskState = Readonly<typeof initialState>;

// Reducer

export default (state: ScheduledTaskState = initialState, action): ScheduledTaskState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_SCHEDULEDTASK_LIST):
    case REQUEST(ACTION_TYPES.FETCH_SCHEDULEDTASK):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_SCHEDULEDTASK):
    case REQUEST(ACTION_TYPES.UPDATE_SCHEDULEDTASK):
    case REQUEST(ACTION_TYPES.DELETE_SCHEDULEDTASK):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_SCHEDULEDTASK_LIST):
    case FAILURE(ACTION_TYPES.FETCH_SCHEDULEDTASK):
    case FAILURE(ACTION_TYPES.CREATE_SCHEDULEDTASK):
    case FAILURE(ACTION_TYPES.UPDATE_SCHEDULEDTASK):
    case FAILURE(ACTION_TYPES.DELETE_SCHEDULEDTASK):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_SCHEDULEDTASK_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.FETCH_SCHEDULEDTASK):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_SCHEDULEDTASK):
    case SUCCESS(ACTION_TYPES.UPDATE_SCHEDULEDTASK):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_SCHEDULEDTASK):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/scheduled-tasks';

// Actions

export const getEntities: ICrudGetAllAction<IScheduledTask> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_SCHEDULEDTASK_LIST,
    payload: axios.get<IScheduledTask>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`)
  };
};

export const getEntity: ICrudGetAction<IScheduledTask> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_SCHEDULEDTASK,
    payload: axios.get<IScheduledTask>(requestUrl)
  };
};

export const createEntity: ICrudPutAction<IScheduledTask> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_SCHEDULEDTASK,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<IScheduledTask> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_SCHEDULEDTASK,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<IScheduledTask> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_SCHEDULEDTASK,
    payload: axios.delete(requestUrl)
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
