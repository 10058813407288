import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import TermsAndConditionsContent from './terms-and-conditions-content';
import TermsAndConditionsContentDetail from './terms-and-conditions-content-detail';
import TermsAndConditionsContentUpdate from './terms-and-conditions-content-update';
import TermsAndConditionsContentDeleteDialog from './terms-and-conditions-content-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={TermsAndConditionsContentUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={TermsAndConditionsContentUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={TermsAndConditionsContentDetail} />
      <ErrorBoundaryRoute path={match.url} component={TermsAndConditionsContent} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={TermsAndConditionsContentDeleteDialog} />
  </>
);

export default Routes;
