import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import ConversationCatalogEn from './conversation-catalog-en';
import ConversationCatalogEnDetail from './conversation-catalog-en-detail';
import ConversationCatalogEnUpdate from './conversation-catalog-en-update';
import ConversationCatalogEnDeleteDialog from './conversation-catalog-en-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={ConversationCatalogEnUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={ConversationCatalogEnUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={ConversationCatalogEnDetail} />
      <ErrorBoundaryRoute path={match.url} component={ConversationCatalogEn} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={ConversationCatalogEnDeleteDialog} />
  </>
);

export default Routes;
