import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Row, Col, Alert, Button } from 'reactstrap';
import { Translate, getUrlParameter } from 'react-jhipster';

import { IRootState } from 'app/shared/reducers';
import { activateInterimAction, reset } from './activate-interim.reducer';

const successAlert = (
  <Alert color="success">
    <Translate contentKey="activateInterim.messages.success">
      <strong>SMS message sent successfully.</strong>
    </Translate>
    <Link to="/login" className="info-box-link">
      <Translate contentKey="global.messages.info.authenticated.link">sign in</Translate>
    </Link>
    .
  </Alert>
);

const failureAlert = (
  <Alert color="danger">
    <Translate contentKey="activateInterim.messages.error">
      <strong>SMS message could not be sent.</strong>
    </Translate>
  </Alert>
);

export interface IActivateProps extends StateProps, DispatchProps, RouteComponentProps<{ key: any }> {}

export const ActivateInterimPage = (props: IActivateProps) => {
  useEffect(() => {
    return () => {
      props.reset();
    };
  }, []);

  const handleSendSMS = () => {
    const key = getUrlParameter('key', props.location.search);
    props.activateInterimAction(key);
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h1>
            <Translate contentKey="activateInterim.title">Activation</Translate>
          </h1>
          <h3>
            <Translate contentKey="activateInterim.body" />
          </h3>
          <h3>
            <Translate contentKey="activateInterim.smsInstructions" />
          </h3>
          <br />
          <Row>
            <Button onClick={handleSendSMS}>
              <Translate contentKey="activateInterim.smsButton" />
            </Button>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = ({ activate }: IRootState) => ({
  activationSuccess: activate.activationSuccess,
  activationFailure: activate.activationFailure
});

const mapDispatchToProps = { activateInterimAction, reset };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ActivateInterimPage);
