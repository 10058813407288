import React, { useState, useEffect } from 'react';
import { Translate, getUrlParameter } from 'react-jhipster';
import { connect } from 'react-redux';
import { Row, Col, Button, DropdownItem, Label } from 'reactstrap';
import { IRootState } from 'app/shared/reducers';
import { agreeTermsAndConditions, verifyTermsAndConditions, reset, retrieveTermsAndConditionsData } from './terms-and-conditions.reducer';
import { RouteComponentProps, Redirect, Link } from 'react-router-dom';
import { AvForm, AvInput, AvGroup } from 'availity-reactstrap-validation';
import { UserActivationStatus } from '../models';
import { TermsAndConditionsContentType } from '../models';
import parse from 'html-react-parser';
import { toast } from 'react-toastify';
import { generatePDFv2, PDFFILES } from 'app/pages/medical-assistant-dashboard/shard/pdfUtils';

export interface ITermsAndConditions extends StateProps, DispatchProps, RouteComponentProps<{ key: any; }> { }

export const TermsAndConditions = (props: ITermsAndConditions) => {
  const [key, setkey] = useState('');
  const [privacyData, setPrivacyPolicyData] = useState(false);
  const [personalData, setPersonalData] = useState(false);
  const [agreeToTermsAndConditions, setAgreeToTermsAndConditions] = useState(false);
  const [agreeToDataProtection, setAgreeToDataProtection] = useState(false);
  const [studyParticipation, setStudyParticipationLink] = useState(false);

  useEffect(() => {
    props.reset();
    const keyFromUrl = getUrlParameter('key', props.location.search);
    setkey(keyFromUrl);
    props.verifyTermsAndConditions(keyFromUrl);
    props.currentLanguage && props.retrieveTermsAndConditionsData(props.currentLanguage, TermsAndConditionsContentType.CONSENT);
  }, [props.currentLanguage]);

  const handleSubmit = () => {
    privacyData === true && personalData === true && agreeToTermsAndConditions === true && agreeToDataProtection === true && key ?
    props.agreeTermsAndConditions(new Date().toLocaleDateString(), key, { studyParticipation }) :
    toast.error(<Translate contentKey="activationTermsConditions.checkboxesError"></Translate>, {
      position: "top-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      });;
  };
  
  const downloadPDF = (filename) => {
    const language:string = props.currentLanguage;
    const files = {
        en: {
            dataProcessing: PDFFILES.DATA_PROCESSING_EN,
            terms: PDFFILES.TNC_EN,
            policy: PDFFILES.PRIVACY_POLICY_EN,
        },
        de: {
            dataProcessing: PDFFILES.DATA_PROCESSING_DE,
            terms: PDFFILES.TNC_DE,
            policy: PDFFILES.PRIVACY_POLICY_DE,
        },
        tr: {
            dataProcessing: PDFFILES.DATA_PROCESSING_TR,
            terms: PDFFILES.TNC_TR,
            policy: PDFFILES.PRIVACY_POLICY_TR,
        }
    }

    generatePDFv2(files?.[language]?.[filename])
}

  if (props.updateSuccess) return <Redirect to={`/account/activateInterim2/?key=${key}`} />;
  else return (
    <Row>
      <Col />

      <Col xs={12} md={6} lg={3} style={{ border: "solid 1px", backgroundColor: "white" }}>

        <Row style={{ border: "solid 1px", backgroundColor: "#91D7F2" }}>
          <Col xs={1} />
          <Col>
            <div style={{ color: "white", fontSize: "90px" }}>2</div>
          </Col>
        </Row>

        <Row style={{ marginTop: "1rem" }}>
          <Col xs={1} />
          <Col>
            <div className="wizard-box-title">
              <Translate contentKey="activationTermsConditions.title" />
            </div>
          </Col>
        </Row>
        {props.pagedSuccessfullyLoaded && props.userStatus === UserActivationStatus.SMS_VERIFIED ? <div>
          <Row style={{ marginTop: "1rem" }}>
            <Col xs={1} />
            <Col>
              <div className="wizard-box-sub-title">
                <Translate contentKey="activationTermsConditions.instructions" />
              </div>
            </Col>
            <Col xs={1} />
          </Row><Row style={{ marginTop: "1rem" }}>
            <Col xs={1} />
            <Col>
              <div className="wizard-box-title-2">
              <Translate contentKey="activationTermsConditions.consent" />
              </div>
            </Col>
            <Col xs={1} />
          </Row>

          <Row>
            <Col xs={1} />
            <Col>
              <Row style={{ marginTop: "1rem", "overflow-y": "none" }}>
                <section className="scroll-area">
                {parse(props.content)}
                </section>

              </Row>
              <AvForm onSubmit={handleSubmit}>
              <AvGroup check>
                <Row style={{ marginTop: "1rem", marginBottom: "1rem" }}>
                  <Label className="form-check-label">
                    <AvInput
                      type="checkbox"
                      name="personalData"
                      required
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPersonalData(event.target.checked)} />
                    <Translate contentKey="activationTermsConditions.personalData"></Translate>
                  </Label>
                </Row>

                <Row style={{ marginTop: "1rem", marginBottom: "1rem" }}>
                  <Label className="form-check-label">
                    <AvInput
                      type="checkbox"
                      name="privacyPolicyData"
                      required
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPrivacyPolicyData(event.target.checked)} />
                    { props.currentLanguage !== 'tr' ? <Translate contentKey="activationTermsConditions.agreeTo"></Translate> : null}
                    <a onClick={() => downloadPDF("policy")}><Translate contentKey="activationTermsConditions.privacyPolicyData"></Translate></a>
                    { props.currentLanguage === 'tr' ? <Translate contentKey="activationTermsConditions.agreeTo"></Translate> : null}
                  </Label>
                </Row>

                <Row style={{ marginTop: "1rem", marginBottom: "1rem" }}>
                  <Label className="form-check-label">
                    <AvInput
                      type="checkbox"
                      name="agreeToTermsAndConditions"
                      required
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => setAgreeToTermsAndConditions(event.target.checked)} />
                    { props.currentLanguage !== 'tr' ? <Translate contentKey="activationTermsConditions.agreeTo"></Translate> : null}
                    <a onClick={() => downloadPDF("terms")}><Translate contentKey="activationTermsConditions.agreeToTermsAndConditions"></Translate></a>
                    { props.currentLanguage === 'tr' ? <Translate contentKey="activationTermsConditions.agreeTo"></Translate> : null}
                  </Label>
                </Row>

                <Row style={{ marginTop: "1rem", marginBottom: "1rem" }}>
                  <Label className="form-check-label">
                    <AvInput
                      type="checkbox"
                      name="agreeToDataProtection"
                      required
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => setAgreeToDataProtection(event.target.checked)} />
                      {props.currentLanguage !== 'tr' ? <Translate contentKey="activationTermsConditions.agreeTo"></Translate> : null}
                      <a onClick={() => downloadPDF("dataProcessing")}><Translate contentKey="activationTermsConditions.dataProcessing.title"></Translate></a>
                      {props.currentLanguage === 'tr' ? <Translate contentKey="activationTermsConditions.agreeTo"></Translate> : null}
                  </Label>
                </Row>

                <Row style={{ marginTop: "1rem", marginBottom: "1rem" }}>
                  <Label className="form-check-label">
                    <AvInput
                      type="checkbox"
                      name="studyParticipation"
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => setStudyParticipationLink(event.target.checked)} />
                    { props.currentLanguage !== 'tr' ? <Translate contentKey="activationTermsConditions.studyParticipation"></Translate> : null}
                    <a onClick={() => null}><Translate contentKey="activationTermsConditions.studyParticipationLink"></Translate></a>
                    { props.currentLanguage === 'tr' ? <Translate contentKey="activationTermsConditions.studyParticipation"></Translate> : null}
                  </Label>
                </Row>

                </AvGroup>
                <DropdownItem divider />

                <Row style={{ marginTop: "2rem", marginBottom: "2rem" }}>
                  <Col />
                  <Col xs={8}>
                    <Button
                      type="submit"
                      className="align-self-center app-button"
                    >
                      <Translate contentKey="termsAndConditions.form.button">Back</Translate>
                    </Button>
                  </Col>
                  <Col />
                </Row>
              </AvForm>
            </Col>
            <Col xs={1} />
          </Row>
        </div>
          : props.pageIsLoading ?
            <Row style={{ margin: "2rem", marginBottom: "100%" }}>
              <p style={{ alignContent: "center" }}><Translate contentKey="activationTermsConditions.loading">Loading...</Translate></p>
            </Row>

            : props.verifyFailure ? <Row style={{ margin: "2rem", marginBottom: "100%" }}>
              <p style={{ color: "red" }}><Translate contentKey="activationTermsConditions.errorMessage">Something went wrong!</Translate></p>
            </Row> : ''
        }
      </Col>

      <Col />
    </Row>
  );

};

const mapStateToProps = ({ termsAndConditions, locale }: IRootState) => ({
  updateSuccess: termsAndConditions.agreeTermsAndConditionsAPI.updateSuccess,
  verifyFailure: termsAndConditions.verifyTermsAndConditionsAPI.updateFailure,
  pagedSuccessfullyLoaded: termsAndConditions.verifyTermsAndConditionsAPI.updateSuccess,
  pageIsLoading: termsAndConditions.verifyTermsAndConditionsAPI.loading,
  userStatus: termsAndConditions.verifyTermsAndConditionsAPI.response,
  content: termsAndConditions.termsAndConditionsContentConsentAPI.response,
  currentLanguage: locale.currentLocale ? locale.currentLocale : 'en'
});

const mapDispatchToProps = { agreeTermsAndConditions, verifyTermsAndConditions, reset, retrieveTermsAndConditionsData };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(TermsAndConditions);
