import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, ICrudGetAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './conversation-catalog-tr.reducer';
import { IConversationCatalogTr } from 'app/shared/model/conversation-catalog-tr.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IConversationCatalogTrDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const ConversationCatalogTrDetail = (props: IConversationCatalogTrDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const { conversationCatalogTrEntity } = props;
  return (
    <Row>
      <Col md="8">
        <h2>
          <Translate contentKey="velariaApp.conversationCatalogTr.detail.title">ConversationCatalogTr</Translate> [
          <b>{conversationCatalogTrEntity.id}</b>]
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="conversationTypeId">
              <Translate contentKey="velariaApp.conversationCatalogTr.conversationTypeId">Conversation Type Id</Translate>
            </span>
          </dt>
          <dd>{conversationCatalogTrEntity.conversationTypeId}</dd>
          <dt>
            <span id="conversationType">
              <Translate contentKey="velariaApp.conversationCatalogTr.conversationType">Conversation Type</Translate>
            </span>
          </dt>
          <dd>{conversationCatalogTrEntity.conversationType}</dd>
          <dt>
            <span id="conversationCategory">
              <Translate contentKey="velariaApp.conversationCatalogTr.conversationCategory">Conversation Category</Translate>
            </span>
          </dt>
          <dd>{conversationCatalogTrEntity.conversationCategory}</dd>
          <dt>
            <span id="messageType">
              <Translate contentKey="velariaApp.conversationCatalogTr.messageType">Message Type</Translate>
            </span>
          </dt>
          <dd>{conversationCatalogTrEntity.messageType}</dd>
          <dt>
            <span id="messageId">
              <Translate contentKey="velariaApp.conversationCatalogTr.messageId">Message Id</Translate>
            </span>
          </dt>
          <dd>{conversationCatalogTrEntity.messageId}</dd>
          <dt>
            <span id="messageText">
              <Translate contentKey="velariaApp.conversationCatalogTr.messageText">Message Text</Translate>
            </span>
          </dt>
          <dd>{conversationCatalogTrEntity.messageText}</dd>
          <dt>
            <span id="replyType">
              <Translate contentKey="velariaApp.conversationCatalogTr.replyType">Reply Type</Translate>
            </span>
          </dt>
          <dd>{conversationCatalogTrEntity.replyType}</dd>
          <dt>
            <span id="replyId">
              <Translate contentKey="velariaApp.conversationCatalogTr.replyId">Reply Id</Translate>
            </span>
          </dt>
          <dd>{conversationCatalogTrEntity.replyId}</dd>
          <dt>
            <span id="replyText">
              <Translate contentKey="velariaApp.conversationCatalogTr.replyText">Reply Text</Translate>
            </span>
          </dt>
          <dd>{conversationCatalogTrEntity.replyText}</dd>
          <dt>
            <span id="parentMessageId">
              <Translate contentKey="velariaApp.conversationCatalogTr.parentMessageId">Parent Message Id</Translate>
            </span>
          </dt>
          <dd>{conversationCatalogTrEntity.parentMessageId}</dd>
          <dt>
            <span id="nextMessageId">
              <Translate contentKey="velariaApp.conversationCatalogTr.nextMessageId">Next Message Id</Translate>
            </span>
          </dt>
          <dd>{conversationCatalogTrEntity.nextMessageId}</dd>
        </dl>
        <Button tag={Link} to="/conversation-catalog-tr" replace color="info">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/conversation-catalog-tr/${conversationCatalogTrEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ conversationCatalogTr }: IRootState) => ({
  conversationCatalogTrEntity: conversationCatalogTr.entity,
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ConversationCatalogTrDetail);
