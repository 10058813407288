import React from 'react';
import './ma-dashboard-button.css';
import { connect } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import { Link } from 'react-router-dom';
import { URL_PREFIX, URL_SUFFIX } from '../../ma-dashboard-layout/pages-list';
// import { callToReset } from '../../ma-dashboard.reducer'
interface SideBarLinkBoxProps {
    svg: any;
    text: string;
}
export interface ISideBarLinkBoxProps extends StateProps, DispatchProps, SideBarLinkBoxProps { }

const MaDashboardButton = (props: ISideBarLinkBoxProps) => {
    return (
        <Link to={URL_PREFIX.MA_DASHBOARD + URL_SUFFIX.ADD_NEW_PATIENT}
            
            style={{ textDecoration: 'none' }}>
            <div id='ma-dashboard-button-link' className="ma-dashboard-button-box ma-dashboard-button-box-hover">
                <img src={props.svg} alt='icon' className="center"></img>
                <p>{props.text}</p>
            </div>
        </Link>
    );
};

const mapStateToProps = ({ maDashboardReducer }: IRootState) => {
    return {
    };
};


const mapDispatchToProps = {
    // callToReset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(MaDashboardButton);
