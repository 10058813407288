import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './self-registration.reducer';
import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface ISelfRegistrationDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string; }> { }

export const SelfRegistrationDetail = (props: ISelfRegistrationDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const { selfRegistrationEntity } = props;
  return (
    <Row>
      <Col>
        <Row className="justify-content-center">
          <Col md={4} sm={8}>
            <h2>
              <Translate contentKey="velariaApp.selfRegistration.detail.title">SelfRegistration</Translate> [<b>{selfRegistrationEntity.id}</b>]
        </h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md={4} sm={8} className="info-box">
            <dl className="jh-entity-details">
              <dt>
                <span id="firstName">
                  <Translate contentKey="velariaApp.selfRegistration.firstName">First Name</Translate>
                </span>
              </dt>
              <dd>{selfRegistrationEntity.firstName}</dd>
              <dt>
                <span id="lastName">
                  <Translate contentKey="velariaApp.selfRegistration.lastName">Last Name</Translate>
                </span>
              </dt>
              <dd>{selfRegistrationEntity.lastName}</dd>
              <dt>
                <span id="gender">
                  <Translate contentKey="velariaApp.selfRegistration.gender">Gender</Translate>
                </span>
              </dt>
              <dd>{selfRegistrationEntity.gender}</dd>
              <dt>
                <span id="email">
                  <Translate contentKey="velariaApp.selfRegistration.email">Email</Translate>
                </span>
              </dt>
              <dd>{selfRegistrationEntity.email}</dd>
              <dt>
                <span id="mobileNumber">
                  <Translate contentKey="velariaApp.selfRegistration.mobileNumber">Mobile Number</Translate>
                </span>
              </dt>
              <dd>{selfRegistrationEntity.mobileNumber}</dd>
              <dt>
                <span id="langKey">
                  <Translate contentKey="velariaApp.selfRegistration.langKey">Lang Key</Translate>
                </span>
              </dt>
              <dd>{selfRegistrationEntity.langKey}</dd>
              <dt>
                <span id="birthdate">
                  <Translate contentKey="velariaApp.selfRegistration.birthdate">Birthdate</Translate>
                </span>
              </dt>
              <dd>
                {selfRegistrationEntity.birthdate ? (
                  <TextFormat value={selfRegistrationEntity.birthdate} type="date" format={APP_LOCAL_DATE_FORMAT} />
                ) : null}
              </dd>
              <dt>
                <Translate contentKey="velariaApp.selfRegistration.clinic">Clinic</Translate>
              </dt>
              <dd>
                {selfRegistrationEntity.clinics
                  ? selfRegistrationEntity.clinics.map((val, i) => (
                    <span key={val.id}>
                      <a>{val.name}</a>
                      {selfRegistrationEntity.clinics && i === selfRegistrationEntity.clinics.length - 1 ? '' : ', '}
                    </span>
                  ))
                  : null}
              </dd>
            </dl>
            <Button tag={Link} to="/self-registration" replace className="rounded" color="success" >
              <FontAwesomeIcon icon="arrow-left" />{' '}
              <span className="d-none d-md-inline">
                <Translate contentKey="entity.action.back">Back</Translate>
              </span>
            </Button>
        &nbsp;
        <Button tag={Link} to={`/self-registration/${selfRegistrationEntity.id}/edit`} replace className="rounded" color="danger">
              <FontAwesomeIcon icon="pencil-alt" />{' '}
              <span className="d-none d-md-inline">
                <Translate contentKey="entity.action.edit">Edit</Translate>
              </span>
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ selfRegistration }: IRootState) => ({
  selfRegistrationEntity: selfRegistration.entity,
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(SelfRegistrationDetail);
