import Axios from 'axios';
import { translate } from 'react-jhipster';

export const generateReport = patientId => {
  const requestUrl = `/api/conversation-summary/report/${patientId}`;
  Axios.get(`${requestUrl}`, {
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/pdf'
    }
  }).then(response => {
    if (response.data) {
      const file = new Blob([response.data], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    } else {
      alert(translate('maDashboard.general.emptyReport'));
    }
  });
};
