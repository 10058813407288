import React, { useState, useEffect } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Col } from 'reactstrap';
import { getEntitiesByQuery as getSelfRegistration } from 'app/entities/self-registration/self-registration.reducer';
import MedicalAssistantSelfRegistrations from './ma-selfRegistration'

import classnames from 'classnames';
import { Translate, translate } from 'react-jhipster';
import { IRootState } from 'app/shared/reducers';
import { connect } from 'react-redux';
import { updateHideCalendar } from '../ma-dashboard.reducer';



export interface IMedicalAssistantSelfRegistrationsPage extends StateProps, DispatchProps { }


const MedicalAssistantSelfRegistrationsPage = (props: IMedicalAssistantSelfRegistrationsPage) => {
    const [activeTab, setActiveTab] = useState('1');
    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    useEffect(() => {
        if (props.selectedClinic) {
            props.getSelfRegistration('clinicId.equals=' + props.selectedClinic.id);
        }
        props.updateHideCalendar(true);
    }, []);

    const onSearch = (event) => {
        props.getSelfRegistration('clinicId.equals=' + props.selectedClinic.id + '&search.equals=' + event.target.value);
    }

    return (
        <div>
            <div style={{ marginLeft: '1rem' }}>
                <Translate contentKey="velariaApp.selfRegistration.Explanation" >Explanation</Translate>
            </div>
            <br />
            <form className="form-inline mt-4 mb-4">
                <input className="form-control form-control-sm w-75" style={{ borderRadius: '100px' }} type="text" placeholder={translate("velariaApp.selfRegistration.Search")} aria-label="Search" onChange={(e) => onSearch(e)} />
            </form>
            <Nav className={"selfRegistration-tab"} tabs>
                <NavItem >
                    <NavLink
                        className={classnames({ active: activeTab === '1' })}
                        onClick={() => { toggle('1'); }}
                        style={{ "border-bottom": "1px solid #d7d4d4" }}
                    >
                        {translate("maDashboard.patientArea.patientsSelfRegistrations")}
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={activeTab} className="page-container">
                <TabPane tabId="1">
                    <MedicalAssistantSelfRegistrations />
                </TabPane>
            </TabContent>
        </div>
    );
};


const mapStateToProps = (storeState: IRootState) => ({
    selectedClinic: storeState.maDashboardReducer.selectedClinic,
    currentLocale: storeState.locale.currentLocale
});

const mapDispatchToProps = {
    getSelfRegistration,
    updateHideCalendar
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(MedicalAssistantSelfRegistrationsPage);
