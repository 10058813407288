import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router-dom';

import { IRootState } from 'app/shared/reducers';
import { login } from 'app/shared/reducers/authentication';
import LoginModal from './login-modal';
import { AUTHORITIES } from '../../config/constants'
export interface ILoginProps extends StateProps, DispatchProps, RouteComponentProps<{}> { }
const AUTH_TOKEN_KEY = 'jhi-authenticationToken';

export const Login = (props: ILoginProps) => {
  const [showModal, setShowModal] = useState(props.showModal);

  useEffect(() => {
    setShowModal(true);
  }, []);

  const handleLogin = (username, password, rememberMe = false) => props.login(username, password, rememberMe,'VELARIA');

  const handleClose = () => {
    setShowModal(false);
    props.history.push('/');
  };

  const { location, isAuthenticated, authorities } = props;
  const { from } = (location.state as any) || { from: { pathname: '/', search: location.search } };

  let redirectPath = '/';
  if (isAuthenticated) {

    if (authorities.includes(AUTHORITIES.ADMIN)) {
      redirectPath = '/clinic';
    }
    if (authorities.includes(AUTHORITIES.PATIENT)) {
      redirectPath = '/patient-welcome';
    }
    if (authorities.includes(AUTHORITIES.MEDICAL_ASSISTANT)) {
      redirectPath = '/ma-dashboard';
    }
    return <Redirect to={redirectPath} from={from} />;

  }
  return <LoginModal showModal={showModal} handleLogin={handleLogin} handleClose={handleClose} loginError={props.loginError} />;
};

const mapStateToProps = ({ authentication }: IRootState) => ({
  isAuthenticated: authentication.isAuthenticated,
  loginError: authentication.loginError,
  showModal: authentication.showModalLogin,
  authorities: authentication.account.authorities
});

const mapDispatchToProps = { login };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Login);
