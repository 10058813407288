import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import './line-strip-toggler.css';

const INITIAL_CLASSNAME = "line-strip-toggler line-strip-toggler-";
const SVG_WHEN_OPENED_TOGGLER = '../../content/images/close-doctor-list.svg';
const SVG_WHEN_CLOSED_TOGGLER = '../../content/images/plus-doctor-list.svg';

interface LineStripTogglerProps {
    togglerId: number;
    leftImagePath: string;
    text: string;
    updateFatherIsTogglerOpen: (string, boolean) => any;
}


const LineStripToggler = (props: LineStripTogglerProps) => {
    const [isOpen, setIsOpen] = useState(true);
    const [lineStripTogglerClassName, setLineStripTogglerClassName] = useState("opened");
    const [svg, setSvg] = useState(SVG_WHEN_CLOSED_TOGGLER);
    const [svgPoistion, setSvgPosition] = useState({});

    const toggleOpenDoctorsDetails = (e) => {
        const updatedIsOpen = !isOpen;
        setIsOpen(updatedIsOpen);
    };

    useEffect(() => {
        setLineStripTogglerClassName(INITIAL_CLASSNAME + (isOpen ? "opened" : "closed"));
        setSvg(isOpen ? SVG_WHEN_OPENED_TOGGLER : SVG_WHEN_CLOSED_TOGGLER);

        props.updateFatherIsTogglerOpen(props.togglerId, isOpen);

        isOpen
            ? setSvgPosition({ position: "absolute", top: "2px", right: "1px" })
            : setSvgPosition({ position: "absolute", top: "9px", right: "10px" });

    }, [isOpen]);

    return (
        <div >
            <img
                id="left-image"
                alt="left-image"
                src={props.leftImagePath}
                style={
                    {
                        position: "absolute",
                        left: "0px",
                        top: "0px",
                        zIndex: 1,
                        border: "0.1px solid",
                        borderRadius: "50%",
                        height: "80%"
                    }}
            />
            <Row className={`no-gutters ${lineStripTogglerClassName}`}>
                <Col md={1}>

                </Col>
                <Col>
                    <div>
                        <div style={{
                            position: "absolute",
                            top: "13px",
                            fontSize: "16px",
                            fontWeight: "bold",
                            fontFamily: "'Segoe UI', Regular"

                        }}>{props.text}</div>
                        <img
                            id="right-image-toggler"
                            alt="right-image-toggler"
                            src={svg}
                            onClick={toggleOpenDoctorsDetails}
                            style={svgPoistion}
                        />
                    </div>
                </Col>
            </Row>
        </div >
    );
};

export default LineStripToggler;
