import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { ITermsAndConditionsContent, defaultValue } from 'app/shared/model/terms-and-conditions-content.model';

export const ACTION_TYPES = {
  FETCH_TERMSANDCONDITIONSCONTENT_LIST: 'termsAndConditionsContent/FETCH_TERMSANDCONDITIONSCONTENT_LIST',
  FETCH_TERMSANDCONDITIONSCONTENT: 'termsAndConditionsContent/FETCH_TERMSANDCONDITIONSCONTENT',
  CREATE_TERMSANDCONDITIONSCONTENT: 'termsAndConditionsContent/CREATE_TERMSANDCONDITIONSCONTENT',
  UPDATE_TERMSANDCONDITIONSCONTENT: 'termsAndConditionsContent/UPDATE_TERMSANDCONDITIONSCONTENT',
  DELETE_TERMSANDCONDITIONSCONTENT: 'termsAndConditionsContent/DELETE_TERMSANDCONDITIONSCONTENT',
  SET_BLOB: 'termsAndConditionsContent/SET_BLOB',
  RESET: 'termsAndConditionsContent/RESET'
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<ITermsAndConditionsContent>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false
};

export type TermsAndConditionsContentState = Readonly<typeof initialState>;

// Reducer

export default (state: TermsAndConditionsContentState = initialState, action): TermsAndConditionsContentState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_TERMSANDCONDITIONSCONTENT_LIST):
    case REQUEST(ACTION_TYPES.FETCH_TERMSANDCONDITIONSCONTENT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_TERMSANDCONDITIONSCONTENT):
    case REQUEST(ACTION_TYPES.UPDATE_TERMSANDCONDITIONSCONTENT):
    case REQUEST(ACTION_TYPES.DELETE_TERMSANDCONDITIONSCONTENT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_TERMSANDCONDITIONSCONTENT_LIST):
    case FAILURE(ACTION_TYPES.FETCH_TERMSANDCONDITIONSCONTENT):
    case FAILURE(ACTION_TYPES.CREATE_TERMSANDCONDITIONSCONTENT):
    case FAILURE(ACTION_TYPES.UPDATE_TERMSANDCONDITIONSCONTENT):
    case FAILURE(ACTION_TYPES.DELETE_TERMSANDCONDITIONSCONTENT):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_TERMSANDCONDITIONSCONTENT_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.FETCH_TERMSANDCONDITIONSCONTENT):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_TERMSANDCONDITIONSCONTENT):
    case SUCCESS(ACTION_TYPES.UPDATE_TERMSANDCONDITIONSCONTENT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_TERMSANDCONDITIONSCONTENT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.SET_BLOB: {
      const { name, data, contentType } = action.payload;
      return {
        ...state,
        entity: {
          ...state.entity,
          [name]: data,
          [name + 'ContentType']: contentType
        }
      };
    }
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/terms-and-conditions-contents';

// Actions

export const getEntities: ICrudGetAllAction<ITermsAndConditionsContent> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_TERMSANDCONDITIONSCONTENT_LIST,
    payload: axios.get<ITermsAndConditionsContent>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`)
  };
};

export const getEntity: ICrudGetAction<ITermsAndConditionsContent> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_TERMSANDCONDITIONSCONTENT,
    payload: axios.get<ITermsAndConditionsContent>(requestUrl)
  };
};

export const createEntity: ICrudPutAction<ITermsAndConditionsContent> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_TERMSANDCONDITIONSCONTENT,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<ITermsAndConditionsContent> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_TERMSANDCONDITIONSCONTENT,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<ITermsAndConditionsContent> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_TERMSANDCONDITIONSCONTENT,
    payload: axios.delete(requestUrl)
  });
  dispatch(getEntities());
  return result;
};

export const setBlob = (name, data, contentType?) => ({
  type: ACTION_TYPES.SET_BLOB,
  payload: {
    name,
    data,
    contentType
  }
});

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
