import React from 'react';
import './sections-divider.css';
import { Row, Col } from 'reactstrap';

function SectionsDivider() {
    return (
        <Row>
            <Col>
                <div className="sections-divider"></div>
            </Col>
        </Row>
    );
}

export default SectionsDivider;
